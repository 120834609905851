import React, { useCallback } from 'react';
import { useCookies } from "react-cookie";
import  { useParams } from 'react-router-dom'
import { GeoStats } from '../components/ProfileComponents';
import "../css/profile.css"
const api = require('../api');

const Profile = () => {
    const { username } = useParams();
    const [cookies] = useCookies(["authToken"]);

    const [displayUsername, setDisplayUsername] = React.useState([]);
    const [stream_link, setStreamLink] = React.useState("");
    const [geo_stats, setGeoStats] = React.useState([]);
    const [isLoadingTwitch, setIsLoadingTwitch] = React.useState(true);
    const [isTwitchConnected, setIsTwitchConnected] = React.useState(false);
    const [hasTwitchExtension, setHasTwitchExtension] = React.useState(false);
    const [twitchUsername, setTwitchUsername] = React.useState("");
    const [twitchProfileImg, setTwitchProfileImg] = React.useState("");
    
    const refreshUserInfo = useCallback(() => {
        if (username){
            setDisplayUsername(username);
            api.get('/users/getByUsername', {"username": username}, (profileResponse) => {
                if(profileResponse){
                    setStreamLink(profileResponse.stream_link);
                    api.get('/users/getGeoStats', {"id": profileResponse.id}, (geoResponse) => {
                        if(typeof geoResponse !== 'string' && !(geoResponse instanceof String)){
                            setGeoStats(geoResponse);
                        }
                    })
                }
            });
            api.get('/twitch/getStatusByUsername', {username}, (response) => {
                if(response){
                    setIsTwitchConnected(response.connected);
                    setHasTwitchExtension(response.has_extension);
                    setTwitchUsername(response.user_data?.display_name);
                    setTwitchProfileImg(response.user_data?.profile_image_url);
                    setIsLoadingTwitch(false);
                }
            });
        } else if(cookies.authToken) {
            api.getAuth('/users/getCurrent', cookies.authToken, {}, (userResponse) => {
                if(userResponse){
                    setDisplayUsername(userResponse.username);
                    api.get('/users/getByUsername', {"username": userResponse.username}, (profileResponse) => {
                        if(profileResponse){
                            setStreamLink(profileResponse.stream_link);
                            api.get('/users/getGeoStats', {"id": profileResponse.id}, (geoResponse) => {
                                if(typeof geoResponse !== 'string' && !(geoResponse instanceof String)){
                                    setGeoStats(geoResponse);
                                }
                            })
                        }
                    })
                }
            });
            api.getAuth('/twitch/getCurrentStatus', cookies.authToken, {}, (response) => {
                if(response){
                    setIsTwitchConnected(response.connected);
                    setHasTwitchExtension(response.has_extension);
                    setTwitchUsername(response.user_data?.display_name);
                    setTwitchProfileImg(response.user_data?.profile_image_url);
                    setIsLoadingTwitch(false);
                }
            });
        }
    }, [cookies.authToken, username]);
    React.useEffect(() => {refreshUserInfo()}, [refreshUserInfo])
    
    return (
        <div className="container-fluid d-flex flex-column align-items-center content-box">
            <div className="container m-3 d-flex flex-column align-items-start">
                <h1>{displayUsername}</h1>
                {stream_link && (
                    <div className="mt-1">
                        <b>Stream Link:</b> <a href={stream_link}>{stream_link}</a>
                    </div>
                )}
                <div className="w-100 d-flex gap-3 flex-wrap justify-content-between mt-4">
                    <div className="profile-box">
                        <h4>Geoguesser Stats</h4>
                        <GeoStats stats={geo_stats}/>
                    </div>
                    {isLoadingTwitch ? (
                        "Loading..."
                    ) : isTwitchConnected ? (
                        <div className="profile-box">
                            <h4>Twitch</h4>
                                <a target="_blank" rel="noreferrer" href={"https://www.twitch.tv/" + twitchUsername} style={{textDecoration: "none", color: "white"}}>
                                    <div className="d-flex flex-row align-items-center my-3 twitch-link">
                                        <img className='rounded-circle' style={{width: "50px"}} src={twitchProfileImg} alt='twitch-profile-img'/>
                                        <h4 className='mx-3'>{twitchUsername}</h4>
                                    </div>
                                </a>
                                {hasTwitchExtension && "Twitch extension configured"}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );

};

  
export default Profile;
  