import { Outlet } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <div className="d-flex flex-column h-100">
        <NavbarComponent />
        <div className="container-fluid p-1 flex-grow-1">
          <Outlet />
        </div>
        <Footer />
    </div>
  )
};

export default Layout;
