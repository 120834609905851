export const get = (endpoint, params, callback) => {
    var url = new URL(process.env.REACT_APP_API_URL + endpoint);
    url.search = new URLSearchParams(params);
    const fetch_params = {
        method : "GET"
    }
    request(url.toString(), fetch_params, callback)
}

export const getPromise = (endpoint, params) => new Promise((resolve, reject) =>  {
    var url = new URL(process.env.REACT_APP_API_URL + endpoint);
    url.search = new URLSearchParams(params);
    const fetch_params = {
        method : "GET"
    }
    fetch(url.toString(), fetch_params)
    .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            response.json().then((r) => {resolve(r)})
        } else {
            response.text().then((r) => {resolve(r)})
        }
    })
})

export const getAuth = (endpoint, authToken, params, callback) => {
    var url = new URL(process.env.REACT_APP_API_URL + endpoint);
    url.search = new URLSearchParams(params);
    const fetch_params = {
        method : "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken
        }
    }
    request(url.toString(), fetch_params, callback)
}

export const post = (endpoint, params, callback) => {
    var url = new URL(process.env.REACT_APP_API_URL + endpoint);
    const fetch_params = {
        method : "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    }
    request(url, fetch_params, callback)
}

export const postAuth = (endpoint, authToken, params, callback) => {
    var url = new URL(process.env.REACT_APP_API_URL + endpoint);
    const fetch_params = {
        method : "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken
        },
        body: JSON.stringify(params)
    }
    request(url, fetch_params, callback)
}

const request = (url, fetch_params, callback) => {
    fetch(url, fetch_params)
    .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            response.json().then((r) => {if(callback) callback(r)})
        } else {
            response.text().then((r) => {if(callback) callback(r)})
        }
    })
}