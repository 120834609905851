import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
import { BingoCard } from "./BingoCard";
import { CallsList } from "./CallsList";
import { LoadingSpinner } from '../LoadingSpinner';
import { formatTimeNoTz } from "../../functions/DateFunctions";
const api = require('../../api')

export const PlayerDashboard = ({ gameID, cards, setCards, allPromptsList, allowReroll, maxRerolls, showCalls, autoMark, showWinModal, isOld = false }) => {
    const [cookies] = useCookies(["authToken"]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCardIndex, setSelectedCardIndex] = useState(cards ? cards.length - 1 : 0);
    const selectedCard = cards[selectedCardIndex];
    const [markerColor, setMarkerColor] = React.useState("#FFFF00");
    const [message, setMessage] = useState("");
    const calls = allPromptsList?.filter(item => item.called).map(item => item.id);

    const rollNewCard = () => {
        if (allPromptsList?.length < 24) {
            setMessage("There are not enough prompts in this game to roll a card yet!");
            return;
        }
        api.postAuth('/bingo/cards/create', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response  && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                setCards(response.sort((a, b) => a.created_at > b.created_at))
                setSelectedCardIndex(response.length - 1);
            } else {
                setMessage("Could not create card.");
            }
        })
    }

    const rerollCard = () => {
        api.postAuth('/bingo/cards/rerollForCurrentUser', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                setCards(response.sort((a, b) => a.created_at > b.created_at));
                setSelectedCardIndex(response.length - 1);
            }
        })
    }

    const toggleFlag = () => {
        api.postAuth('/bingo/cards/flag', cookies.authToken, { 
            "card_id": selectedCard.id,
            "flagged": selectedCard.flagged_at ? false : true
        }, null)
    }

    useEffect(() => {
        if (gameID === undefined || gameID === "undefined") return;
        setIsLoading(true);
        api.getAuth('/bingo/cards/getForCurrentUser', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response && Array.isArray(response) && response.length > 0) {
                setCards(response.sort((a, b) => a.created_at > b.created_at));
                setSelectedCardIndex(response.length - 1);
            }
            setIsLoading(false);
        })
    }, [cookies.authToken, gameID, setCards])

    return (
        <div className="split-container-wrapper">
            <div className="split-container-inner mb-4">
                {cards && cards.length > 1 && (
                    <div className="d-flex gap-2 my-2">
                        Card: 
                        {cards.sort((a,b) => a.created_at > b.created_at).map((card, index) =>  (
                            <button 
                                key={card.id} 
                                className={"btn py-0 px-3" + (index === selectedCardIndex ? " btn-accent2" : " btn-accent1")}
                                onClick={() => setSelectedCardIndex(index)}
                            >
                                {index + 1}
                            </button>
                        )
                        )}
                    </div>
                )}
                {selectedCard?.prompts && selectedCard?.prompts?.length > 0 ? (
                    <>
                        <BingoCard
                            cardID={selectedCard.id}
                            prompts={selectedCard.prompts}
                            autoMark={autoMark || isOld}
                            calls={calls}
                            markerColor={markerColor}
                            locked={selectedCardIndex < cards.length - 1}
                        />
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex flex-row gap-3 align-items-center mt-2">
                                Marker Color:
                                <input className="marker-input" type="color" value={markerColor} onChange={e => setMarkerColor(e.target.value)} />
                            </div>
                            {selectedCardIndex === cards.length - 1 && !isOld && (
                                <>
                                    {selectedCard?.flagged_at ? (
                                        <div className="mt-2 d-flex align-items-center gap-2">
                                            <div>Card flagged for review!</div>
                                            <Button className="btn-success btn-shrink" onClick={toggleFlag}>Cancel</Button>
                                        </div>
                                    ) : (
                                        <Button className="mt-2 btn-success btn-shrink" onClick={toggleFlag}>BINGO!</Button>
                                    )}
                                    {allowReroll && cards && cards.length < maxRerolls && (
                                        <Button className="mt-2 btn-success btn-shrink" onClick={rerollCard}>Reroll ({maxRerolls - cards.length} remaining)</Button>
                                    )}
                                </>
                            )}
                            {selectedCard?.wins?.length > 0 && (
                                <>
                                    <table className="table table-sm w-auto text-center mt-3 mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">Win #</th>
                                                <th className="text-nowrap">Won At</th>
                                                <th className="text-nowrap">Game Key</th>
                                                <th className="text-nowrap">Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedCard?.wins.sort((a, b) => a.win_number > b.win_number).map(item => (<tr>
                                                <td className="align-middle">{item.win_number}</td>
                                                <td className="align-middle text-nowrap">{formatTimeNoTz(new Date(item.won_at))}</td>
                                                <td className="align-middle">{item.name}</td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                                        {item.code}
                                                        {item.code ? (
                                                            <button
                                                                className="btn btn-accent2 py-1 px-3"
                                                                onClick={(e) => {
                                                                    navigator.clipboard.writeText(item.code);
                                                                    e.target.textContent = "Copied!";
                                                                    setTimeout(() => {
                                                                        e.target.textContent = "Copy";
                                                                    }, "3000");
                                                                }}
                                                            >
                                                                Copy
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                    {selectedCard?.wins && selectedCard.wins.filter(x => x.code === null).length > 0 && (
                                        <button className="btn btn-primary w-100 mt-2" onClick={showWinModal}>
                                            Claim Key
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ) : isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="d-flex flex-column align-items-center mt-3">
                        You don't have a card yet!
                        <Button className="my-3 btn-success" onClick={rollNewCard}>Roll New Card</Button>
                        {message}
                    </div>
                )}

            </div>
            {showCalls && (
                <div className="split-container-inner">
                    <CallsList
                        allPromptsList={allPromptsList}
                        calls={calls}
                    />
                </div>
            )}
        </div>
    )
};