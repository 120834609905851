import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useWebSocket from 'react-use-websocket';
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { formatDate, formatTime } from "../functions/DateFunctions";

import { ModDashboard } from "../components/bingogame/ModDashboard";
import { PlayerDashboard } from "../components/bingogame/PlayerDashboard";
import { ProfileLink } from "../components/ProfileComponents";
import { LoadingSpinner } from "../components/LoadingSpinner"
import { GameWinModal } from "../components/bingogame/GameWinModal";
const api = require('../api')

const BingoGame = () => {
    const { gameID } = useParams();

    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/game/" + gameID } });
    }

    const [role, setRole] = useState("");
    const [selectedTab, setSelectedTab] = useState("");
    const [settingsList, setSettingsList] = useState([]);
    const dateTime = settingsList?.scheduled_datetime ? new Date(settingsList.scheduled_datetime) : null;
    const [isOld, setIsOld] = useState(false);
    const [showOldModal, setShowOldModal] = useState(false);

    const [participants, setParticipants] = useState(null);
    const [streamers, setStreamers] = useState([]);
    const [winnerList, setWinnerList] = useState([]);
    const [flaggedPlayers, setFlaggedPlayers] = useState([]);
    const [triggerReload, setTriggerReload] = useState(false);

    const [allPromptsList, setAllPromptsList] = useState([]);
    const [allKeysList, setAllKeysList] = useState([]);

    const [cards, setCards] = useState([]);
    const [showWinModal, setShowWinModal] = useState(false);

    const [largeStream, setLargeStream] = useState(false);

    const onGameJoin = () => {
        api.postAuth('/bingo/games/joinGame', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response === "Game is full") {
                alert("Could not join game: Game is full");
            } else {
                setRole("player");
            }
        })
    }

    const refreshParticipants = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        if (role === "mod" || role === "streamer" || role === "creator") {
            api.getAuth('/bingo/games/getParticipants', cookies.authToken, { "game_id": gameID }, (response) => {
                if (typeof response !== 'string' && !(response instanceof String)) {
                    if (response) {
                        setParticipants(response);
                    }
                }
            })
        }
    }, [cookies.authToken, role, gameID])

    const refreshDetails = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        api.getAuth('/bingo/games/getById', cookies.authToken, { "game_id": gameID }, (response) => {
            if (typeof response !== 'string' && !(response instanceof String)) {
                if (response.details) {
                    setSettingsList(response.details);
                }
            }
        })
    }, [cookies.authToken, gameID])

    const refreshCards = useCallback((cardID) => {
        if (gameID === undefined || gameID === "undefined" || !cards.some(card => card.id === cardID)) return;
        api.getAuth('/bingo/cards/getForCurrentUser', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                setCards(response.sort((a, b) => a.created_at > b.created_at));
            }
        })
    }, [cookies.authToken, gameID, cards])

    const refreshFlags = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        if (role === "mod" || role === "streamer" || role === "creator") {
            api.getAuth('/bingo/games/getFlags', cookies.authToken, { "game_id": gameID }, (response) => {
                if (typeof response !== 'string' && !(response instanceof String)) {
                    setFlaggedPlayers(response);
                } else {
                    setFlaggedPlayers([]);
                }
            })
        }
    }, [cookies.authToken, gameID, role])

    const refreshPlayerWins = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        api.getAuth('/bingo/cards/getForCurrentUser', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                setCards(response.sort((a, b) => a.created_at > b.created_at));
            }
        })
    }, [cookies.authToken, gameID])

    const refreshWinners = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        if (role === "mod" || role === "streamer" || role === "creator") {
            api.getAuth('/bingo/games/getWinners', cookies.authToken, { "game_id": gameID }, (response) => {
                if (typeof response !== 'string' && !(response instanceof String)) {
                    if (response) {
                        setWinnerList(response);
                    }
                }
            })
        } else {
            refreshPlayerWins();
        }
    }, [cookies.authToken, role, gameID, refreshPlayerWins])

    const refreshKeys = useCallback(() => {
        if (gameID === undefined || gameID === "undefined") return;
        setTriggerReload(true);
        api.getAuth('/bingo/keys/getKeys', cookies.authToken, { "game_id": gameID }, (response) => {
            if (typeof response !== 'string' && !(response instanceof String)) {
                if (response) {
                    setAllKeysList(response);
                }
            }
        })
    }, [cookies.authToken, gameID])

    useEffect(() => {
        if (gameID === undefined || gameID === "undefined") return;
        api.getAuth('/bingo/games/getById', cookies.authToken, { "game_id": gameID }, (response) => {
            if (typeof response !== 'string' && !(response instanceof String)) {
                if (response.details) {
                    setSettingsList(response.details);
                    setParticipants(response.participants);
                    setAllPromptsList(response.prompts);
                    setAllKeysList(response.keys);
                    const scheduledDateTime = response.details.scheduled_datetime ? new Date(response.details.scheduled_datetime) : null;
                    const now = new Date();
                    const old = scheduledDateTime && (now.getTime() - scheduledDateTime.getTime() > 86400000);
                    setIsOld(old);
                    setShowOldModal(old);
                } else {
                    navigate('/bingo');
                }
            } else if (response === "Invalid token") {
                navigate('/login', { state: { "next": "/game/" + gameID } });
            } else {
                navigate('/bingo');
            }
        })
        api.getAuth('/bingo/games/getRoleForCurrentUser', cookies.authToken, { "game_id": gameID }, (response) => {
            if (response === "Role not found") {
                setRole("none");
            } else {
                setRole(response);
                if (response === "mod" || response === "streamer" || response === "creator") {
                    setSelectedTab("mod");
                } else {
                    setSelectedTab("player");
                }
            }
        })
        refreshFlags();
        refreshWinners();
    }, [cookies.authToken, gameID, navigate, refreshFlags, refreshWinners])

    useEffect(() => {
        if (participants?.streamers) {
            let storedStreamersParsed;
            const storedStreamers = localStorage.getItem("streamers_" + gameID);
            if (storedStreamers) {
                storedStreamersParsed = JSON.parse(storedStreamers);
            }
            Promise.all(participants.streamers.map(async (streamer) => {
                const result = await api.getPromise('/twitch/getStatusByUsername', { "username": streamer.username });
                const storedStreamer = storedStreamersParsed ? storedStreamersParsed.filter(s => s.username === streamer.username) : null;
                return {
                    twitch: result,
                    showStream: storedStreamer ? storedStreamer[0].showStream : false,
                    showChat: storedStreamer ? storedStreamer[0].showChat : false,
                    ...streamer
                };
            })).then((results) => {
                setStreamers(results);
                localStorage.setItem("streamers_" + gameID, JSON.stringify(results));
            })
        }
    }, [participants, gameID])

    // Set up web socket
    const { sendJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
                if (response) {
                    sendJsonMessage({
                        action: "set_bingo_participant",
                        gameID: gameID,
                        playerID: response.id
                    });
                }
            });
        },
        onMessage: (message) => {
            if (message.data) {
                const json = JSON.parse(message.data);
                if (json.action === "set_prompts") {
                    setAllPromptsList(json.prompts);
                } else if (json.action === "refresh_details") {
                    refreshDetails();
                } else if (json.action === "refresh_winners") {
                    refreshWinners();
                } else if (json.action === "refresh_participants") {
                    refreshParticipants();
                } else if (json.action === "refresh_keys") {
                    refreshKeys();
                } else if (json.action === "game_won") {
                    setShowWinModal(true);
                } else if (json.action === "refresh_cards") {
                    refreshCards(json.cardID);
                } else if (json.action === "refresh_flags") {
                    refreshFlags();
                }
            }
        },
        share: true,
        filter: () => false,
        retryOnError: true,
        shouldReconnect: () => true
    });

    const toggleStream = useCallback((username) => {
        const newStreamers = streamers.map((s) => {
            if (s.username === username) {
                return Object.assign(s, { showStream: !s.showStream });
            }
            return s;
        });
        setStreamers(newStreamers);
        localStorage.setItem("streamers_" + gameID, JSON.stringify(newStreamers));
    }, [streamers, gameID])

    const toggleChat = useCallback((username) => {
        const newStreamers = streamers.map((s) => {
            if (s.username === username) {
                return Object.assign(s, { showChat: !s.showChat });
            }
            return s;
        });
        setStreamers(newStreamers);
        localStorage.setItem("streamers_" + gameID, JSON.stringify(newStreamers));
    }, [streamers, gameID])

    return (
        cookies.authToken ? (
            <div className="d-flex flex-column align-items-center">
                {role === "" ? (
                    <div className="p-3">
                        <div className="m-3 p-3 d-flex align-items-center">
                            <LoadingSpinner />
                        </div>
                    </div>
                ) : (
                    <div className="w-100 p-3">
                        {/* Header */}
                        <div className="container d-flex flex-row align-items-center">
                            <h2>{settingsList.name}</h2>
                            {dateTime && <h5 className="m-3">{formatDate(dateTime)} @ {formatTime(dateTime)}</h5>}
                        </div>
                        {streamers.length > 0 && !isOld &&
                            <div>
                                <div className="container d-flex flex-wrap justify-content-between">
                                    <table className="mt-2 w-auto">
                                        <thead>
                                            <tr>
                                                <th className="text-center px-1"><u>Stream</u></th>
                                                <th className="text-center px-1"><u>Chat</u></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {streamers.map((streamer) => {
                                                let stream_link = streamer.stream_link;
                                                if (!stream_link && streamer.twitch?.user_data?.display_name) {
                                                    stream_link = "https://www.twitch.tv/" + streamer.twitch.user_data.display_name;
                                                }
                                                return (
                                                    <tr key={streamer.username}>
                                                        <td className="text-center p-1">
                                                            {streamer.twitch?.user_data?.display_name &&
                                                                <button
                                                                    className="btn btn-sm btn-toggle btn-accent2"
                                                                    onClick={() => toggleStream(streamer.username)}
                                                                >
                                                                    {streamer.showStream && <i className="fa-solid fa-check" />}
                                                                </button>
                                                            }
                                                        </td>
                                                        <td className="text-center p-1">
                                                            {streamer.twitch?.user_data?.display_name &&
                                                                <button
                                                                    className="btn btn-sm btn-toggle btn-accent2"
                                                                    onClick={() => toggleChat(streamer.username)}
                                                                >
                                                                    {streamer.showChat && <i className="fa-solid fa-check" />}
                                                                </button>
                                                            }
                                                        </td>
                                                        <td className="p-1 px-2"><ProfileLink username={streamer.username} /></td>
                                                        <td className="p-1">{stream_link?.length > 0 && <a href={stream_link}>{stream_link}</a>}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="mt-2 d-flex gap-2 align-items-center stream-size-toggle">
                                        <button
                                            className="btn btn-sm btn-toggle btn-accent2"
                                            onClick={() => setLargeStream(!largeStream)}
                                        >
                                            {largeStream && <i className="fa-solid fa-check" />}
                                        </button>
                                        Show Full Width Stream(s)
                                    </div>
                                </div>
                                <div className={"d-flex gap-3 mt-2 flex-wrap w-100" + (largeStream ? "" : " container")}>
                                    {streamers.map((streamer) => {
                                        const twitch_name = streamer.twitch?.user_data?.display_name;
                                        if (twitch_name && (streamer.showStream || streamer.showChat)) {
                                            return (
                                                <div className="flex-grow-1 text-center d-flex flex-wrap" key={twitch_name}>
                                                    {streamer.showStream && (
                                                        <iframe
                                                            className="twitch-stream-iframe"
                                                            title={"stream_" + twitch_name}
                                                            src={`https://player.twitch.tv/?channel=${twitch_name}&parent=${process.env.REACT_APP_TWITCH_PARENT || "www.bayoubingo.com"}`}
                                                            allowFullScreen>
                                                        </iframe>
                                                    )}
                                                    {streamer.showChat && (
                                                        <iframe
                                                            className="twitch-chat-iframe"
                                                            title={"chat_" + twitch_name}
                                                            src={`https://www.twitch.tv/embed/${twitch_name}/chat?parent=${process.env.REACT_APP_TWITCH_PARENT || "www.bayoubingo.com"}`}
                                                        >
                                                        </iframe>
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                        }

                        {/* Main content */}
                        <div className="container p-3 bingo-container">
                        {isOld && (
                            <div className="announcement d-flex flex-row justify-content-between align-items-center">
                                You are viewing an old game!
                            </div>
                        )}
                            {/* Tabs */}
                            <div className="d-flex flex-column align-items-center">
                                <ul className="nav">
                                    {(role === "streamer" || role === "mod" || role === "creator") && (
                                        <li key="mod" className="nav-item" onClick={() => setSelectedTab("mod")}>
                                            <div className={"nav-link" + (selectedTab === "mod" ? " active-tab" : "")}>Mod Dashboard</div>
                                        </li>
                                    )}
                                    <li key="player" className="nav-item" onClick={() => setSelectedTab("player")}>
                                        <div className={"nav-link" + (selectedTab === "player" ? " active-tab" : "")}>Player Dashboard</div>
                                    </li>
                                    {allKeysList && allKeysList.length > 0 && (
                                        <li key="keys" className="nav-item" onClick={() => setSelectedTab("keys")}>
                                            <div className={"nav-link" + (selectedTab === "keys" ? " active-tab" : "")}>Keys</div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {selectedTab === "player" ? (
                                <PlayerDashboard
                                    gameID={gameID}
                                    cards={cards}
                                    setCards={setCards}
                                    allPromptsList={allPromptsList}
                                    allowReroll={settingsList.allow_rerolls}
                                    maxRerolls={settingsList.max_rerolls}
                                    showCalls={settingsList.show_calls}
                                    autoMark={false}
                                    showWinModal={() => setShowWinModal(true)}
                                    isOld={isOld}
                                />
                            ) : selectedTab === "mod" && (role === "mod" || role === "streamer" || role === "creator") ? (
                                <ModDashboard
                                    triggerReload={triggerReload}
                                    setTriggerReload={setTriggerReload}
                                    gameID={gameID}
                                    settingsList={settingsList}
                                    allPromptsList={allPromptsList}
                                    participants={participants}
                                    winnerList={winnerList}
                                    flaggedPlayers={flaggedPlayers}
                                    refreshWinners={refreshWinners}
                                    isOld={isOld}
                                />
                            ) : selectedTab === "keys" ? (
                                <div className="list-group">
                                    {allKeysList && allKeysList.length > 0 ?
                                        allKeysList?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map((key) => {
                                            return (
                                                <div
                                                    key={key.id}
                                                    className={
                                                        "list-group-item"
                                                        + (key.claimed ? " claimed bg-secondary" : "")
                                                    }
                                                >
                                                    <span className={key.claimed && "claimed"}>{key.name}</span>
                                                </div>
                                            )
                                        })
                                        : null}
                                </div>
                            ) : (
                                <Modal show={true} style={{ marginTop: "15%" }}>
                                    <ModalBody>
                                        <h3>Join game?</h3>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className="btn-modal" onClick={onGameJoin}>Join</Button>
                                        <Button className="btn-modal" onClick={() => navigate("/bingo")}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </div>
                    </div>
                )}
                <GameWinModal
                    show={showWinModal}
                    onClose={() => { setShowWinModal(false); refreshPlayerWins(); }}
                    keysList={allKeysList ? allKeysList.sort((a, b) => a.name > b.name) : []}
                />
                <Modal show={showOldModal} style={{ marginTop: "15%" }}>
                    <ModalBody>
                        <h4>This game is more than a day old! Are you sure you're in the right place?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-modal" onClick={() => setShowOldModal(false)}>Continue</Button>
                        <Button className="btn-modal" onClick={() => navigate("/bingo")}>Go Back</Button>
                    </ModalFooter>
                </Modal>
            </div>
        ) : null
    );
};

export default BingoGame;


