import React, { useEffect, useState, useRef } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Image, Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import '../../css/geoguesser.css';
import { GeoguesserMap } from "./GeoguesserMap";

const normalizeMeters = (num, max) => {
    return (num / max) * 1000
}

const GeoguesserJoin = () => {
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [message, setMessage] = useState("");
    const [selectedMap, setSelectedMap] = useState("");
    const [guess, setGuess] = useState();

    const checkClick = (e) => {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        var normGuessX = normalizeMeters(x, rect.width);
        var normGuessY = normalizeMeters(y, rect.height);
        setGuess({
            [selectedMap]: [{x: normGuessX, y: normGuessY}]
        });
        sendJsonMessage({
            action: "set_guess",
            guess: {
                map: selectedMap,
                location: {x: normGuessX, y: normGuessY}
            }
        });
    }

    const handleJoin = (e) => {
        e.preventDefault();
        setMessage("");
        const streamer = e.target.elements[0].value;
        if(streamer && streamer.length > 0 && readyState === ReadyState.OPEN) {
            sendJsonMessage({
                action: "join_streamer",
                streamer
            });
            setMessage("Waiting for " + streamer + " to start game...");
        }
    }

     // Set up web socket
    const { sendJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onMessage: (message) => {
            if(message.data){
                const data = JSON.parse(message.data);
                if(data.img_filename){
                    setImageUrl("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + data.img_filename);
                    setGuess();
                }
            }
        },
        share: true,
        filter: () => false,
        retryOnError: true,
        shouldReconnect: () => true
    });

    return (
        <div className="p-3">
            <div className="w-100 d-flex flex-column align-items-center">
                {imageUrl && imageUrl.length > 0 ? (
                    <div className={"split-container-wrapper mt-4 d-flex flex-row align-items-top gap-3 w-100"}>
                        { /* Left half */ }
                        <div className={"split-container-inner d-flex flex-column align-items-center text-light gap-3"}>
                            <h3>Where was this taken?</h3>
                            <div className="geo-img-wrapper" onClick={() => {setShowModal(true);}}>
                                <Image className="img-fluid geo-img-inner w-100 h-100" src={imageUrl}/>
                                <div className="geo-img-inner w-100 d-flex justify-content-end p-3">
                                    <div className="zoom-icon">
                                        <i className="fa-solid fa-magnifying-glass-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { /* Right half */ }
                        <div className={"split-container-inner d-flex flex-column align-items-center text-light"}>
                            <GeoguesserMap
                                selectedMap={selectedMap}
                                setSelectedMap={setSelectedMap}
                                actualMap={""}
                                checkClick={checkClick}
                                isViewer={true}
                                setShowModal={setShowModal}
                                userGuesses={guess}
                            />
                        </div>
                </div>
                ) : (
                    <div className="mt-4 d-flex flex-column gap-3 align-items-center justify-content-center">
                        <h3>Enter streamer name:</h3>
                        <form className="mt-2 d-flex gap-2" onSubmit={handleJoin}>
                            <input className="form-control" type="text" name="streamer"/>
                            <Button className="btn-accent1" type="submit">Join</Button>
                        </form>
                        {message}
                    </div>
                )}
            </div>

            <Modal show={showModal} fullscreen={true}>
                <ModalBody>
                    <Image className="img-fluid" src={imageUrl} />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-modal" onClick={() => setShowModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
};
  
export default GeoguesserJoin;