import React from 'react';

const Terms = () => {
    return (
        <div className="container d-flex flex-column align-items-center content-box">
            <div className="container m-3">
                <h1>Terms & Conditions</h1>
                <p className="mt-3"><i>Last updated January 6, 2024.</i></p>

                <h2><u>Key Points</u></h2>
                <ul>
                    <li>These key points highlight important information but do not substitute the terms below.</li>
                    <li>
                        By using this website, you indicate that you have read and understand these Terms and Conditions 
                        and agree to abide by them at all times.
                    </li>
                    <li>You must be over the age of 18 to use this site.</li>
                    <li>
                        Any content you post must not be unlawful, offensive, upsetting, intended to disgust, threatening, libelous, 
                        defamatory, obscene or otherwise objectionable. Examples can be found below.
                    </li>
                    <li>
                        We can not control all content posted by other users. 
                        Thus, you may be exposed to content that You may find offensive, indecent, incorrect or objectionable.
                    </li>
                    <li>Your account may be terminated if you breach the Terms & Conditions.</li>
                </ul>
                
                <h2><u>Full Terms & Conditions</u></h2>
                <p><i>
                    Referenced from <a href="https://www.lawdepot.com/contracts/website-terms-and-conditions/?loc=US">https://www.lawdepot.com/contracts/website-terms-and-conditions/?loc=US</a>
                    <br/>and <a href="https://www.termsfeed.com/blog/sample-terms-and-conditions-template">https://www.termsfeed.com/blog/sample-terms-and-conditions-template</a>
                </i></p>

                <h3>Definitions</h3>
                <p>
                    The words of which the initial letter is capitalized have meanings defined under the following conditions.
                    The following definitions shall have the same meaning regardless of whether they appear in singular or in
                    plural.
                    For the purposes of these Terms and Conditions:
                    <ul>
                        <li>
                            <b>"Account"</b> - 
                            a unique account created for You to access Our Service.
                        </li>
                        <li>
                            <b>"Content"</b> - 
                            content such as text, images, or other information that can be posted, uploaded,
                            linked to or otherwise made available by You, regardless of the form of that content.
                        </li>
                        <li>
                            <b>"Device"</b> - 
                            any device that can access this Site such as a computer, a cellphone or a digital tablet.
                        </li>
                        <li>
                            <b>"Feedback"</b> - 
                            feedback, innovations, or suggestions sent by You regarding the attributes, performance or features of this Site.
                        </li>
                        <li>
                            <b>"Site"</b> - 
                            this website, Hunt Minigames / Bayou Bingo, accessible from www.bayoubingo.com.
                        </li>
                        <li>
                            <b>"Terms and Conditions"</b> (a.k.a. <b>"Terms"</b>) - 
                            these Terms and Conditions that form the entire agreement between You and Us regarding the use of this Site.
                        </li>
                        <li>
                            <b>"Us / We"</b> - 
                            the creators and maintainers of this Site.
                        </li>
                        <li>
                            <b>"You"</b> - 
                            the individual accessing or using this Site, or the company or other legal entity on 
                            behalf of which such individual is accessing or using this Site.
                        </li>
                    </ul>
                </p>

                <h3>Acknowledgment</h3>
                <p>
                    These are the Terms and Conditions governing the use of this Site and the agreement that operates
                    between You and Us.
                    Your access to and use of this Site is conditioned on Your acceptance of and compliance with these
                    Terms. 
                    These Terms apply to all visitors, users and others who access or use this Site.
                    By accessing or using this Site You agree to be bound by these Terms and Conditions. If You disagree
                    with any part of these Terms and Conditions then You may not access this Site.
                </p><p>
                    You represent that you are over the age of 18. We do not permit those under 18 to use this Site.
                </p><p>
                    Your access to and use of this Site is also conditioned on Your acceptance of and compliance with 
                    Our <a href="/privacy">Privacy Policy</a>. Please read the Privacy Policy carefully before using this Site.
                </p>

                <h3>Accounts</h3>
                <p>
                    When You create an account on our Site, you agree to the following:
                    <ul>
                        <li>
                            You may not use as a username the name of another person or entity or that is not lawfully available for
                            use, a name or trademark that is subject to any rights of another person or entity other than You without
                            appropriate authorization, or a name that is otherwise offensive, vulgar or obscene;
                        </li>
                        <li>
                            You are solely responsible for Your Account and the security and privacy of your Account, including
                            passwords or sensitive information attached to that Account; and
                        </li>
                        <li>
                            All personal information you provide to Us through your Account is up to date, accurate, and truthful
                            and that you will update your personal information if it changes.
                        </li>
                    </ul>
                    We reserve the right to suspend or terminate Your Account if you are using our Site illegally or if you violate
                    these Terms and Conditions.
                </p>

                <h3>Content</h3>
                <h4>Your Right to Post Content</h4>
                <p>
                    This Site allows You to post Content. 
                    You are responsible for the Content that You post to the Site, including its legality, reliability, and appropriateness.
                </p><p>
                    By posting Content to the Site, You grant Us the right and license to use, modify, publicly perform,
                    publicly display, reproduce, and distribute such Content on and through the Site. 
                    You retain any and all of Your rights to any Content You submit, post or display on or through the Site and 
                    You are responsible for protecting those rights. 
                    You agree that this license includes the right for Us to make Your Content available to other users of the Site, 
                    who may also use Your Content subject to these Terms.
                </p><p>
                    You represent and warrant that: 
                    (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided 
                    in these Terms, and 
                    (ii) the posting of Your Content on or through the Site does not violate the privacy rights, publicity rights, copyrights, 
                    contract rights or any other rights of any person.
                </p>
                <h4>Content Restrictions</h4>
                <p>
                    We are not responsible for the content of the Site's users. 
                    You expressly understand and agree that You are solely responsible for the Content and for all activity 
                    that occurs under your account, whether done so by You or any third person using Your account.
                </p><p>
                    You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening,
                    libelous, defamatory, obscene or otherwise objectionable. 
                    Examples of such objectionable Content include, but are not limited to, the following:
                    <ul>
                        <li>
                            Unlawful or promoting unlawful activity.
                        </li>
                        <li>
                            Defamatory, discriminatory, or mean-spirited content, including references or commentary about
                            religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
                        </li>
                        <li>
                            Spam constituting unauthorized or unsolicited advertising, chain letters, any other form of 
                            unauthorized solicitation, or any form of lottery or gambling.
                        </li>
                        <li>
                            Containing or installing any viruses, worms, malware, trojan horses, or other content that is
                            designed or intended to disrupt, damage, or limit the functioning of any software, hardware or
                            telecommunications equipment or to damage or obtain unauthorized access to any data or other
                            information of a third person.
                        </li>
                        <li>
                            Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright,
                            right of publicity or other rights.
                        </li>
                        <li>
                            Impersonating any person or entity including Us and our representatives.
                        </li>
                        <li>
                            Violating the privacy of any third person.
                        </li>
                        <li>
                            False information and features.
                        </li>
                    </ul>
                    We reserve the right, but not the obligation, to, in Our sole discretion, determine whether or not
                    any Content is appropriate and complies with this Terms, refuse, or remove this Content. 
                    We further reserve the right to make formatting and edits and change the manner of any Content. 
                    We can also limit or revoke the use of the Site if You post such objectionable Content. 
                </p><p>
                    As We cannot control all content posted by users and/or third parties on the Site, you agree to use the Site 
                    at your own risk. 
                    You understand that by using the Site You may be exposed to content that You may find offensive, indecent, 
                    incorrect or objectionable, and You agree that under no circumstances will We be liable in any way for any content, 
                    including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of Your use 
                    of any Content.
                </p>
                <h4>Content Loss</h4>
                <p>
                    We do not guarantee there will be no loss or corruption of data.
                    We will provide support and attempt to troubleshoot any known or discovered issues that may affect the Content.
                    But You acknowledge that We have no liability related to the integrity of Content or the failure to successfully 
                    restore Content to a usable state.
                    You agree to maintain a complete and accurate copy of any Content in a location independent of the Site.
                </p>

                <h3>Your Feedback to Us</h3>
                <p>
                    You assign all rights, title and interest in any Feedback You provide Us. 
                    If for any reason such assignment is ineffective, You agree to grant Us a non-exclusive, perpetual, 
                    irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and 
                    exploit such Feedback without restriction.
                </p>

                <h3>Links to Other Websites</h3>
                <p>
                    Our Site contains links to third party websites or services that we do not own or control. 
                    We are not responsible for the content, policies, or practices of any third party website or service linked to on our Site. 
                    You further acknowledge and agree that We shall not be responsible or liable, directly or indirectly, for any damage or loss 
                    caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services 
                    available on or through any such web sites or services.
                </p><p>
                    It is your responsibility to read the Terms and Conditions and privacy policies of these third party websites before
                    using these sites.
                </p>

                <h3>Termination</h3>
                <p>
                    We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
                    whatsoever, including without limitation if You breach these Terms and Conditions.
                    Upon termination, Your right to use the Site will cease immediately. 
                    If You wish to terminate Your Account, You may simply discontinue using the Site.
                </p>

                <h3>Limitation of Liability</h3>
                <p>
                    To the maximum extent permitted by applicable law, in no event shall We be liable for any special, incidental, 
                    indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of
                    data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any 
                    way related to the use of or inability to use the Site, third-party software and/or third-party hardware used with 
                    the Site, or otherwise in connection with any provision of this Terms), even if We have been advised of the possibility 
                    of such damages and even if the remedy fails of its essential purpose.
                </p><p>
                    Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
                    consequential damages, which means that some of the above limitations may not apply. 
                    In these states, each party's liability will be limited to the greatest extent permitted by law.
                </p>

                <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                <p>
                    The Site is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. 
                    To the maximum extent permitted under applicable law, We expressly disclaim all warranties, whether express, implied, 
                    statutory or otherwise, with respect to the Site, including all implied warranties of merchantability, fitness for a 
                    particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of 
                    performance, usage or trade practice. 
                </p><p>
                    Without limitation to the foregoing, We provide no warranty or undertaking, and make no representation of any kind 
                    that the Site will meet Your requirements, achieve any intended results, be compatible or work with any other software, 
                    applications, systems or services, operate without interruption, meet any performance or reliability standards or be 
                    error free or that any errors or defects can or will be corrected.
                </p><p>
                    Without limiting the foregoing, We do not make any representation or warranty of any kind, express or implied: 
                    (i) as to the operation or availability of the Site, or the information, content, and materials or products included 
                    thereon; (ii) that the Site will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency 
                    of any information or content provided through the Site; or (iv) that the Site, its servers, the content, or e-mails 
                    sent from or on behalf of Us are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
                    harmful components.
                </p><p>
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
                    statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
                    But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest
                    extent enforceable under applicable law.
                </p>

                <h3>Applicable Law</h3>
                <p>
                    These Terms and Conditions are governed by the laws of the United States and the State of Missouri.
                    If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the
                    country in which you are resident in.
                </p>

                <h3>United States Legal Compliance</h3>
                <p>
                    You represent and warrant that (i) You are not located in a country that is subject to the United States
                    government embargo, or that has been designated by the United States government as a "terrorist
                    supporting" country, and (ii) You are not listed on any United States government list of prohibited or
                    restricted parties.
                </p>

                <h3>Severability</h3>
                <p>
                    If an any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent
                    or invalid under applicable laws, those provisions will be deemed void and will be removed from these
                    Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms
                    and Conditions will still be considered valid.
                </p>

                <h3>Changes</h3>
                <p>
                    These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and
                    to reflect any changes to the way We operate our Site and the way We expect users to behave on our Site. We
                    will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
                    By continuing to access or use Our Site after those revisions become effective, You agree to be bound
                    by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
                    website and the Site.
                </p>

                <h3>Contact Details</h3>
                <p>
                    Please contact us if you have any questions or concerns at <a href="mailto:bayoubingoweb@gmail.com">bayoubingoweb@gmail.com</a>.
                </p>

            </div>
        </div>
    );
};
  
export default Terms;
  