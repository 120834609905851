import React from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { ProfileLink } from "../ProfileComponents"
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';
const api = require('../../api');

export const ParticipantsBlock = ({gameID, refreshGameInfo, participantsList, editable, isFull}) => {
    const [cookies] = useCookies(["authToken"]);
    const [editing, setEditing] = React.useState(false);

    const handleAddParticipant = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/bingo/games/addParticipantsByUsername', cookies.authToken, { 
            "game_id": gameID,
            "participant_list": [elements.participantUsername.value],
            "role": elements.participantType.value
        }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }
    const handleRemoveParticipant = async (event) => {
        event.preventDefault();
        api.postAuth('/bingo/games/removeParticipantsByUsername', cookies.authToken, { 
            "game_id": gameID,
            "participant_list": [event.target.elements.participantUsername.value]
        }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }

    const participantTypeOptions= [
        { value: "player", label: "Player" }, 
        { value: "mod", label: "Mod" }, 
        { value: "streamer", label: "Streamer" }
    ]
    
    return (
        <div className="p-3">
            <div className="d-flex flex-row justify-content-end">
                { editing ? (
                    <Button className="game-detail-button" onClick={() => setEditing(false)}>Cancel</Button>
                ) : (
                    editable ? (<Button className="game-detail-button" onClick={() => setEditing(true)}>Edit</Button>) : null
                )}
            </div>
            
            { editing ? (
                <div>
                    <h5>Streamers</h5>
                    <ul>
                        { participantsList.streamers ? 
                            participantsList.streamers.map((user, i) => {
                                return (
                                    <li key={"user_" + i}>
                                        <div className="d-flex flex-row align-items-center">
                                            <form onSubmit={handleRemoveParticipant} className="d-flex flex-row align-items-center">
                                                <input hidden className="m-1" type="text" name="participantUsername" value={user.username} readOnly/>
                                                {user.username}
                                                { participantsList.streamers.length > 1 ? (
                                                    <Button className="btn-sm btn-danger m-1 game-detail-button" type="submit">Remove</Button>
                                                ) : null }
                                            </form>
                                        </div>
                                    </li>
                                )
                            }) 
                        : null }
                    </ul>
                    <h5 className="mt-3">Mods</h5>
                    <ul>
                        { participantsList.mods ? 
                            participantsList.mods.map((mod, i) => {
                                return (
                                    <li key={"mod_" + i}>
                                        <div className="d-flex flex-row align-items-center">
                                            <form onSubmit={handleRemoveParticipant} className="d-flex flex-row align-items-center">
                                                <input hidden className="m-1" type="text" name="participantUsername" value={mod.username} readOnly/>
                                                {mod.username}
                                                <Button className="btn-sm btn-danger m-1 game-detail-button" type="submit">Remove</Button>
                                            </form>
                                        </div>
                                    </li>
                                )
                            }) 
                        : null }
                    </ul>
                    <h5 className="mt-3">Players</h5>
                    <ul>
                        { participantsList.players ? 
                            participantsList.players.map((streamer, i) => {
                                return (
                                    <li key={"streamer_" + i}>
                                        <div className="d-flex flex-row align-items-center">
                                            <form onSubmit={handleRemoveParticipant} className="d-flex flex-row align-items-center">
                                                <input hidden className="m-1" type="text" name="participantUsername" value={streamer.username} readOnly/>
                                                {streamer.username}
                                                <Button className="btn-sm btn-danger m-1 game-detail-button" type="submit">Remove</Button>
                                            </form>
                                        </div>
                                    </li>
                                )
                            }) 
                        : null }
                    </ul>
                </div>
            ) : (
                <div>
                    {editable && !isFull ? (
                        <div>
                            <h5 className="m-1">Add / Edit Participant</h5>
                            <form className="d-flex flex-wrap flex-row align-items-center form-row" onSubmit={handleAddParticipant}>
                                <input required className="m-1 settings-content-small form-control" type="text" name="participantUsername" placeholder="Username"/>
                                <Select required
                                    className="m-1 text-dark game-detail-select"
                                    name="participantType"
                                    options={participantTypeOptions} 
                                    defaultValue={participantTypeOptions[0]}
                                />
                                <Button className="btn-sm btn-success game-detail-button" type="submit">Add</Button>
                            </form>
                        </div>
                    ) : null}
                    <b>Streamers: </b> 
                    <div className="d-flex flex-row flex-wrap">{participantsList.streamers ? participantsList.streamers.map((s, i) => (<>{i > 0 && (<div>,&nbsp;</div>)}<ProfileLink username={s.username}/></>)) : "None"}</div>
                    <br/>
                    <b>Mods: </b> 
                    <div className="d-flex flex-row flex-wrap">{participantsList.mods ? participantsList.mods.map((s, i) => (<>{i > 0 && (<div>,&nbsp;</div>)}<ProfileLink username={s.username}/></>)) : "None"}</div>
                    <br/>
                    <b>Players ({participantsList.players ? participantsList.players.length : "0"}): </b> 
                    <div className="d-flex flex-row flex-wrap">{participantsList.players ? participantsList.players.map((s, i) => (<>{i > 0 && (<div>,&nbsp;</div>)}<ProfileLink username={s.username}/></>)) : "None"}</div>
                </div>
            )}
        </div>
    )
}