const weapons_3slot = [
    {
        category: "Berthier",
        ammo_type: "Long",
        variants: [
            { name: "Berthier Mle 1892", ammo1: ["Incendiary", "Spitzer"], ammo2: ["Incendiary", "Spitzer"], img: "/items/weapons/berthier.png" },
            { name: "Berthier Mle 1892 Riposte",  ammo1: ["Incendiary", "Spitzer"], ammo2: ["Incendiary", "Spitzer"], img: "/items/weapons/berthier_riposte.png" },
            { name: "Berthier Mle 1892 Deadeye", ammo1: ["Incendiary", "Spitzer"], ammo2: ["Incendiary", "Spitzer"], img: "/items/weapons/berthier_deadeye.png" },
            { name: "Berthier Mle 1892 Marksman", ammo1: ["Incendiary", "Spitzer"], ammo2: ["Incendiary", "Spitzer"], img: "/items/weapons/berthier_marksman.png" },
        ]
    },
    {
        category: "Bomb Lance",
        variants: [
            { name: "Bomb Lance", ammo1: ["Dragon Breath", "Steel Ball Ammo", "Waxed Frag Charge"], ammo2: ["Dragon Breath", "Steel Ball Ammo", "Waxed Frag Charge"], img: "/items/weapons/bomblance.png" },
        ]
    },
    {
        category: "Rival",
        variants: [
            { name: "Caldwell Rival 78", ammo1: ["Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/rival.png" },
        ]
    },
    {
        category: "Crossbow",
        variants: [
            { name: "Crossbow", ammo1: ["Explosive Bolt", "Shot Bolt"], ammo2: ["Explosive Bolt", "Shot Bolt"], img: "/items/weapons/crossbow.png" },
        ]
    },
    {
        category: "Crown",
        variants: [
            { name: "Crown & King Auto-5", ammo1: ["Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/crown.png" },
        ]
    },
    {
        category: "Drilling",
        variants: [
            { name: "Drilling", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: ["Flechette", "Penny Shot", "Slug"], img: "/items/weapons/drilling.png" },
        ]
    },
    {
        category: "Lebel",
        variants: [
            { name: "Lebel 1886", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/lebel.png" },
            { name: "Lebel 1886 Marksman", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/lebel_marksman.png" },
            { name: "Lebel 1886 Talon", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/lebel_talon.png" },
        ]
    },
    {
        category: "LeMat",
        variants: [
            { name: "LeMat Mark II Carbine", ammo1: ["Full Metal Jacket", "Incendiary"], ammo2: ["Dragon Breath", "Slug", "Starshell"], img: "/items/weapons/lemat_carbine.png" },
        ]
    },
    {
        category: "Martini-Henry",
        variants: [
            { name: "Martini-Henry IC1", ammo1: ["Explosive", "Full Metal Jacket", "Incendiary"], ammo2: ["Explosive", "Full Metal Jacket", "Incendiary"], img: "/items/weapons/martinihenry.png" },
            { name: "Martini-Henry IC1 Deadeye", ammo1: ["Explosive", "Full Metal Jacket", "Incendiary"], ammo2: ["Explosive", "Full Metal Jacket", "Incendiary"], img: "/items/weapons/martinihenry_deadeye.png" },
            { name: "Martini-Henry IC1 Marksman", ammo1: ["Explosive", "Full Metal Jacket", "Incendiary"], ammo2: ["Explosive", "Full Metal Jacket", "Incendiary"], img: "/items/weapons/martinihenry_marksman.png" },
            { name: "Martini-Henry IC1 Riposte", ammo1: ["Explosive", "Full Metal Jacket", "Incendiary"], ammo2: ["Explosive", "Full Metal Jacket", "Incendiary"], img: "/items/weapons/martinihenry_riposte.png" },
            { name: "Martini-Henry IC1 Ironside", ammo1: ["Explosive", "Full Metal Jacket", "Incendiary"], ammo2: [], img: "/items/weapons/martinihenry_ironside.png" },
        ]
    },
    {
        category: "Mosin",
        variants: [
            { name: "Mosin-Nagant M1891", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin.png" },
            { name: "Mosin-Nagant M1891 Avtomat", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_avtomat.png" },
            { name: "Mosin-Nagant M1891 Bayonet", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_bayonet.png" },
            { name: "Mosin-Nagant M1891 Sniper", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_sniper.png" },
        ]
    },
    {
        category: "Nagant",
        variants: [
            { name: "Nagant M1895 Officer Carbine", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_officer_carbine.png" },
            { name: "Nagant M1895 Officer Carbine Deadeye", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_officer_carbine_deadeye.png" },
        ]
    },
    {
        category: "Nitro",
        variants: [
            { name: "Nitro Express Rifle", ammo1: ["Explosive", "Shredder"], ammo2: [], img: "/items/weapons/nitro.png" },
        ]
    },
    {
        category: "Romero",
        variants: [
            { name: "Romero 77", ammo1: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], ammo2: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], img: "/items/weapons/romero.png" },
            { name: "Romero 77 Talon", ammo1: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], ammo2: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], img: "/items/weapons/romero_talon.png" },
            { name: "Romero 77 Alamo", ammo1: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], ammo2: [], img: "/items/weapons/romero_alamo.png" },
        ]
    },
    {
        category: "Sparks",
        variants: [
            { name: "Sparks LRR", ammo1: ["Full Metal Jacket", "Incendiary", "Poison"], ammo2: ["Full Metal Jacket", "Incendiary", "Poison"], img: "/items/weapons/sparks.png" },
            { name: "Sparks LRR Silencer", ammo1: ["Full Metal Jacket", "Incendiary", "Poison"], ammo2: ["Full Metal Jacket", "Incendiary", "Poison"], img: "/items/weapons/sparks_silencer.png" },
            { name: "Sparks LRR Sniper", ammo1: ["Full Metal Jacket", "Incendiary", "Poison"], ammo2: ["Full Metal Jacket", "Incendiary", "Poison"], img: "/items/weapons/sparks_sniper.png" },
        ]
    },
    {
        category: "Specter",
        variants: [
            { name: "Specter 1882", ammo1: ["Dragon Breath", "Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/specter.png" },
            { name: "Specter 1882 Bayonet", ammo1: ["Dragon Breath", "Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/specter_bayonet.png" },
        ]
    },
    {
        category: "Springfield",
        variants: [
            { name: "Springfield 1866", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield.png" },
            { name: "Springfield 1866 Bayonet", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield_bayonet.png" },
            { name: "Springfield 1866 Marksman", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield_marksman.png" },
        ]
    },
    {
        category: "Krag",
        variants: [
            { name: "Springfield 1866 Krag", ammo1: ["Full Metal Jacket", "Incendiary"], ammo2: [], img: "/items/weapons/krag.png" },
            { name: "Springfield 1866 Krag Bayonet", ammo1: ["Full Metal Jacket", "Incendiary"], ammo2: [], img: "/items/weapons/krag_bayonet.png" },
            { name: "Springfield 1866 Krag Sniper", ammo1: ["Full Metal Jacket", "Incendiary"], ammo2: [], img: "/items/weapons/krag_sniper.png" },
        ]
    },
    {
        category: "Vetterli",
        variants: [
            { name: "Vetterli 71 Karabiner", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli.png" },
            { name: "Vetterli 71 Karabiner Bayonet", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli_bayonet.png" },
            { name: "Vetterli 71 Karabiner Deadeye", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli_deadeye.png" },
            { name: "Vetterli 71 Karabiner Marksman", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli_marksman.png" },
            { name: "Vetterli 71 Karabiner Silencer", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli_silencer.png" },
            { name: "Vetterli 71 Karabiner Cyclone", ammo1: ["Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/vetterli_cyclone.png" },
        ]
    },
    {
        category: "Terminus",
        variants: [
            { name: "Winfield 1887 Terminus", ammo1: ["Dragon Breath", "Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/terminus.png" },
        ]
    },
    {
        category: "Slate",
        variants: [
            { name: "Winfield 1893 Slate", ammo1: ["Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/slate.png" },
            { name: "Winfield 1893 Slate Riposte", ammo1: ["Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/slate_riposte.png" },
        ]
    },
    {
        category: "Winfield",
        variants: [
            { name: "Winfield M1873", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfield.png" },
            { name: "Winfield M1873 Aperture", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfield_aperture.png" },
            { name: "Winfield M1873 Musket Bayonet", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfield_bayonet.png" },
            { name: "Winfield M1873 Swift", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfield_swift.png" },
            { name: "Winfield M1873 Talon", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfield_talon.png" },
            { name: "Winfield M1873C", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc.png" },
            { name: "Winfield M1873C Marksman", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc_marksman.png" },
            { name: "Winfield M1873C Silencer", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc_silencer.png" },
        ]
    },
    {
        category: "Centennial",
        variants: [
            { name: "Winfield M1879 Centennial", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Poison"], ammo2: [], img: "/items/weapons/centennial.png" },
            { name: "Winfield M1879 Centennial Sniper", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Poison"], ammo2: [], img: "/items/weapons/centennial_sniper.png" },
            { name: "Winfield M1879 Centennial Trauma", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Poison"], ammo2: [], img: "/items/weapons/centennial_trauma.png" },
        ]
    },
];

const weapons_2slot = [
    {
        category: "Bornheim",
        variants: [
            { name: "Bornheim No. 3 Match", ammo1: ["High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/bornheim_match.png" },
        ]
    },
    {
        category: "Caldwell Conversion Uppercut",
        variants: [
            { name: "Caldwell Conversion Uppercut Precision", ammo1: ["Full Metal Jacket", "Explosive", "Incendiary"], ammo2: [], img: "/items/weapons/uppercut_precision.png" },
            { name: "Caldwell Conversion Uppercut Precision Deadeye", ammo1: ["Full Metal Jacket", "Explosive", "Incendiary"], ammo2: [], img: "/items/weapons/uppercut_precision_deadeye.png" },
        ]
    },
    {
        category: "Rival",
        variants: [
            { name: "Caldwell Rival 78 Handcannon", ammo1: ["Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/rival.png" },
        ]
    },
    {
        category: "Combat Axe",
        variants: [
            { name: "Combat Axe", ammo1: [], ammo2: [], img: "/items/weapons/axe.png" },
        ]
    },
    {
        category: "Dolch",
        variants: [
            { name: "Dolch 96 Precision", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/dolch_precision.png" },
        ]
    },
    {
        category: "Drilling",
        variants: [
            { name: "Drilling Handcannon", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: ["Flechette", "Penny Shot", "Slug"], img: "/items/weapons/drilling_handcannon.png" },
        ]
    },
    {
        category: "Hunting Bow",
        variants: [
            { name: "Hunting Bow", ammo1: ["Concertina Arrow", "Frag Arrow", "Poison Arrow"], ammo2: ["Concertina Arrow", "Frag Arrow", "Poison Arrow"], img: "/items/weapons/bow.png" },
        ]
    },
    {
        category: "LeMat",
        variants: [
            { name: "LeMat Mark II UpperMat", ammo1: ["Full Metal Jacket", "Poison"], ammo2: ["Dragon Breath", "Slug", "Starshell"], img: "/items/weapons/lemat_uppermat.png" },
        ]
    },
    {
        category: "Mosin",
        variants: [
            { name: "Mosin-Nagant M1891 Obrez", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_obrez.png" },
            { name: "Mosin-Nagant M1891 Obrez Drum", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_obrez_drum.png" },
            { name: "Mosin-Nagant M1891 Obrez Mace", ammo1: ["Incendiary", "Spitzer"], ammo2: [], img: "/items/weapons/mosin_obrez_mace.png" },
        ]
    },
    {
        category: "Nagant",
        variants: [
            { name: "Nagant M1895 Precision", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_precision.png" },
            { name: "Nagant M1895 Precision Deadeye", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_precision_deadeye.png" },
        ]
    },
    {
        category: "Railroad Hammer",
        variants: [
            { name: "Railroad Hammer", ammo1: [], ammo2: [], img: "/items/weapons/railroadhammer.png" },
        ]
    },
    {
        category: "Romero",
        variants: [
            { name: "Romero 77 Handcannon", ammo1: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], ammo2: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], img: "/items/weapons/romero_handcannon.png" },
            { name: "Romero 77 Hatchet", ammo1: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], ammo2: ["Dragon Breath", "Penny Shot", "Slug", "Starshell"], img: "/items/weapons/romero_hatchet.png" },
        ]
    },
    {
        category: "Scottfield",
        variants: [
            { name: "Scottfield Model 3 Precision", ammo1: ["Dumdum", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/scottfield_precision.png" },
        ]
    },
    {
        category: "Specter",
        variants: [
            { name: "Specter 1882 Compact", ammo1: ["Dragon Breath", "Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/specter_compact.png" },
        ]
    },
    {
        category: "Springfield",
        variants: [
            { name: "Springfield 1866 Compact", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield_compact.png" },
            { name: "Springfield 1866 Compact Striker", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield_compact_striker.png" },
            { name: "Springfield 1866 Compact Deadeye", ammo1: ["Dumdum", "Explosive", "High Velocity", "Poison"], ammo2: ["Dumdum", "Explosive", "High Velocity", "Poison"], img: "/items/weapons/springfield_compact_deadeye.png" },
        ]
    },
    {
        category: "Terminus",
        variants: [
            { name: "Winfield 1887 Terminus Handcannon", ammo1: ["Dragon Breath", "Flechette", "Penny Shot", "Slug"], ammo2: [], img: "/items/weapons/terminus_handcannon.png" },
        ]
    },
    {
        category: "Winfield",
        variants: [
            { name: "Winfield M1873C Vandal", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc_vandal.png" },
            { name: "Winfield M1873C Vandal Striker", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc_vandal_striker.png" },
            { name: "Winfield M1873C Vandal Deadeye", ammo1: ["Poison", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/winfieldc_vandal_deadeye.png" },
        ]
    },
    {
        category: "Centennial",
        variants: [
            { name: "Winfield M1876 Centennial Shorty", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Poison"], ammo2: [], img: "/items/weapons/centennial_shorty.png" },
            { name: "Winfield M1876 Centennial Shorty Silencer", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Poison"], ammo2: [], img: "/items/weapons/centennial_shorty_silencer.png" },
        ]
    },
];

const weapons_1slot = [
    {
        category: "Baseball Bat",
        variants: [
            { name: "Baseball Bat", ammo1: [], ammo2: [], img: "/items/weapons/bat.png" },
        ]
    },
    {
        category: "Bornheim",
        variants: [
            { name: "Bornheim No. 3", ammo1: ["High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/bornheim.png" },
            { name: "Bornheim No. 3 Silencer", ammo1: ["High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/bornheim_silencer.png" },
            { name: "Bornheim No. 3 Extended", ammo1: ["High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/bornheim_extended.png" },
        ]
    },
    {
        category: "New Army",
        variants: [
            { name: "Caldwell 92 New Army", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/newarmy.png" },
            { name: "Caldwell 92 New Army Swift", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/newarmy_swift.png" },
        ]
    },
    {
        category: "Caldwell Conversion",
        variants: [
            { name: "Caldwell Conversion Pistol", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/caldwell.png" },
            { name: "Caldwell Conversion Chain Pistol", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/caldwell_chain.png" },
        ]
    },
    {
        category: "Caldwell Conversion Uppercut",
        variants: [
            { name: "Caldwell Conversion Uppercut", ammo1: ["Full Metal Jacket", "Explosive", "Incendiary"], ammo2: [], img: "/items/weapons/uppercut.png" },
        ]
    },
    {
        category: "Caldwell Pax",
        variants: [
            { name: "Caldwell Pax", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Incendiary", "Poison"], ammo2: [], img: "/items/weapons/pax.png" },
            { name: "Caldwell Pax Claw", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Incendiary", "Poison"], ammo2: [], img: "/items/weapons/pax_claw.png" },
            { name: "Caldwell Pax Trueshot", ammo1: ["High Velocity", "Dumdum", "Full Metal Jacket", "Incendiary", "Poison"], ammo2: [], img: "/items/weapons/pax_trueshot.png" },
        ]
    },
    {
        category: "Cavalry Saber",
        variants: [
            { name: "Cavalry Saber", ammo1: [], ammo2: [], img: "/items/weapons/saber.png" },
        ]
    },
    {
        category: "Dolch",
        variants: [
            { name: "Dolch 96", ammo1: ["Dumdum", "Full Metal Jacket"], ammo2: [], img: "/items/weapons/dolch.png" },
        ]
    },
    {
        category: "Hand Crossbow",
        variants: [
            { name: "Hand Crossbow", ammo1: ["Chaos Bolt", "Choke Bolt", "Dragon Bolt", "Poison Bolt"], ammo2: ["Chaos Bolt", "Choke Bolt", "Dragon Bolt", "Poison Bolt"], img: "/items/weapons/handcrossbow.png" },
        ]
    },
    {
        category: "LeMat",
        variants: [
            { name: "LeMat Mark II", ammo1: ["Full Metal Jacket", "Incendiary"], ammo2: ["Dragon Breath", "Slug", "Starshell"], img: "/items/weapons/lemat.png" },
        ]
    },
    {
        category: "Machete",
        variants: [
            { name: "Machete", ammo1: [], ammo2: [], img: "/items/weapons/machete.png" },
        ]
    },
    {
        category: "Nagant",
        variants: [
            { name: "Nagant M1895", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant.png" },
            { name: "Nagant M1895 Silencer", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_silencer.png" },
            { name: "Nagant M1895 Officer", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_officer.png" },
            { name: "Nagant M1895 Officer Brawler", ammo1: ["Dumdum", "High Velocity", "Poison"], ammo2: [], img: "/items/weapons/nagant_officer_brawler.png" },
        ]
    },
    {
        category: "Scottfield",
        variants: [
            { name: "Scottfield Model 3", ammo1: ["Dumdum", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/scottfield.png" },
            { name: "Scottfield Model 3 Brawler", ammo1: ["Dumdum", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/scottfield_brawler.png" },
            { name: "Scottfield Model 3 Spitfire", ammo1: ["Dumdum", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/scottfield_spitfire.png" },
            { name: "Scottfield Model 3 Swift", ammo1: ["Dumdum", "Full Metal Jacket", "High Velocity", "Incendiary"], ammo2: [], img: "/items/weapons/scottfield_swift.png" },
        ]
    },
    {
        category: "Sparks",
        variants: [
            { name: "Sparks Pistol", ammo1: ["Full Metal Jacket", "Incendiary", "Poison"], ammo2: ["Full Metal Jacket", "Incendiary", "Poison"], img: "/items/weapons/sparks_pistol.png" },
        ]
    },
];

const tools_list = [
    { name: "Alert Trip Mine", rank: 35, img: "/items/tools/alertmines.png" },
    { name: "Blank Fire Decoys", rank: 52, img: "/items/tools/blankfiredecoys.png" },
    { name: "Choke Bomb", rank: 1, img: "/items/tools/chokes.png" },
    { name: "Concertina Trip Mine", rank: 29, img: "/items/tools/concertinamines.png" },
    { name: "Decoy Fuses", rank: 52, img: "/items/tools/decoyfuses.png" },
    { name: "Decoys", rank: 1, img: "/items/tools/decoys.png" },
    { name: "Dusters", rank: 1, img: "/items/tools/dusters.png" },
    { name: "Derringer Pennyshot", rank: 17, img: "/items/tools/derringer_pennyshot.png" },
    { name: "Electric Lamp", rank: 1, img: "/items/tools/lamp.png" },
    { name: "First Aid Kit", rank: 1, img: "/items/tools/firstaidkit.png" },
    { name: "Flare Pistol", rank: 23, img: "/items/tools/flarepistol.png" },
    { name: "Fusees", rank: 1, img: "/items/tools/fusees.png" },
    { name: "Heavy Knife", rank: 5, img: "/items/tools/heavyknife.png" },
    { name: "Knife", rank: 1, img: "/items/tools/knife.png" },
    { name: "Knuckle Knife", rank: 25, img: "/items/tools/knuckleknife.png" },
    { name: "Poison Trip Mine", rank: 29, img: "/items/tools/poisonmines.png" },
    { name: "Quad Derringer", rank: 1, img: "/items/tools/derringer.png" },
    { name: "Spyglass", rank: 1, img: "/items/tools/spyglass.png" },
    { name: "Throwing Axes", rank: 8, img: "/items/tools/throwingaxes.png" },
    { name: "Throwing Knives", rank: 2, img: "/items/tools/throwingknives.png" },
    //TODO: { name: "Throwing Spear", rank: 33, img: "/items/tools/.png" },
];
const tools_melee = [
    { name: "Dusters", rank: 1, img: "/items/tools/dusters.png" },
    { name: "Heavy Knife", rank: 5, img: "/items/tools/heavyknife.png" },
    { name: "Knife", rank: 1, img: "/items/tools/knife.png" },
    { name: "Knuckle Knife", rank: 25, img: "/items/tools/knuckleknife.png" },
];
const consumables_list = [
    { name: "Ammo Box", rank: 1, img: "/items/consumables/ammobox.png" },
    { name: "Antidote Shot", rank: 21, img: "/items/consumables/antidote.png" },
    { name: "Big Dynamite Bundle", rank: 58, img: "/items/consumables/bigdynamite.png" },
    { name: "Chaos Bomb", rank: 31, img: "/items/consumables/chaosbomb.png" },
    { name: "Choke Beetle", rank: 46, img: "/items/consumables/chokebeetle.png" },
    { name: "Concertina Bomb", rank: 37, img: "/items/consumables/concertina.png" },
    { name: "Dynamite Bundle", rank: 15, img: "/items/consumables/dynamitebundle.png" },
    { name: "Dynamite Stick", rank: 1, img: "/items/consumables/dynamitestick.png" },
    { name: "Fire Beetle", rank: 46, img: "/items/consumables/firebeetle.png" },
    { name: "Fire Bomb", rank: 1, img: "/items/consumables/firebomb.png" },
    { name: "Flash Bomb", rank: 50, img: "/items/consumables/flash.png" },
    { name: "Frag Bomb", rank: 41, img: "/items/consumables/frag.png" },
    { name: "Hellfire Bomb", rank: 43, img: "/items/consumables/hellfire.png" },
    { name: "Hive Bomb", rank: 48, img: "/items/consumables/hivebomb.png" },
    { name: "Liquid Fire Bomb", rank: 43, img: "/items/consumables/liquidfirebomb.png" },
    //TODO: { name: "Medical Pack", rank: 1, img: "/items/consumables/.png" },
    { name: "Poison Bomb", rank: 39, img: "/items/consumables/poisonbomb.png" },
    //TODO: { name: "Regeneration Shot", rank: 13, img: "/items/consumables/.png" },
    { name: "Stamina Shot", rank: 10, img: "/items/consumables/stamina.png" },
    { name: "Sticky Bomb", rank: 1, img: "/items/consumables/sticky.png" },
    //TODO: { name: "Tool Box", rank: 55, img: "/items/consumables/.png" },
    { name: "Vitality Shot", rank: 7, img: "/items/consumables/vitality.png" },
    { name: "Waxed Dynamite Stick", rank: 19, img: "/items/consumables/waxeddynamite.png" },
    { name: "Weak Antidote Shot", rank: 1, img: "/items/consumables/antidote_weak.png" },
    { name: "Weak Stamina Shot", rank: 1, img: "/items/consumables/stamina_weak.png" },
    { name: "Weak Vitality Shot", rank: 1, img: "/items/consumables/vitality_weak.png" },
    //TODO: { name: "Weak Regeneration Shot", rank: 1, img: "/items/consumables/.png" },
    { name: "Stalker Beetle", rank: 27, img: "/items/consumables/beetle.png" },
];

const traits_list = [
    {name: "Adrenaline",    cost: 1, rank: 1,   img: "/items/traits/adrenaline.png"},
    {name: "Ambidextrous",  cost: 3, rank: 33,  img: "/items/traits/ambidextrous.png"},
    {name: "Assailant",     cost: 1, rank: 18,  img: "/items/traits/assailant.png"},
    {name: "Beastface",     cost: 4, rank: 42,  img: "/items/traits/beastface.png"},
    {name: "Blade Seer",    cost: 1, rank: 54,  img: "/items/traits/bladeseer.png", items: [
        "Crossbow", "Hand Crossbow", "Hunting Bow"
    ]},
    {name: "Bloodless",     cost: 3, rank: 1,   img: "/items/traits/bloodless.png"},
    {name: "Bolt Thrower",  cost: 1, rank: 1,   img: "/items/traits/boltthrower.png", items: [
        "Crossbow", "Hand Crossbow"
    ]},
    {name: "Bulletgrubber", cost: 4, rank: 26,  img: "/items/traits/bulletgrubber.png", items: [
        "Bornheim No. 3", "Bornheim No. 3 Silencer", "Bornheim No. 3 Extended", "Bornheim No. 3 Match",
        "Dolch 96", "Dolch 96 Precision",
        "Lebel 1886", "Lebel 1886 Marksman", "Lebel 1886 Talon",
        "Mosin-Nagant M1891", "Mosin-Nagant M1891 Avtomat", "Mosin-Nagant M1891 Bayonet", "Mosin-Nagant M1891 Sniper",
        "Mosin-Nagant M1891 Obrez", "Mosin-Nagant M1891 Obrez Drum", "Mosin-Nagant M1891 Obrez Mace",
        "Berthier Mle 1892", "Berthier Mle 1892 Riposte", "Berthier Mle 1892 Deadeye", "Berthier Mle 1892 Marksman",
        "Specter 1882", "Specter 1882 Bayonet", "Specter 1882 Compact",
        "Winfield 1887 Terminus", "Winfield 1887 Terminus Handcannon"
    ]},
    {name: "Bulwark",       cost: 2, rank: 1,   img: "/items/traits/bulwark.png"},
    {name: "Conduit",       cost: 5, rank: 38,  img: "/items/traits/conduit.png"},
    {name: "Dauntless",     cost: 1, rank: 64,  img: "/items/traits/dauntless.png"},
    //TODO: {name: "Decoy Supply",  cost: 1, rank: 53,  img: "/items/traits/decoysupply.png"},
    {name: "Determination", cost: 4, rank: 1,   img: "/items/traits/determination.png"},
    {name: "Doctor",        cost: 9, rank: 1,   img: "/items/traits/doctor.png"},
    {name: "Fanning",       cost: 8, rank: 12,  img: "/items/traits/fanning.png", items: [
        "Caldwell Conversion Pistol", "Caldwell Conversion Chain Pistol", "Caldwell Conversion Uppercut",
        "Caldwell Pax", "Caldwell Pax Claw", "LeMat Mark II",
        "Nagant M1895", "Nagant M1895 Silencer",
        "Scottfield Model 3", "Scottfield Model 3 Brawler", "Scottfield Model 3 Spitfire", "Scottfield Model 3 Swift"
    ]},
    {name: "Frontiersman",  cost: 7, rank: 57,  img: "/items/traits/frontiersman.png"},
    {name: "Gator Legs",    cost: 3, rank: 56,  img: "/items/traits/gatorlegs.png"},
    {name: "Ghoul",         cost: 3, rank: 20,  img: "/items/traits/ghoul.png"},
    {name: "Greyhound",     cost: 5, rank: 3,   img: "/items/traits/greyhound.png"},
    {name: "Hornskin",      cost: 3, rank: 1,   img: "/items/traits/hornskin.png"},
    {name: "Hundred Hands", cost: 2, rank: 16,  img: "/items/traits/hundredhands.png", items: ["Hunting Bow"]},
    {name: "Iron Eye",      cost: 3, rank: 9,   img: "/items/traits/ironeye.png", items: [
        // TODO - 2 slot weapons
        "Berthier Mle 1892", "Berthier Mle 1892 Riposte", "Caldwell Rival 78", "Crown & King Auto-5",
        "Drilling", "Lebel 1886", "Lebel 1886 Talon", "LeMat Mark II Carbine", "Mosin-Nagant M1891",
        "Mosin-Nagant M1891 Avtomat", "Mosin-Nagant M1891 Bayonet", "Specter 1882", "Specter 1882 Bayonet",
        "Springfield 1866 Krag", "Springfield 1866 Krag Bayonet", "Vetterli 71 Karabiner", 
        "Vetterli 71 Karabiner Bayonet", "Vetterli 71 Karabiner Silencer", "Vetterli 71 Karabiner Cyclone",
        "Winfield 1887 Terminus", "Winfield 1893 Slate", "Winfield 1893 Slate Riposte", "Winfield M1873",
        "Winfield M1873 Aperture", "Winfield M1873 Musket Bayonet", "Winfield M1873 Swift", "Winfield M1873 Talon",
        "Winfield M1873C", "Winfield M1873C Silencer", "Winfield M1879 Centennial", "Winfield M1879 Centennial Trauma"
    ]},
    {name: "Kiteskin",      cost: 1, rank: 51,  img: "/items/traits/kiteskin.png"},
    {name: "Levering",      cost: 4, rank: 6,   img: "/items/traits/levering.png", items: [
        //TODO - double check
        "Winfield 1887 Terminus", "Winfield M1873", "Winfield M1873 Aperture", "Winfield M1873 Musket Bayonet",
        "Winfield M1873 Swift", "Winfield M1873 Talon", "Winfield M1873C", "Winfield M1873C Marksman",
        "Winfield M1873C Silencer", "Winfield M1879 Centennial", "Winfield M1879 Centennial Sniper",
        "Winfield M1879 Centennial Trauma", "Winfield 1887 Terminus Handcannon", "Winfield M1873C Vandal",
        "Winfield M1873C Vandal Striker", "Winfield M1873C Vandal Deadeye", "Winfield M1876 Centennial Shorty",
        "Winfield M1876 Centennial Shorty Silencer"
    ]},
    {name: "Lightfoot",     cost: 5, rank: 47,  img: "/items/traits/lightfoot.png"},
    {name: "Magpie",        cost: 1, rank: 1,   img: "/items/traits/magpie.png"},
    {name: "Martialist",    cost: 2, rank: 28,  img: "/items/traits/martialist.png", items: ["Katana"]},
    {name: "Mithridatist",  cost: 3, rank: 40,  img: "/items/traits/mithridatist.png"},
    {name: "Necromancer",   cost: 4, rank: 1,   img: "/items/traits/necromancer.png"},
    {name: "Packmule",      cost: 4, rank: 1,   img: "/items/traits/packmule.png"},
    {name: "Physician",     cost: 5, rank: 45,  img: "/items/traits/physician.png"},
    {name: "Pitcher",       cost: 6, rank: 61,  img: "/items/traits/pitcher.png"},
    {name: "Poacher",       cost: 1, rank: 36,  img: "/items/traits/poacher.png"},
    {name: "Poison Sense",  cost: 1, rank: 59,  img: "/items/traits/poisonsense.png"},
    //TODO: {name: "Quartermaster", cost: 6, rank: 1,   img: "/items/traits/quartermaster.png"},
    {name: "Resilience",    cost: 3, rank: 4,   img: "/items/traits/resilience.png"},
    {name: "Salveskin",     cost: 3, rank: 1,   img: "/items/traits/salveskin.png"},
    {name: "Scopesmith",    cost: 2, rank: 22,  img: "/items/traits/scopesmith.png", items: [
        //TODO - 2 slot weapons
        "Berthier Mle 1892 Deadeye", "Berthier Mle 1892 Marksman", "Lebel 1886 Marksman",
        "Mosin-Nagant M1891 Sniper", "Springfield 1866 Krag Sniper", "Vetterli 71 Karabiner Deadeye",
        "Vetterli 71 Karabiner Marksman", "Winfield M1873C Marksman", "Winfield M1879 Centennial Sniper"
    ]},
    {name: "Serpent",       cost: 4, rank: 14,  img: "/items/traits/serpent.png"},
    {name: "Silent Killer", cost: 4, rank: 62,  img: "/items/traits/silentkiller.png"},
    {name: "Steady Aim",    cost: 2, rank: 30,  img: "/items/traits/steadyaim.png", items: [
        //TODO - 2 slot weapons, 1 slot weapons?
        "Berthier Mle 1892 Deadeye", "Berthier Mle 1892 Marksman", "Lebel 1886 Marksman",
        "Mosin-Nagant M1891 Sniper", "Springfield 1866 Krag Sniper", "Vetterli 71 Karabiner Deadeye",
        "Vetterli 71 Karabiner Marksman", "Winfield M1873C Marksman", "Winfield M1879 Centennial Sniper"
    ]},
    {name: "Vigilant",      cost: 1, rank: 44,  img: "/items/traits/vigilant.png"},
    {name: "Vigor",         cost: 3, rank: 63,  img: "/items/traits/vigor.png"},
    {name: "Vulture",       cost: 3, rank: 24,  img: "/items/traits/vulture.png"},
    {name: "Whispersmith",  cost: 1, rank: 49,  img: "/items/traits/whispersmith.png"},
];

module.exports = {
    weapons_3slot,
    weapons_2slot,
    weapons_1slot,
    tools_list,
    tools_melee,
    consumables_list,
    traits_list
}