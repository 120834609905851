import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCookies } from "react-cookie";
import { ToggleEditableSection } from '../components/FormElements';
import "../css/account.css";
const api = require('../api')

const Account = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);
    const [searchParams] = useSearchParams();

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/account" } });
    }

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [stream_link, setStreamLink] = useState("");
    const [isLoadingTwitch, setIsLoadingTwitch] = useState(true);
    const [isTwitchConnected, setIsTwitchConnected] = useState(false);
    const [hasTwitchExtension, setHasTwitchExtension] = useState(false);
    const [twitchUsername, setTwitchUsername] = useState("");
    const [twitchProfileImg, setTwitchProfileImg] = useState("");
    const [isTwitchOnly, setIsTwitchOnly] = useState(true);

    const refreshUserInfo = useCallback(() => {
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                setUsername(response.username);
                setEmail(response.email);
                setStreamLink(response.stream_link);
                setIsTwitchOnly(response.twitch_only);
            }
        });
        api.getAuth('/twitch/getCurrentStatus', cookies.authToken, {}, (response) => {
            if (response) {
                setIsTwitchConnected(response.connected);
                setHasTwitchExtension(response.has_extension);
                setTwitchUsername(response.user_data?.display_name);
                setTwitchProfileImg(response.user_data?.profile_image_url);
                setIsLoadingTwitch(false);
            }
        });
    }, [cookies.authToken])

    useEffect(() => {
        if (searchParams.get("code")) {
            api.postAuth('/twitch/setTokens', cookies.authToken, {
                "code": searchParams.get("code")
            }, () => {
                refreshUserInfo();
            })
        } else {
            refreshUserInfo();
        }
    }, [searchParams, cookies.authToken, refreshUserInfo])

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/users/updateProfile', cookies.authToken, {
            "email": elements.email.value,
            "stream_link": elements.stream_link.value,
            "password": elements.password.value
        }, (response) => {
            refreshUserInfo();
        })
    }

    const handleUpdatePassword = (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        const new_password = elements.new_password.value
        const confirm_new_password = elements.confirm_new_password.value
        if (new_password === confirm_new_password) {
            api.postAuth('/users/updatePassword', cookies.authToken, {
                "old_password": elements.old_password.value,
                "new_password": new_password
            }, (response) => {
                alert("Password changed successfully")
            })
        } else {
            alert("New password fields must match")
        }
    }

    return (
        cookies.authToken ? (
            <div className="container-fluid d-flex flex-column align-items-center content-box">
                <div className="container m-3">
                    <h1>Account</h1>
                    <Link to={"/profile/" + username}>
                        <b>View Profile</b>
                    </Link>
                    <ToggleEditableSection
                        title={"Profile"}
                        btn_text={"Update"}
                        handleSubmit={handleUpdateSubmit}
                        content_static={(
                            <div>
                                <b>Email:</b> {email}<br />
                                <b>Stream Link:</b> {stream_link}
                            </div>
                        )}
                        content_editing={(
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="mt-1 form-control form-control-lg" name="email" defaultValue={email} />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="stream_link">Stream Link</label>
                                    <input type="text" className="mt-1 form-control form-control-lg" name="stream_link" defaultValue={stream_link} />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="passwordInput">Password</label>
                                    <input required type="password" className="mt-1 form-control form-control-lg" name="password" />
                                </div>
                            </div>
                        )}
                    />
                    <div className="p-3">
                        <h3>Twitch Connection</h3>
                        {isLoadingTwitch ? (
                            "Loading..."
                        ) : isTwitchConnected ? (
                            <>
                                <div className='d-flex flex-row align-items-center my-3'>
                                    <img className='rounded-circle' style={{ width: "50px" }} src={twitchProfileImg} alt='twitch-profile-img' />
                                    <h4 className='mx-3'>{twitchUsername}</h4>
                                </div>
                                {hasTwitchExtension ? (
                                    "Twitch extension configured"
                                ) : (
                                    "Twitch extension not configured"
                                )}
                                <div>
                                    Having connection issues? &nbsp;
                                    <a href={
                                        "https://id.twitch.tv/oauth2/authorize?response_type=code"
                                        + "&client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID
                                        + "&redirect_uri=" + process.env.REACT_APP_BASE_URL + "/account"
                                        + "&scope=" + process.env.REACT_APP_TWITCH_PERMISSIONS}>
                                        Reconnect with Twitch
                                    </a>
                                </div>
                            </>
                        ) : (
                            <a href={
                                "https://id.twitch.tv/oauth2/authorize?response_type=code"
                                + "&client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID
                                + "&redirect_uri=" + process.env.REACT_APP_BASE_URL + "/account"
                                + "&scope=" + process.env.REACT_APP_TWITCH_PERMISSIONS}>
                                Connect with Twitch
                            </a>
                        )}
                    </div>
                    {!isTwitchOnly && (
                        <div className="p-3">
                            <h3>Password</h3>
                            <form onSubmit={handleUpdatePassword}>
                                <div className="form-group mt-1">
                                    <label htmlFor="old_password">Current Password</label>
                                    <input required type="password" className="mt-1 form-control form-control-lg" name="old_password" />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="new_password">New Password</label>
                                    <input required type="password" className="mt-1 form-control form-control-lg" name="new_password" />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="confirm_new_password">Confirm New Password</label>
                                    <input required type="password" className="mt-1 form-control form-control-lg" name="confirm_new_password" />
                                </div>
                                <div className="form-group mt-3">
                                    <button type="submit" className="mt-1 btn btn-accent1">Submit</button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        ) : null
    );

};


export default Account;
