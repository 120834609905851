import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { formatDate } from "../../functions/DateFunctions";
const api = require('../../api');

const Users = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);

    const [users, setUsers] = useState([]);

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/" } });
    }

    const updateCreatePerm = (user_id, set_to) => {
        api.postAuth('/users/updatePerms/createGame', cookies.authToken, { "user_id": user_id, "set_to": set_to }, (response) => {
            if (response && response === "Updated successfully") {
                const newUsers = users.map((user) => {
                    if (user.id === user_id) {
                        return {
                            ...user,
                            can_create_games: set_to,
                        };
                    } else {
                        return user;
                    }
                });
                setUsers(newUsers);
            }
        });
    }

    useEffect(() => {
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                if (response.username !== "xxyrana" && response.username !== "baconbeagle") {
                    navigate('/');
                } else {
                    api.getAuth('/users/getAll', cookies.authToken, {}, (response) => {
                        setUsers(response);
                    });
                }
            } else {
                navigate('/');
            }
        });
    }, [cookies.authToken, navigate]);


    return (
        cookies.authToken ? (
            <div className="d-flex flex-column align-items-center">
                <div className="container m-3 bg-light rounded p-4">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Username</th>
                                <th>Created</th>
                                <th>Last Login</th>
                                <th>Can Create Games</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map(user => {
                                return (
                                    <tr>
                                        <td>{user.id}</td>
                                        <td>{user.username}</td>
                                        <td>{formatDate(new Date(user.created_on))}</td>
                                        <td>{user.last_login && formatDate(new Date(user.last_login))}</td>
                                        <td>{user.can_create_games ? (
                                            <Button className="btn btn-sm btn-success" onClick={() => updateCreatePerm(user.id, false)}>Yes</Button>
                                        ) : (
                                            <Button className="btn btn-sm btn-danger" onClick={() => updateCreatePerm(user.id, true)}>No</Button>
                                        )}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : null
    );
};

export default Users;