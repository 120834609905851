import React from "react";
import { Link, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export const Footer = () => {
  const currentPage = useLocation().pathname;
  return (
    <div className="text-light p-1 text-center d-flex flex-row justify-content-center" style={{fontSize: ".7em"}}>
      <Link className={currentPage.includes("terms") ? "nav-link active" : "nav-link "} to="/terms">Terms & Conditions</Link>
      <Link className={currentPage.includes("privacy") ? "nav-link active" : "nav-link "} to="/privacy">Privacy Policy</Link>
    </div>
  );
};

export default Footer;