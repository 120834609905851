import React, { useState } from "react";
import { useCookies } from "react-cookie";
import '../css/geoguesser.css';
import GeoguesserMain from "../components/geoguesser/GeoguesserMain";
import ModeSelect from "../components/geoguesser/ModeSelect";
import GeoguesserJoin from "../components/geoguesser/GeoguesserJoin";
import GeoguesserStream from "../components/geoguesser/GeoguesserStream";
const api = require('../api');


const Geoguesser = () => {
    const [cookies] = useCookies(["authToken"]);

    const [isLoading, setIsLoading] = useState(cookies.authToken !== undefined);
    const [mode, setMode] = useState("");
    const [twitchId, setTwitchId] = useState();
    
    if(cookies.authToken){
        api.getAuth('/twitch/getCurrentStatus', cookies.authToken, {}, (response) => {
            if (response?.connected && response?.user_data?.id){
                setTwitchId(response.user_data.id);
            }
            setIsLoading(false);
        })
    }

    const handleModeSelect = (mode) => {
        setMode(mode);
    }

    return (
        mode.length > 0 ? 
            mode === "stream" ? 
                <GeoguesserStream/>
            : mode === "join" ?
                <GeoguesserJoin/>
            : <GeoguesserMain/>
         : 
            <ModeSelect
                isLoading={isLoading}
                twitchId={twitchId}
                setMode={handleModeSelect}
            />
        
    );
};
  
export default Geoguesser;