import React from 'react';
import { Button } from 'react-bootstrap';

const Donate = () => {
    return (
        <div className="container d-flex flex-column align-items-center content-box">
            <div className="container m-3 p-5">
                <h1>Donate</h1>
                <p className="mt-4">We accept donations to help cover the cost of hosting. This is not intended for profit.</p>
                <p>
                    If you would like to donate, please click the button below. If you would like to be credited on the website,
                    you can optionally enter a name / username and/or a note when donating.
                    To give an idea of cost, hosting has been about $20 per month.
                </p>
                <p>Thank you for your support!</p>
                {/*<form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="hosted_button_id" value="HKGQL9TX8CZ7G" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>*/}

                <div className="d-flex flex-column align-items-center">
                    <a href="https://www.paypal.com/donate/?hosted_button_id=HKGQL9TX8CZ7G" target="_blank" rel="noreferrer">
                        <Button className="btn btn-lg btn-warning py-3 px-4">Donate with PayPal</Button>
                    </a>
                    (Opens new window)
                </div>
            </div>
        </div>
    );
};
  
export default Donate;
  