import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

export const ModCallsList = ({gameID, allPromptsList, calls, handleCall}) => {
    let settings;
    const storedSettings = localStorage.getItem("settings_" + gameID);
    if (storedSettings) {
        settings = JSON.parse(storedSettings);
    } 

    const [showCalled, setShowCalled] = useState(settings ? settings.showCalled : true);
    const [showUncalled, setShowUncalled] = useState(settings ? settings.showUncalled : true);
    const [sort, setSort] = useState(settings ? settings.sort : "modified"); // modified / created / count
    const [search, setSearch] = useState("");

    useEffect(() => {
        localStorage.setItem("settings_" + gameID, JSON.stringify({
                showCalled,
                showUncalled,
                sort
        }));
    }, [showCalled, showUncalled, sort, gameID])

    const getSortedPrompts = () => {
        let sortedPromptsList = structuredClone(allPromptsList);
        if(!showCalled){
            sortedPromptsList = sortedPromptsList?.filter(x => !calls.includes(x.id));
        }
        if(!showUncalled){
            sortedPromptsList = sortedPromptsList?.filter(x => calls.includes(x.id));
        }
        if(search && search.length > 0){
            sortedPromptsList = sortedPromptsList?.filter(x => x.text.toLowerCase().includes(search.toLowerCase()));
        }

        if(sort === "modified"){
            sortedPromptsList?.sort((a, b) => {return a.modified_at < b.modified_at ? 1 : -1});
        } else if (sort === "created"){
            sortedPromptsList?.sort((a, b) => {return a.created_at < b.created_at ? 1 : -1});
        } else if (sort === "count"){
            sortedPromptsList?.sort((a, b) => {return b.ct - a.ct });
        }
        return sortedPromptsList?.map(prompt => {
            const called = calls.includes(prompt.id);
            return (
                <li key={prompt.id} className={"d-flex justify-content-between gap-2 list-group-item" + (called ? " list-group-item-success" : "")}>
                    <div>
                        <input className="m-2" type="checkbox" checked={called} onChange={() => handleCall(prompt.id, !called)}/>
                        {prompt.text}
                        {prompt.description ?
                            <ul><li key={prompt.id}><i>{prompt.description}</i></li></ul>
                        : null}
                    </div>
                    <div>
                        {prompt.ct > 0 && prompt.ct}
                    </div>
                </li>
            );
        });
    }

    
    return (
        <div className="container p-3">
            <h2>Calls</h2>
            <div className="d-flex flex-wrap gap-2 align-items-center mb-2">
                <input className="py-1 px-2" type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                <button className="btn btn-sm btn-accent1 btn-shrink" onClick={() => setSearch("")}>Clear</button>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
                <div className="d-flex flex-wrap">
                    <div>
                        <input className="form-check-input" id="showCalled" type="checkbox" checked={showCalled}  onChange={() => setShowCalled(!showCalled)}/>
                        <label className="form-check-label mx-2" htmlFor="showCalled">Show Checked</label>
                    </div>
                    <div>
                        <input className="form-check-input" id="showUncalled" type="checkbox" checked={showUncalled}  onChange={() => setShowUncalled(!showUncalled)}/>
                        <label className="form-check-label mx-2" htmlFor="showUncalled">Show Unchecked</label>
                    </div>
                </div>
                <div>
                    <span>Sort by:</span>
                    <select
                        onChange={e => setSort(e.target.value)}
                        className="mx-2" >
                        <option value={"modified"} selected={sort === "modified"}>Last modified</option>
                        <option value={"created"} selected={sort === "created"}>Created</option>
                        <option value={"count"} selected={sort === "count"}>Count</option>
                    </select >
                </div>
            </div>
            <ul className="list-group">
                { getSortedPrompts(allPromptsList) }
            </ul>
        </div>
    )
}