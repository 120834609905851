import React from 'react';

const Privacy = () => {


    return (
        <div className="container d-flex flex-column align-items-center content-box">
            <div className="container m-3">
                <h1>Privacy Policy</h1>
                <p className="mt-3"><i>Last updated January 6, 2024.</i></p>

                <h2><u>Key Points</u></h2>
                <ul>
                    <li>These key points highlight important information but do not substitute the policy below.</li>
                    <li>We do not use trackers or collect data about you other than what you directly provide, such as email.</li>
                    <li>We do not sell your data.</li>
                    <li>Your data is stored securely, with passwords being stored in a secure, non-human-readable format.</li>
                </ul>
                
                <h2><u>Full Privacy Policy</u></h2>
                <p><i>Referenced from <a href="https://gdpr.eu/privacy-notice/">https://gdpr.eu/privacy-notice/</a></i></p>
                <p>
                    This website was made by volunteer individuals, not a company.
                    This privacy policy will explain how we use the personal data we collect from you when you use our website.
                </p>

                <h3>What data do we collect?</h3>
                <p>
                    We collect the following personal data directly from users:
                    <ul>
                        <li>Username</li>
                        <li>Email (optional) - Used to reset your password</li>
                        <li>Stream link (optional) - Displayed on your profile and bingo games</li>
                    </ul>
                    We also store the following:
                    <ul>
                        <li>Account creation date</li>
                        <li>Date last logged in</li>
                        <li>Any information you enter as part of your hosted bingo games</li>
                        <li>Statistics on your geoguesser attempts (such as distance from answer)</li>
                        <li>Deleted items (bingo games, prompts) in case recovery is needed</li>
                    </ul>
                </p>

                <h3>How will we use your data?</h3>
                <p>
                    We collect the above data so that you can create and manage an account on this website and use 
                    our services in a convenient manner. 
                    We do not sell or analyze your data in any way.
                </p>

                <h3>How do we store your data?</h3>
                <p>
                    Your data is stored securely by a third-party website, Heroku. 
                    Passwords are encrypted and stored securely in a non-human-readable format.
                </p>

                <h3>What are your data protection rights?</h3>
                <p>
                    We would like to make sure you are fully aware of all of your data protection rights. 
                    Every user is entitled to the following:
                    <ul className="m-3">
                        <li>
                            <b>The right to access</b> - 
                            You have the right to request copies of your personal data from us.
                        </li>
                        <li>
                            <b>The right to rectification</b> - 
                            You have the right to request that we correct any information you believe is inaccurate. 
                            You also have the right to request us to complete information you believe is incomplete.
                        </li>
                        <li>
                            <b>The right to erasure</b> - 
                            You have the right to request that we erase your personal data, under certain conditions.
                        </li>
                        <li>
                            <b>The right to restrict processing</b> - 
                            You have the right to request that we restrict the processing of your personal data, 
                            under certain conditions.
                        </li>
                        <li>
                            <b>The right to object to processing</b> - 
                            You have the right to object to our processing of your personal data, under certain conditions.
                        </li>
                        <li>
                            <b>The right to data portability</b> - 
                            You have the right to request that we transfer the data that we have collected 
                            to another organization, or directly to you, under certain conditions.
                        </li>
                    </ul>
                </p>

                <h3>What are cookies?</h3>
                <p>
                    Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. 
                    We do not collect information from you using cookies.
                    For further information, visit <a href="allaboutcookies.org">allaboutcookies.org</a>.
                </p>

                <h3>How do we use cookies? / What types of cookies do we use?</h3>
                <p>
                    Our only use of cookies is to keep you logged in to your account.
                    We do not use tracking / advertising cookies of any kind.
                </p>

                <h3>How to manage your cookies</h3>
                <p>
                    You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. 
                    However, if you block cookies from our website, you will not be able to log into and manage your account.
                </p>

                <h3>Privacy policies of other websites</h3>
                <p>
                    Our website contains links to other websites. Our privacy policy applies only to our website, so if
                    you click on a link to another website, you should read their privacy policy.
                </p>

                <h3>Changes to our privacy policy</h3>
                <p>Any changes to our privacy policy will be updated on this page.</p>

                <h3>How to contact us</h3>
                <p>You can contact the owner of this website at <a href="mailto:bayoubingoweb@gmail.com">bayoubingoweb@gmail.com</a>.</p>

            </div>
        </div>
    );

};
  
export default Privacy;
  