import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
import { ProfileLink } from "../ProfileComponents"
import { ModCallsList } from "./ModCallsList";
import { CheckCardModal } from "./CheckCardModal";
const api = require('../../api')

const PlayerList = ({title, player_list, flaggedPlayers, handleCheckClick, winners, canHideWinners}) => {
    const [search, setSearch] = useState("");
    const [hideWinners, setHideWinners] = useState(false);
    let filteredList = player_list.filter(x => x.username.toLowerCase().includes(search.toLowerCase()));
    if(hideWinners){
        filteredList = filteredList.filter(x => winners.filter(w => w.username === x.username).length === 0)
    }
    return (
        <div className="container p-3">
            <h2>{title} ({player_list.length})</h2>
            <input className="px-2" type="text" size={15} value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)}/>
            {canHideWinners && (
                <div className="mt-2">
                    <input className="form-check-input" id="hideWinners" type="checkbox" checked={hideWinners}  onChange={() => setHideWinners(!hideWinners)}/>
                    <label className="form-check-label mx-2" htmlFor="hideWinners">Hide Winners</label>
                </div>
            )}
            <table className="w-100 mt-1">
                <tbody>
                    { filteredList.length > 0 ? 
                        filteredList.map((player) => {
                            return (
                                <tr key={player.id}>
                                    <td>{flaggedPlayers.some(x => x.username === player.username) ? <i className="fa-solid fa-hand"></i> : null}</td>
                                    <td className="p-1"><ProfileLink username={player.username}/></td>
                                    <td className="py-1 text-end">
                                        <Button className="btn btn-sm btn-accent2 px-2 py-1" 
                                            onClick={(event) => {handleCheckClick(event, player.username, player.user_id ? player.user_id : player.id)}}
                                        >
                                            Check Card
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    : null }
                </tbody>
            </table>
        </div>
    )
}

export const ModDashboard = ({triggerReload, setTriggerReload, gameID, settingsList, allPromptsList, participants, winnerList, flaggedPlayers}) => {
    const [cookies] = useCookies(["authToken"]);

    const playerList = participants ? [...(participants?.players || []), ...(participants?.mods || [])] : [];
    playerList.sort((a, b) => a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1);

    const [isLoadingCheckCard, setIsLoadingCheckCard] = useState(true);
    const [playerCards, setPlayerCards] = useState([]);
    const [showCheckCardModal, setShowCheckCardModal] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [selectedUsername, setSelectedUsername] = useState();
    const [selectedCardIndex, setSelectedCardIndex] = useState(0);

    const calls = allPromptsList?.filter(item => item.called).map(item => item.id);

    const handleCall = (id, called) => {
        api.postAuth('/bingo/prompts/updateCall', cookies.authToken, { "game_id": gameID, "prompt_id": id, "called": called })
    }

    const handleCheckClick = (event, username, player_id) => {
        event.preventDefault();
        setSelectedPlayer(player_id);
        setSelectedUsername(username);
        setIsLoadingCheckCard(true);
        setShowCheckCardModal(true);
        api.getAuth('/bingo/cards/getByParticipantById', cookies.authToken, { "game_id": gameID, "player_id": player_id }, (response) => {
            if (response && response !== "No card found" && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                setPlayerCards(response.sort((a, b) => a.created_at > b.created_at));
                setSelectedCardIndex(response.length - 1);
            } else {
                setPlayerCards([]);
            }
            setIsLoadingCheckCard(false);
        })
    }

    useEffect(() => {
        if(triggerReload){
            setIsLoadingCheckCard(true);
            api.getAuth('/bingo/cards/getByParticipantById', cookies.authToken, { "game_id": gameID, "player_id": selectedPlayer }, (response) => {
                if (response && response !== "No card found" && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                    setPlayerCards(response.sort((a, b) => a.created_at > b.created_at));
                    setSelectedCardIndex(response.length - 1);
                } else {
                    setPlayerCards([]);
                }
                setIsLoadingCheckCard(false);
            })
            setTriggerReload(false);
        }
    }, [triggerReload, cookies.authToken, gameID, selectedPlayer, setTriggerReload])

    const markWinner = (event) => {
        event.preventDefault();
        api.postAuth('/bingo/cards/markWinner', cookies.authToken, {
            "card_id": playerCards[selectedCardIndex].id,
        }, (response) => {
            if(response === "User is at max wins"){
                alert("User is already at max wins")
            } else if(response === "Card has recently been marked"){
                alert("Card has recently been marked")
            } 
            api.getAuth('/bingo/cards/getByParticipantById', cookies.authToken, { "game_id": gameID, "player_id": selectedPlayer }, (response) => {
                if (response && response !== "No card found" && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                    setPlayerCards(response.sort((a, b) => a.created_at > b.created_at));
                    setSelectedCardIndex(response.length - 1);
                } else {
                    setPlayerCards([]);
                }
            })
        })
    }

    const deleteWin = (win_id) => {
        api.postAuth('/bingo/cards/deleteWin', cookies.authToken, {
            "win_id": win_id,
        }, (response) => {
            api.getAuth('/bingo/cards/getByParticipantById', cookies.authToken, { "game_id": gameID, "player_id": selectedPlayer }, (response) => {
                if (response && response !== "No card found" && typeof response !== 'string' && !(response instanceof String) && Array.isArray(response)) {
                    setPlayerCards(response.sort((a, b) => a.created_at > b.created_at));
                    setSelectedCardIndex(response.length - 1);
                } else {
                    setPlayerCards([]);
                }
            })
        })
    }

    const setAllowRerolls = (allow) => {
        api.postAuth('/bingo/games/update', cookies.authToken, { 
            "game_id": gameID,
            "allow_rerolls": allow
        })
    }

    return (
        <>
            <div className="p-3 d-flex flex-wrap flex-row align-items-top modview-container">
                <div className="flex-grow-1 calls-container">
                    {settingsList.allow_rerolls ? (
                        <div className="d-flex gap-2">
                            <button 
                                className="btn btn-accent2 py-0 px-2 d-flex align-items-center justify-content-center"
                                onClick={() => setAllowRerolls(false)}
                            >
                                <i className="fa-solid fa-lock-open"></i>
                            </button> 
                            Rerolls Unlocked
                        </div>
                    ) : (
                        <div className="d-flex gap-2">
                            <button 
                                className="btn btn-accent2 py-0 px-2 d-flex align-items-center justify-content-center"
                                onClick={() => setAllowRerolls(true)}
                            >
                                <i className="fa-solid fa-lock"></i>
                            </button> 
                            Rerolls Locked
                        </div>
                    )}
                    <ModCallsList
                        gameID={gameID}
                        allPromptsList={allPromptsList}
                        calls={calls}
                        handleCall={handleCall}
                    />
                </div>
                <div className="m-2"></div>
                <div>
                    <PlayerList 
                        title={"Winners"} 
                        player_list={winnerList}
                        handleCheckClick={handleCheckClick}
                        winners={[]}
                        flaggedPlayers={flaggedPlayers}
                        canHideWinners={false}
                    />
                    <div className="m-2"></div>
                    <PlayerList 
                        title={"All Players"} 
                        player_list={playerList} 
                        handleCheckClick={handleCheckClick}
                        winners={winnerList}
                        flaggedPlayers={flaggedPlayers}
                        canHideWinners={true}
                    />
                </div>
            </div>
            
            <CheckCardModal
                show={showCheckCardModal}
                onClose={() => setShowCheckCardModal(false)}
                isLoading={isLoadingCheckCard}
                username={selectedUsername}
                flaggedPlayers={flaggedPlayers}
                cards={playerCards}
                selectedCardIndex={selectedCardIndex}
                setSelectedCardIndex={setSelectedCardIndex}
                allPromptsList={allPromptsList}
                calls={calls}
                onMarkWinner={markWinner}
                onDeleteWin={deleteWin}
            />
        </>
    );
};