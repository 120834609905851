import React from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { SelectTZ } from "../FormElements"
import Select from 'react-select'
import { formatDate, formatTime, formatDateForInput, formatTimeForInput, getUTC } from "../../functions/DateFunctions";
import 'bootstrap/dist/css/bootstrap.min.css';
const api = require('../../api');

export const SettingsBlock = ({gameID, refreshUserGames, refreshGameInfo, editable, settingsList}) => {
    const [cookies] = useCookies(["authToken"]);
    const dateTime = new Date(settingsList.scheduled_datetime)
    const date = formatDateForInput(dateTime);
    const time = formatTimeForInput(dateTime);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        const offset = elements.timeZone.value;
        const utcDateTime = getUTC(elements.date.value + " " + elements.time.value, offset);
        api.postAuth('/bingo/games/update', cookies.authToken, { 
            "game_id": gameID,
            "name": elements.name.value,
            "description": elements.description.value,
            "scheduled_datetime": utcDateTime.toUTCString(),
            "player_limit": elements.playerLimit.value,
            "public": elements.public.value,
            "allow_rerolls": elements.allow_rerolls.value,
            "max_rerolls": elements.max_rerolls.value,
            "show_calls": elements.show_calls.value,
            "max_wins": elements.max_wins.value
        }, (response) => {
            refreshUserGames();
            refreshGameInfo();
        })
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-row justify-content-end">
                </div>
                <div className="d-flex flex-column gap-2 px-4">
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Name:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input required type="text" className="w-100 form-control" name="name" maxLength={150} defaultValue={settingsList.name}/>
                            ) : settingsList.name}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Description:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input className="w-100 form-control" type="text" name="description" defaultValue={settingsList.description}/>
                            ) : settingsList.description}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Date:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input className="w-auto form-control" required type="date" name="date" defaultValue={date}/>
                            ) : formatDate(dateTime)}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Time:</div>
                        <div className="settings-content">
                            {editable ? (
                                <div className="d-flex flex-row flex-wrap gap-1 align-items-center">
                                    <input required className="w-auto form-control" type="time" name="time" defaultValue={time}/>
                                    <SelectTZ inputName="timeZone" dateTime={dateTime} />
                                </div>
                            ) : formatTime(dateTime)}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Player Limit:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input required type="number" min="3" max="1000" className="settings-content-small form-control" name="playerLimit" defaultValue={settingsList.player_limit}/>
                            ) : settingsList.player_limit}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Max Wins per Player:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input required type="number" min="1" max="100" className="settings-content-small form-control" name="max_wins" defaultValue={settingsList.max_wins}/>
                            ) : settingsList.max_wins}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Visibility:</div>
                        <div className="settings-content">
                            {editable ? (
                                <Select required className="settings-content-small text-dark" name="public" options={[
                                    { value: "true", label: "Public"},
                                    { value: "false", label: "Private"}
                                ]} defaultValue={settingsList.public ? { value: "true", label: "Public"} : { value: "false", label: "Private"}}/>
                            ) : settingsList.public ? "Public" : "Private"}
                            </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Allow Rerolls:</div>
                        <div className="settings-content">
                            {editable ? (
                                <Select required className="settings-content-small text-dark" name="allow_rerolls" options={[
                                    { value: "true", label: "Yes"},
                                    { value: "false", label: "No"}
                                ]} defaultValue={settingsList.allow_rerolls ? { value: "true", label: "Yes"} : { value: "false", label: "No"}}/>
                            ) : settingsList.allow_rerolls ? "Yes" : "No"}
                            </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Max Rerolls:</div>
                        <div className="settings-content">
                            {editable ? (
                                <input required type="number" min="1" max="100" className="settings-content-small form-control" name="max_rerolls" defaultValue={settingsList.max_rerolls}/>
                            ) : settingsList.max_rerolls}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center form-row">
                        <div className="settings-label">Show Calls:</div>
                        <div className="settings-content">
                            {editable ? (
                                <Select required className="settings-content-small text-dark" name="show_calls" options={[
                                    { value: "true", label: "Yes"},
                                    { value: "false", label: "No"}
                                ]} defaultValue={settingsList.show_calls ? { value: "true", label: "Yes"} : { value: "false", label: "No"}}/>
                            ) : settingsList.show_calls ? "Yes" : "No"}
                            </div>
                    </div>

                    { editable && (
                        (<div className="p-1 w-100 d-flex flex-row align-items-center">
                            <Button className="btn game-detail-button" type="submit">Update</Button>
                        </div>)
                    )}
                </div>
            </form>
        </div>
    )
}