import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { SelectTZ } from "../FormElements"
import Select from 'react-select'
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/bingo.css"
const momenttz = require('moment-timezone');


export const CreateGameModal = ({show, onSubmit, onClose}) => {
    var timeZoneOptions = []
    momenttz.tz.names().forEach((element) =>{
        timeZoneOptions.push({
            value: element,
            label: element
        })
    })
    return (
        <Modal show={show} onHide={onClose} dialogClassName="modal-xl">
            <ModalBody>
                <div className="container-fluid rounded create-game-body">
                    <form onSubmit={onSubmit}>
                        <div>
                            <h2 className="mb-1">General Settings</h2>
                            <div className="form-group m-3">
                                <label htmlFor="name">Game Name</label>
                                <input required type="text" className="mt-1 form-control form-control-lg" name="name" maxLength={150}/>
                            </div>
                            <div className="form-group m-3">
                                <label htmlFor="description">Description</label>
                                <input type="text" className="mt-1 form-control form-control-lg" name="description"/>
                            </div>
                            <div className="d-flex flex-row w-100 form-row">
                                <div className="col-1 flex-grow-1 form-group m-3 form-col">
                                    <label htmlFor="date">Date</label>
                                    <input required type="date" className="mt-1 form-control form-control-lg" name="date" min={moment().format("YYYY-MM-DD")}/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="time">Time</label>
                                    <input required type="time" className="mt-1 form-control form-control-lg" name="time"/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="timeZone">Time Zone</label>
                                    <SelectTZ inputName="timeZone"/>
                                </div>
                            </div>
                            <div className="d-flex flex-row w-100 form-row">
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="player_limit">Player Limit</label>
                                    <input required type="number" min="3" max="1000" className="mt-1 form-control form-control-lg" name="player_limit"/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="public">Visibility</label>
                                    <Select required className="mt-1 text-dark" name="public" options={[
                                        { value: "true", label: "Public"},
                                        { value: "false", label: "Private"}
                                    ]} defaultValue={{ value: "false", label: "Private" }}/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="max_wins">Max Wins per Player</label>
                                    <input required type="number" className="mt-1 form-control form-control-lg" name="max_wins" defaultValue={1}/>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <h2 className="mb-1">Player View Settings</h2>
                            <div className="d-flex flex-row w-100 form-row">
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="allow_rerolls">Allow Card Rerolls</label>
                                    <Select required className="mt-1 text-dark" name="allow_rerolls" options={[
                                        { value: "true", label: "Yes"},
                                        { value: "false", label: "No"}
                                    ]} defaultValue={{ value: "true", label: "Yes" }}/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="max_rerolls">Max Rerolls</label>
                                    <input required type="number" className="mt-1 form-control form-control-lg" name="max_rerolls" defaultValue={10}/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="show_calls">Show List of Calls</label>
                                    <Select required className="mt-1 text-dark" name="show_calls" options={[
                                        { value: "true", label: "Yes"},
                                        { value: "false", label: "No"}
                                    ]} defaultValue={{ value: "true", label: "Yes" }}/>
                                </div>
                                <div className="col-1 flex-grow-1 form-group m-3">
                                </div>
                            </div>
                        </div>
                        {/*<div className="border border-dark rounded p-3">
                            <h2 className="mb-1">Win Settings</h2>
                            <div className="d-flex flex-row w-100">
                                <div className="col-1 flex-grow-1 form-group m-3">
                                    <label htmlFor="reroll_on_win">Players Reroll on Win</label>
                                    <Select required className="mt-1" name="reroll_on_win" options={[
                                        { value: "true", label: "Yes"},
                                        { value: "false", label: "No"}
                                    ]} defaultValue={{ value: "true", label: "Yes" }}/>
                                </div>
                            </div>
                        </div>*/}
                        <div className="form-group mx-3 d-flex flex-row gap-3 justify-content-end">
                            <Button className="btn-accent1" type="submit">Create</Button>
                            <Button className="btn-accent2" onClick={onClose}>Cancel</Button>
                        </div>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
}
