import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Image } from "react-bootstrap";
import "../css/loadout.css"
import { weapons_3slot, weapons_2slot, weapons_1slot, tools_list, tools_melee, consumables_list, traits_list } from "../consts/LoadoutConsts";

const Loadout = () => {

    const [guaranteeMelee, setGuaranteeMelee] = useState(true);
    const [guaranteeMedkit, setGuaranteeMedkit] = useState(true);
    const [allowDuplicateWeapons, setAllowDuplicateWeapons] = useState(false);
    const [allowDuplicateConsumables, setAllowDuplicateConsumables] = useState(true);
    //const [allowUnlocked, setAllowUnlocked] = useState(true);
    const [allowCustomAmmo, setAllowCustomAmmo] = useState(true);
    const [quartermaster, setQuartermaster] = useState(false);
    const bloodlineRank = useRef(100);
    const [includeTraits, setIncludeTraits] = useState(false);
    const traitPoints = useRef(7);

    const [weapon1, setWeapon1] = useState({});
    const [weapon1ammo1, setWeapon1ammo1] = useState("");
    const [weapon1ammo2, setWeapon1ammo2] = useState("");
    const [weapon2, setWeapon2] = useState({});
    const [weapon2ammo1, setWeapon2ammo1] = useState("");
    const [weapon2ammo2, setWeapon2ammo2] = useState("");
    const [tools, setTools] = useState([]);
    const [consumables, setConsumables] = useState([]);
    const [traits, setTraits] = useState([]);

    const getRandomItem = (list) => {
        return list[Math.floor(Math.random()*list.length)]
    }

    const getRandom2SlotCategory = useCallback(() => {
        var weapons_1slot_temp = weapons_1slot;
        var weapons_2slot_temp = weapons_2slot;
        if(Math.floor(Math.random() * 2)){
            return getRandomItem(weapons_2slot_temp);
        } else {
            const singles_list = ["Baseball Bat", "Cavalry Saber", "Hand Crossbow", "Machete"]
            weapons_1slot_temp = weapons_1slot_temp.filter(function(item) { return !singles_list.includes(item.category) })
            var item = getRandomItem(weapons_1slot_temp);
            var response = {
                category: item.category,
                variants: []
            };
            item.variants.forEach(variant => {
                response.variants.push({
                    name: "2x " + variant.name,
                    ammo1: variant.ammo1,
                    ammo2: variant.ammo2,
                    img: variant.img,
                    double: true
                })
            });
            return response;
        }
    }, []);

    const rerollTools = useCallback(() => {
        var list = [];
        var tools_list_temp = tools_list.filter(x => x.rank <= bloodlineRank.current);
        if(guaranteeMelee){
            var selected_melee = getRandomItem(tools_melee);
            list.push(selected_melee);
            tools_list_temp = tools_list_temp.filter(function(tool) { return tool.name !== selected_melee.name; });
        }
        if(guaranteeMedkit){
            list.push({ name: "First Aid Kit", rank: 0, img: "/items/tools/firstaidkit.png" });
            tools_list_temp = tools_list_temp.filter(function(tool) { return tool.name !== "First Aid Kit"; });
        }
        while(list.length < 4){
            var selected_tool = getRandomItem(tools_list_temp);
            list.push(selected_tool);
            // eslint-disable-next-line no-loop-func
            tools_list_temp = tools_list_temp.filter(function(tool) { return tool.name !== selected_tool.name; });
        }
        setTools(list); 
    }, [guaranteeMedkit, guaranteeMelee]);

    const rerollConsumables = useCallback(() => {
        var list = [];
        var consumables_list_temp = consumables_list.filter(x => x.rank <= bloodlineRank.current);
        var selected_consumable;
        if(allowDuplicateConsumables) {
            for(var i=0; i<4; i++){
                selected_consumable = getRandomItem(consumables_list_temp);
                if(selected_consumable){
                    var duplicate = false;
                    for(var j=0; j<list.length; j++){
                        if(list[j]?.consumable?.name === selected_consumable.name){
                            duplicate = true;
                            list[j].count++;
                            break;
                        }
                    }
                    if(!duplicate){
                        list.push({
                            consumable: selected_consumable,
                            count: 1
                        });
                    }
                }
            }
        } else {
            while(list.length < 4){
                selected_consumable = getRandomItem(consumables_list_temp);
                list.push({
                    consumable: selected_consumable,
                    count: 1
                });
                // eslint-disable-next-line no-loop-func
                consumables_list_temp = consumables_list_temp.filter(function(consumable) { return consumable.name !== selected_consumable.name; });
            }
        }
        setConsumables(list);
    }, [allowDuplicateConsumables]);

    const getRandomAmmoType = useCallback((list) => {
        if (list.length > 0){
            var newList = list;
            newList.push("Regular");
            return getRandomItem(newList);
        }
    }, []);

    const rerollAmmo = useCallback((w1, w2) => {
        if(!w1 || !w2) return;
        setWeapon1ammo1(allowCustomAmmo && w1 && w1.ammo1 ? getRandomAmmoType(w1.ammo1) : "")
        setWeapon1ammo2(allowCustomAmmo && w1 && w1.ammo2 ? getRandomAmmoType(w1.ammo2) : "")
        setWeapon2ammo1(allowCustomAmmo && w2 && w2.ammo1 ? getRandomAmmoType(w2.ammo1) : "")
        setWeapon2ammo2(allowCustomAmmo && w2 && w2.ammo2 ? getRandomAmmoType(w2.ammo2) : "")
    }, [allowCustomAmmo, getRandomAmmoType]);

    const rerollTraits = useCallback((w1, w2) => {
        let list = [];
        let points_remaining = traitPoints.current;
        let traits_list_temp = traits_list.filter(x => (x.rank <= bloodlineRank.current && (!x.items || x.items.includes(w1.name) || x.items.includes(w2.name))));
        if(!(w2?.double ?? false)){
            traits_list_temp = traits_list_temp.filter(x => x.name !== "Ambidextrous");
        }
        while(points_remaining > 0 && traits_list_temp.length > 0 && list.length < 15){
            // eslint-disable-next-line no-loop-func
            let selected_trait = getRandomItem(traits_list_temp.filter(x => x.cost <= points_remaining));
            list.push(selected_trait);
            points_remaining -= selected_trait.cost;
            // eslint-disable-next-line no-loop-func
            traits_list_temp = traits_list_temp.filter(function(trait) { return trait.name !== selected_trait.name; });
        }
        setTraits(list); 
    }, []);

    const rerollWeapons = useCallback(() => {
        var weapons_1slot_temp = weapons_1slot;
        var weapons_3slot_temp = weapons_3slot;
        var c1, c2, w1, w2;
        if(quartermaster){
            // 3 + 2
            c1 = getRandomItem(weapons_3slot_temp);
            c2 = getRandom2SlotCategory();
            if(!allowDuplicateWeapons){
                while(c1.category === c2.category) {
                    c2 = getRandom2SlotCategory();
                }
            }
        } else if(Math.floor(Math.random() * 2)){
            // 3 + 1
            c1 = getRandomItem(weapons_3slot_temp);
            c2 = getRandomItem(weapons_1slot_temp);
            if(!allowDuplicateWeapons){
                while(c1.category === c2.category) {
                    c2 = getRandomItem(weapons_1slot_temp);
                }
            }
        } else {
            // 2 + 2
            c1 = getRandom2SlotCategory();
            c2 = getRandom2SlotCategory();
            if(!allowDuplicateWeapons){
                while(c1.category === c2.category) {
                    c2 = getRandom2SlotCategory();
                }
            }
        }
        w1 = getRandomItem(c1.variants);
        w2 = getRandomItem(c2.variants);
        setWeapon1(w1);
        setWeapon2(w2);
        rerollAmmo(w1, w2);
        if(includeTraits){
            rerollTraits(w1, w2);
        } else {
            setTraits([]);
        }
    }, [allowDuplicateWeapons, getRandom2SlotCategory, quartermaster, rerollAmmo, includeTraits, rerollTraits]);

    const reroll = useCallback(() => {
        rerollWeapons();
        rerollTools();
        rerollConsumables();
        rerollAmmo();
    }, [rerollAmmo, rerollConsumables, rerollTools, rerollWeapons]);

    useEffect(() => {reroll()}, [])

    return (
        <div className="container-fluid d-flex flex-column align-items-center content-box">
            <div className="container m-3 p-4">
                <div className="d-flex flex-row flex-wrap gap-3 mt-4">
                    <form className="px-3" onSubmit={(e) => { e.preventDefault(); reroll()}}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="checkMelee" type="checkbox" checked={guaranteeMelee}  onChange={() => setGuaranteeMelee(!guaranteeMelee)}/>
                            <label className="form-check-label" htmlFor="checkMelee">Guarantee Melee Tool</label>
                        </div> 
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="checkMedkit" type="checkbox" checked={guaranteeMedkit}  onChange={() => setGuaranteeMedkit(!guaranteeMedkit)}/>
                            <label className="form-check-label" htmlFor="checkMedkit">Guarantee Medkit</label>
                        </div>
                        {<div className="form-check form-switch">
                            <input className="form-check-input" id="checkDuplicateW" type="checkbox" checked={allowDuplicateWeapons}  onChange={() => setAllowDuplicateWeapons(!allowDuplicateWeapons)}/>
                            <label className="form-check-label" htmlFor="checkDuplicateW">Allow Duplicate Weapons</label>
                        </div>}
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="checkDuplicateC" type="checkbox" checked={allowDuplicateConsumables}  onChange={() => setAllowDuplicateConsumables(!allowDuplicateConsumables)}/>
                            <label className="form-check-label" htmlFor="checkDuplicateC">Allow Duplicate Consumables</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="checkCustom" type="checkbox" checked={allowCustomAmmo}  onChange={() => setAllowCustomAmmo(!allowCustomAmmo)}/>
                            <label className="form-check-label" htmlFor="checkCustom">Allow Custom Ammo Types</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="checkQuartermaster" type="checkbox" checked={quartermaster}  onChange={() => setQuartermaster(!quartermaster)}/>
                            <label className="form-check-label" htmlFor="checkQuartermaster">Quartermaster</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="includeTraits" type="checkbox" checked={includeTraits}  onChange={() => setIncludeTraits(!includeTraits)}/>
                            <label className="form-check-label" htmlFor="includeTraits">Include Traits</label>
                        </div>
                        {includeTraits && (
                            <div className="mt-2" style={{marginLeft: "1em"}}>
                                <input className="" style={{paddingLeft: "0.5em"}} id="traitPoints" type="number" min={1} size={1} defaultValue={traitPoints.current} onChange={(e) => traitPoints.current = e.target.value}/>
                                <label className="mx-2" htmlFor="traitPoints">Trait Points</label>
                            </div>
                        )}
                        <div className="mt-2">
                            <input className="" style={{paddingLeft: "0.5em"}} id="bloodlineRank" type="number" min={1} size={1} defaultValue={bloodlineRank.current} onChange={(e) => bloodlineRank.current = e.target.value}/>
                            <label className="mx-2" htmlFor="bloodlineRank">Bloodline Rank</label>
                        </div>
                        <Button type="submit" className="mt-3 btn-accent1">Reroll</Button>
                    </form>
                    <div className="d-flex flex-column gap-3 flex-grow-1" style={{width: "600px"}}>
                        <div className="loadout-row pt-0">
                            <div>
                                <Image src={ weapon1 && weapon1.img }/>
                            </div>
                            <div className="weapon-label">
                                { weapon1 && weapon1.name }
                                { weapon1ammo1 && (
                                    weapon1ammo2 ? (
                                        <div>
                                            Ammo Type 1: {weapon1ammo1}
                                            <br/>
                                            Ammo Type 2: {weapon1ammo2}
                                        </div>
                                    ) : (
                                        <div>
                                            Ammo Type: {weapon1ammo1}
                                        </div>
                                    )
                                ) }
                            </div>
                        </div>
                        <div className="loadout-row">
                            <div>
                                <Image src={ weapon2 && weapon2.img }/>
                                { weapon2 && weapon2.double && (<Image src={ weapon2 && weapon2.img }/>)}
                            </div>
                            <div className="weapon-label">
                                { weapon2 && weapon2.name }
                                { weapon2ammo1 && (
                                    weapon2ammo2 ? (
                                        <div>
                                            Ammo Type 1: {weapon2ammo1}
                                            <br/>
                                            Ammo Type 2: {weapon2ammo2}
                                        </div>
                                    ) : (
                                        <div>
                                            Ammo Type: {weapon2ammo1}
                                        </div>
                                    )
                                ) }
                            </div>
                        </div>
                        <div className="loadout-row">
                            <div className="d-flex flex-row gap-3 flex-wrap">
                                { tools.map((tool, i) => {
                                    return tool ? (
                                        <div key={"tool" + i} className="d-flex flex-column align-items-center gap-2">
                                            <Image src={ tool.img }/>
                                            <div className="item-label">
                                                { tool.name }
                                            </div>
                                        </div>
                                    ) : null
                                }) }
                            </div>
                        </div>
                        <div className="loadout-row">
                            <div className="d-flex flex-row gap-3 flex-wrap">
                                { consumables.map((consumable, i) => {
                                    return (
                                        <div key={"consumable" + i} className="d-flex flex-column align-items-center gap-2">
                                            <div>
                                                {Array.apply(null, { length: consumable.count }).map((e, i) => 
                                                    <Image key={"img" + i} src={ consumable.consumable.img }/>
                                                )}
                                            </div>
                                            <div className={consumable.count === 1 ? "item-label" : ""}>
                                                { consumable.count > 1 && consumable.count + "x " }{ consumable.consumable.name }
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                        {includeTraits && (
                            <div className="loadout-row">
                                <div className="d-flex flex-row gap-3 flex-wrap">
                                    { traits.map((trait, i) => {
                                        return (
                                            <div key={"trait" + i} className="d-flex flex-column align-items-center gap-2">
                                                <Image src={ trait.img }/>
                                                <div>
                                                    { trait.name } ({trait.cost})
                                                </div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                        )}
                    </div>         
                </div>
            </div>
        </div>
    )
};

export default Loadout;