import React, { useEffect, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'
import { Image, Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
const api = require('../../api');

const GeoguesserDashboard = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);

    const [locationNumbers, setLocationNumbers] = useState([]);
    const [starNumbers, setStarNumbers] = useState([]);
    const [guessNumbers, setGuessNumbers] = useState([]);
    const [votes, setVotes] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [X, setX] = React.useState("");
    const [Y, setY] = React.useState("");
    const [guessDist, setGuessDist] = React.useState(0);
    const [item, setItem] = React.useState();

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/account" } });
    }

    const showImage = (item) => {
        setGuessDist(0);
        setX(item.x_coord);
        setY(item.y_coord);
        setItem(item);
        api.getAuth('/geosetup/getAvgGuessDistance', cookies.authToken, { location_id: item.id }, (response) => {
            if (response && response.avg !== null) {
                setGuessDist(response.avg);
            }
            setShowModal(true);
        });
    }

    const addCanvasDot = useCallback(() => {
        var canv = document.getElementById('mapCanvas');
        var context = canv.getContext('2d');
        context.beginPath();
        context.arc(X * 0.45, Y * 0.45, 4, 0, 2 * Math.PI, false);
        context.fillStyle = '#008800';
        context.fill();
        context.lineWidth = 2;
        context.strokeStyle = '#44FF44';
        context.stroke();
    }, [X, Y]);


    useEffect(() => {
        if (showModal) {
            addCanvasDot();
        }
    }, [addCanvasDot, showModal])

    useEffect(() => {
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                if (response.username !== "xxyrana" && response.username !== "baconbeagle") {
                    navigate('/');
                } else {
                    api.getAuth('/geosetup/getLocationNumbers', cookies.authToken, {}, (response) => {
                        if (response) {
                            setLocationNumbers(response);
                        }
                    });
                    api.getAuth('/geosetup/getStarNumbers', cookies.authToken, {}, (response) => {
                        if (response) {
                            setStarNumbers(response);
                        }
                    });
                    api.getAuth('/geosetup/getVotes', cookies.authToken, {}, (response) => {
                        if (response) {
                            setVotes(response);
                        }
                    });
                    api.getAuth('/geosetup/getGuessNumbers', cookies.authToken, {}, (response) => {
                        if (response) {
                            setGuessNumbers(response);
                        }
                    });
                }
            } else {
                navigate('/');
            }
        });
    }, [cookies.authToken, navigate])


    return (
        cookies.authToken ? (
            <div className="d-flex flex-column align-items-center">
                <div className="container m-3 bg-light rounded p-4">
                    <div className="d-flex flex-row flex-wrap gap-3 align-items-top">
                        <table className="table table-striped table-bordered w-auto h-100">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {locationNumbers && locationNumbers.map((item, i) => {
                                    return (<tr key={'locationNumbers_' + i}>
                                        <td>{item.map_filename.replace("map_", "").replace(".png", "")}</td>
                                        <td>{item.count}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <table className="table table-striped table-bordered w-auto h-100">
                            <thead>
                                <tr>
                                    <th>Stars</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {starNumbers && starNumbers.map((item, i) => {
                                    return (<tr key={'starNumbers_' + i}>
                                        <td>{item.stars}</td>
                                        <td>{item.count}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <table className="table table-striped table-bordered table-hover w-auto h-100">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Image</th>
                                    <th>Good</th>
                                    <th>Easier than Rated</th>
                                    <th>Harder than Rated</th>
                                    <th>Wrong</th>
                                </tr>
                            </thead>
                            <tbody>
                                {votes && votes.map((item, i) => {
                                    return (<tr key={'votes_' + i} onClick={() => showImage(item)}>
                                        <td>{item.id}</td>
                                        <td>{item.img_filename}</td>
                                        <td className={item.vote_good > 0 ? "bg-warning" : ""}>{item.vote_good}</td>
                                        <td className={item.vote_too_easy > 0 ? "bg-warning" : ""}>{item.vote_too_easy}</td>
                                        <td className={item.vote_too_hard > 0 ? "bg-warning" : ""}>{item.vote_too_hard}</td>
                                        <td className={item.vote_wrong > 0 ? "bg-warning" : ""}>{item.vote_wrong}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <table className="table table-striped table-bordered table-hover w-auto h-100">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Guesses</th>
                                </tr>
                            </thead>
                            <tbody>
                                {guessNumbers && guessNumbers.map((item, i) => {
                                    return (<tr key={'guesses_' + i}>
                                        <td>{item.username}</td>
                                        <td>{item.ct}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal show={showModal} fullscreen={true}>
                    <ModalBody>
                        <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                            <Image src={item ? "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + item.img_filename : ""} className="w-75" />
                            <div className="d-flex flex-column gap-2 align-items-center">
                                <div style={{ width: "450px", height: "450px" }}>
                                    <div className="w-100 h-100 position-relative">
                                        <Image src={item ? "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + item.map_filename : ""} className="w-100 h-100 position-absolute" />
                                        <canvas id="mapCanvas" width={450} height={450} className="w-100 h-100 position-absolute"></canvas>
                                    </div>
                                </div>
                                {item && (
                                    <>
                                        <h3>Current Star Rating: {item.stars}</h3>
                                        <table className="table w-auto">
                                            <tbody>
                                                <tr>
                                                    <td>Good: </td>
                                                    <td>{item.vote_good}</td>
                                                </tr>
                                                <tr>
                                                    <td>Easier than {item.stars} stars: </td>
                                                    <td>{item.vote_too_easy}</td>
                                                </tr>
                                                <tr>
                                                    <td>Harder than {item.stars} stars: </td>
                                                    <td>{item.vote_too_hard}</td>
                                                </tr>
                                                <tr>
                                                    <td>Wrong: </td>
                                                    <td>{item.vote_wrong}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {guessDist && guessDist > 0 ? (<h3>Average Guess Distance: {guessDist}m</h3>) : null}
                                    </>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setShowModal(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        ) : null
    );
};

export default GeoguesserDashboard;