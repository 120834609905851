import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { LoadingSpinner } from "../LoadingSpinner";
const api = require('../../api')

export const GameWinModal = ({ show, onClose, keysList }) => {
    const [cookies] = useCookies(["authToken"]);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedKeyId, setSelectedKeyId] = useState(0);
    const [message, setMessage] = useState("");

    const claimKey = () => {
        setMessage("");
        if (selectedKeyId > 0) {
            api.postAuth('/bingo/keys/claim', cookies.authToken, {
                key_id: selectedKeyId
            }, (response) => {
                if (response && response === "No valid wins found") {
                    setMessage("You are not eligible to claim a key");
                } else if (response && response !== "Invalid parameters" && response !== "Error") {
                    onClose();
                } else {
                    setMessage("Could not claim key - please try again")
                }
            });
        } else {
            setMessage("Please select a key to claim")
        }
    }

    return (
        <Modal show={show} dialogClassName="modal-xl">
            <ModalBody>
                <div className="container-fluid d-flex flex-column align-items-center p-3 gap-3">
                    <h3>You win!</h3>
                    {isLoading ? <LoadingSpinner /> : (
                        keysList && keysList.filter(x => !x.claimed).length > 0 ? (
                            <>
                                <div>Select a key below:</div>
                                <div className="list-group overflow-y-scroll">
                                    {keysList && keysList.map((key) => {
                                        return (
                                            <button
                                                key={key.id}
                                                className={
                                                    "list-group-item list-group-item-action"
                                                    + (key.claimed ? " disabled claimed bg-secondary" : "")
                                                    + (key.id === selectedKeyId ? " list-group-item-success" : "")
                                                }
                                                onClick={() => {
                                                    setSelectedKeyId((key.id === selectedKeyId) ? 0 : key.id)
                                                }}
                                            >
                                                <span className={key.claimed && "claimed"}>{key.name}</span>
                                            </button>
                                        )
                                    })}
                                </div>
                                <div className="d-flex gap-2">
                                    <button
                                        className="btn btn-primary btn-shrink"
                                        onClick={claimKey}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        className="btn btn-primary btn-shrink"
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                            <div>
                                There are no keys available to claim. <i className="fa-solid fa-face-frown"></i>
                            </div>
                            <button
                                className="btn btn-primary btn-shrink"
                                onClick={onClose}
                            >
                                Close
                            </button>
                            </>
                        )
                    )}
                    {message.length > 0 && message}
                </div>
            </ModalBody>
        </Modal>
    );
};