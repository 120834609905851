import React, { useCallback, useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import '../../css/geoguesser.css';
import GeoguesserMain from "./GeoguesserMain";
const api = require('../../api');

const GeoguesserStream = () => {
    const [cookies] = useCookies(["authToken"]);
    const [channelID, setChannelID] = useState("");
    const [name, setName] = useState();
    const [userGuesses, setUserGuesses] = useState({});

    // Set up web socket
    const { sendJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            if(cookies.authToken){
                api.getAuth('/twitch/getCurrentStatus', cookies.authToken, {}, (response) => {
                    if (response?.connected && response?.user_data?.id){
                        setChannelID(response.user_data.id);
                        setName(response.user_data.display_name);
                    }
                });
            }
        },
        onClose: () => {
            sendJsonMessage({
                action: "clear_streamer",
                streamer: channelID
            });
        },
        onMessage: (message) => {
            if(message.data){
                const data = JSON.parse(message.data);
                setUserGuesses(data);
            }
        },
        share: true,
        filter: () => false,
        retryOnError: true,
        shouldReconnect: () => true,
    });

    // Subscribe to updates through websocket
    useEffect(() => {
        if(readyState === ReadyState.OPEN && channelID.length > 0) {
            sendJsonMessage({
                action: "set_streamer",
                streamer: channelID
            });
        }
        return () => {
            if(readyState === ReadyState.OPEN && channelID.length > 0) {
                sendJsonMessage({
                    action: "clear_streamer",
                    streamer: channelID
                });
            }
        }
    }, [sendJsonMessage, readyState, channelID]);

    return (
        <div>
            <div className="">
                <h3 className="container px-3 mb-0 mt-1 text-center emphasis-box">Join stream: {name}</h3>
            </div>
            <GeoguesserMain userGuesses={userGuesses} setUserGuesses={setUserGuesses}/>
        </div>
    );
}

export default GeoguesserStream;