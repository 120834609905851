import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container'; 
import Nav from 'react-bootstrap/Nav'; 
import Navbar from 'react-bootstrap/Navbar'; 
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLocation } from 'react-router-dom';
const api = require('../api');

export const NavbarComponent = () => {
    const currentPage = useLocation().pathname;
    const [cookies, setCookie] = useCookies(["authToken"]);
    const [isMod, setIsMod] = useState(false);

    const logout = () => {
        setCookie("authToken", null, { path: "/" });
    }

    useEffect(() => {
        if(cookies.authToken) {
            api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
                if(response){
                    setIsMod(response.username === "xxyrana" || response.username === "baconbeagle");
                }
            });
        }
    }, [cookies.authToken])

    return ( 
        <Navbar collapseOnSelect expand="lg"> 
            <Container> 
                <Navbar.Brand href="/" className={currentPage === "/" ? "active" : ""}> 
                    Home
                </Navbar.Brand> 
                <Navbar.Toggle aria-controls="responsive-navbar-nav" /> 
                <Navbar.Collapse id="responsive-navbar-nav"> 
                    <Nav className="me-auto">
                        {isMod ? (
                            <NavDropdown title="Geoguesser" className={currentPage.includes("geoguesser") ? "active" : ""}>
                                <NavDropdown.Item href={"/geoguesser"}>Play</NavDropdown.Item>
                                <NavDropdown.Item href="/geoguesser/plotter">Plotter</NavDropdown.Item>
                                <NavDropdown.Item href="/geoguesser/dashboard">Dashboard</NavDropdown.Item>
                                <NavDropdown.Item href="/geoguesser/setup">Setup</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav.Link href="/geoguesser" className={currentPage.includes("geoguesser") ? "active" : ""}>Geoguesser</Nav.Link>
                        )}
                        <Nav.Link href="/loadout" className={currentPage.includes("loadout") ? "active" : ""}>Loadout Randomizer</Nav.Link>
                        <Nav.Link href="/bingo" className={currentPage.includes("bingo") ? "active" : ""}>Bayou Bingo</Nav.Link>
                    </Nav> 
                    {cookies.authToken && cookies.authToken != null ? (
                        <Nav> 
                            <Nav.Link href="/donate" className={currentPage.includes("donate") ? "active" : ""}>Donate</Nav.Link>
                            {isMod && (
                                <NavDropdown title="Admin">
                                    <NavDropdown.Item href={"/admin/users"}>Users</NavDropdown.Item>
                                </NavDropdown>
                            )}
                            <NavDropdown title="Account">
                                <NavDropdown.Item href={"/profile"}>Profile</NavDropdown.Item>
                                <NavDropdown.Item href="/account">Account Settings</NavDropdown.Item>
                                <NavDropdown.Item onClick={logout} href="/">Log out</NavDropdown.Item> 
                            </NavDropdown>
                        </Nav> 
                    ) : (
                        <Nav> 
                            <Nav.Link href="/donate" className={currentPage.includes("donate") ? "active" : ""}>Donate</Nav.Link>
                            <Nav.Link href="/login" className={currentPage.includes("login") ? "active" : ""}>Login</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse> 
            </Container> 
        </Navbar> 
    ); 
};

export default NavbarComponent;