import React, { useCallback, useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { Image, Button, Modal, ModalHeader, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner"
import '../../css/geoguesser.css';
import { GeoguesserMap } from "./GeoguesserMap";
const api = require('../../api');

const normalizeMeters = (num, max) => {
    return (num / max) * 1000;
}

const GeoguesserMain = ({userGuesses, setUserGuesses}) => {
    const [cookies] = useCookies(["authToken"]);

    const [isLoading, setIsLoading] = useState(true);

    const [showModal, setShowModal] = useState("none"); // none, wrongMap, result, zoom
    
    const locationId = useRef("");
    const actualMapUrl = useRef("");
    const [imageUrl, setImageUrl] = useState("");
    const [stars, setStars] = useState(0);
    
    const [guessCoords, setGuessCoords] = useState({x: 0, y: 0});
    const [actualCoords, setActualCoords] = useState({x: 0, y: 0, direction: 0});
    const dist = Math.round(Math.sqrt(
        Math.pow((guessCoords.x - actualCoords.x), 2)
        + Math.pow((guessCoords.y - actualCoords.y), 2)
    ));
    var modalTitle;
    if(dist < 5){
        modalTitle = "Wow, nice hacks - enjoy the report";
    } else if(dist < 100){
        modalTitle = "Nice Job!";
    } else if (dist < 550) {
        modalTitle = "Good Try!";
    } else {
        modalTitle = "It must be a Hunt Taketh kind of day...";
    }

    const [selectedMap, setSelectedMap] = useState("S");
    
    const [noMore, setNoMore] = useState(false);
    const [noMoreByStars, setNoMoreByStars] = useState(true);

    const [hideBanner, setHideBanner] = useState(false);

    const addCanvasCone = useCallback((id, x, y, direction) => {
        var canv = document.getElementById(id);
        var context = canv.getContext('2d');
        context.beginPath();
        context.arc(x * 0.45, y * 0.45, 30, (direction - 90 - 40) * Math.PI / 180, (direction - 90 + 40) * Math.PI / 180, false);
        context.lineTo(x * 0.45, y * 0.45);
        context.fillStyle = "rgba(255, 255, 255, 0.5)";
        context.fill();
        context.closePath();
    }, []);

    const addCanvasDot = (id, x, y, fill, stroke) => {
        var canv = document.getElementById(id);
        var context = canv.getContext('2d');
        context.beginPath();
        context.arc(x * 0.45, y * 0.45, 4, 0, 2 * Math.PI, false);
        context.fillStyle = fill;
        context.fill();
        context.lineWidth = 2;
        context.strokeStyle = stroke;
        context.stroke();
        context.closePath();
    }

    const checkClick = (e) => {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        var normGuessX = normalizeMeters(x, rect.width);
        var normGuessY = normalizeMeters(y, rect.height);
        setGuessCoords({x: normGuessX, y: normGuessY});

        if(cookies.authToken){
            api.postAuth('/geo/guessForUser', cookies.authToken, {
                "location_id": locationId.current,
                "x": normGuessX,
                "y": normGuessY
            }, (response) => {
                if(response && response !== "Invalid token"){
                    setActualCoords({x: response.realX, y: response.realY, direction: response.direction});
                    setShowModal("result");
                }
            })
        } else {
            api.post('/geo/guess', {
                "location_id": locationId.current,
                "x": normGuessX,
                "y": normGuessY
            }, (response) => {
                if(response){
                    setActualCoords({x: response.realX, y: response.realY, direction: response.direction});
                    setShowModal("result");
                }
            })
        }

    }

    const maps = {
        "map_bayou.png": "S",
        "map_desalle.png": "D",
        "map_lawson.png": "L"
    };

    const handleNextResponse = (response, isByStars) => {
        if (response && response === "Location not found"){
            if(isByStars){
                setNoMoreByStars(true);
            } else {
                setNoMore(true);
            }
        } else if(response && response !== "Invalid token"){
            locationId.current = response.id;
            setImageUrl("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + response.img_filename);
            actualMapUrl.current = "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + response.map_filename;
            setStars(response.stars);
        }
        setIsLoading(false);

    }

    const next = () => {
        setIsLoading(true);
        setShowModal("none");
        setNoMoreByStars(false);
        (setUserGuesses && setUserGuesses({}));
        const params = (locationId.current !== null && locationId.current !== undefined && locationId.current !== "") ? {location_current: locationId.current} : {};
        if(cookies.authToken){
            api.getAuth('/geo/randomForUser', cookies.authToken, params, (response) => {
                handleNextResponse(response, false);
            })
        } else {
            api.get('/geo/random', params, (response) => {
                handleNextResponse(response, false);
            })
        }
    }

    const nextByStars = (stars) => {
        setIsLoading(true);
        (setUserGuesses && setUserGuesses({}));
        const params = (locationId.current !== null && locationId.current !== undefined && locationId.current !== "") ? {location_current: locationId.current, "stars": stars} : {"stars": stars};
        if(cookies.authToken){
            api.getAuth('/geo/randomForUserByStars', cookies.authToken, params, (response) => {
                handleNextResponse(response, true);
            });
        } else {
            api.get('/geo/randomByStars', params, (response) => {
                handleNextResponse(response, true);
            });
        }
    }

    useEffect(() => {
        next();
    }, [])
      
    useEffect(() => {
        if(showModal === "result"){
            addCanvasDot('resultMapCanvas', guessCoords.x, guessCoords.y, '#FF8800', '#FFFF22');
            if(typeof direction === "number" && actualCoords.direction >= 0){
                addCanvasCone('resultMapCanvas', actualCoords.x, actualCoords.y, actualCoords.direction);
            }
            addCanvasDot('resultMapCanvas', actualCoords.x, actualCoords.y, '#008800', '#44FF44');
        }
    }, [showModal])

    

    return (
        <div className="w-100 d-flex flex-column align-items-center">
            { noMore ? (
                <div className="p-3 d-flex flex-column align-items-center">
                    <h3>You've guessed all map locations already!</h3>
                    <p>We'll add more from time to time, so check back!</p>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center w-100">
                    {!cookies.authToken && !hideBanner && (
                        <div className="announcement d-flex flex-row justify-content-between align-items-center">
                            Log in to save your stats and avoid repeats!
                            <i className="fa-regular fa-circle-xmark cursor-pointer" role="button" onClick={() => setHideBanner(true)}></i>
                        </div>
                    )}
                    <div className={"split-container-wrapper mt-4 d-flex flex-row align-items-top gap-3 w-100"}>

                        { /* Left half */ }
                        <div className={"split-container-inner flex-grow-1 d-flex flex-column align-items-center text-light gap-3"}>
                                { noMoreByStars ? (
                                    <>
                                        <h3>You've guessed all locations with this star rating already!</h3>
                                        <p>Click the button below to get a new location regardless of star rating, 
                                            or try another star rating.</p>
                                        <Button onClick={next} className="mt-3">
                                            Next (any star rating)
                                        </Button>
                                        or choose a difficulty rating:
                                        <div className="mt-2 d-flex flex-row gap-2">
                                            <Button onClick={() => nextByStars(1)} className="btn btn-star">1 <Image src="/star-dark.png"/></Button>
                                            <Button onClick={() => nextByStars(2)} className="btn btn-star">2 <Image src="/star-dark.png"/></Button>
                                            <Button onClick={() => nextByStars(3)} className="btn btn-star">3 <Image src="/star-dark.png"/></Button>
                                            <Button onClick={() => nextByStars(4)} className="btn btn-star">4 <Image src="/star-dark.png"/></Button>
                                            <Button onClick={() => nextByStars(5)} className="btn btn-star">5 <Image src="/star-dark.png"/></Button>
                                            <Button onClick={() => nextByStars(6)} className="btn btn-star">6 <Image src="/star-dark.png"/></Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h3>Where was this taken?</h3>
                                        { isLoading ? <LoadingSpinner /> : (
                                            <div className="geo-img-wrapper" onClick={() => {setShowModal("zoom");}}>
                                                <Image className="img-fluid geo-img-inner w-100 h-100" src={imageUrl}/>
                                                <div className="geo-img-inner w-100 d-flex justify-content-end p-3">
                                                    <div className="zoom-icon">
                                                        <i className="fa-solid fa-magnifying-glass-plus"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="d-flex flex-column align-items-center text-light justify-content-between">
                                            <div className="d-flex flex-row">
                                                Difficulty Rating: 
                                                <div className="m-1"></div>
                                                <div className="d-flex flex-row">
                                                    {Array.apply(null, { length: stars }).map((e, i) => 
                                                        <Image key={"star" + i} src="/star.png" className="m-1 flex-shrink-1"/>
                                                    )}
                                                </div>
                                            </div>
                                            <Button onClick={next} className="my-3 btn-accent1 btn-skip">
                                                Skip
                                            </Button>
                                            or choose a difficulty rating:
                                            <div className="mt-2 d-flex flex-row gap-2">
                                                <Button onClick={() => nextByStars(1)} className="btn btn-star">1 <Image src="/star-dark.png"/></Button>
                                                <Button onClick={() => nextByStars(2)} className="btn btn-star">2 <Image src="/star-dark.png"/></Button>
                                                <Button onClick={() => nextByStars(3)} className="btn btn-star">3 <Image src="/star-dark.png"/></Button>
                                                <Button onClick={() => nextByStars(4)} className="btn btn-star">4 <Image src="/star-dark.png"/></Button>
                                                <Button onClick={() => nextByStars(5)} className="btn btn-star">5 <Image src="/star-dark.png"/></Button>
                                                <Button onClick={() => nextByStars(6)} className="btn btn-star">6 <Image src="/star-dark.png"/></Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>

                        { /* Right half */ }
                        <div className={"split-container-inner d-flex flex-column align-items-center text-light"}>
                            <GeoguesserMap
                                selectedMap={selectedMap}
                                setSelectedMap={setSelectedMap}
                                actualMap={actualMapUrl.current}
                                checkClick={checkClick}
                                isViewer={false}
                                setShowModal={setShowModal}
                                userGuesses={userGuesses}
                            />
                        </div>

                    </div>
                </div>
            )}

            <Modal show={showModal === "zoom"} fullscreen={true}>
                <ModalBody>
                    <Image className="img-fluid" src={imageUrl} />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-modal" onClick={() => setShowModal("none")}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal show={showModal === "result"}>
                <ModalHeader>
                    <ModalTitle>{modalTitle}</ModalTitle>
                    <Button className="btn-modal" onClick={() => next()}>Close</Button>
                </ModalHeader>
                <ModalBody className="py-0">
                    <div className="d-flex flex-column align-items-center">
                        <div style={{width: "450px", height: "450px"}}>
                            <div className="w-100 h-100 position-relative">
                                <Image src={actualMapUrl.current} className="w-100 h-100 position-absolute"/>
                                <canvas id="resultMapCanvas" width={450} height={450} className="w-100 h-100 position-absolute"></canvas>
                            </div>
                        </div>
                        <h3 style={{fontWeight: "300"}} className="mt-3">Distance: {dist}m</h3>
                        <div className="d-flex flex-row mt-2">
                            <div className="mx-1 p-2 d-flex dot-guess"></div>
                            = Your Guess
                        </div>  
                        <div className="d-flex flex-row">
                            <div className="mx-1 p-2 d-flex dot-answer"></div>
                            = Answer
                        </div> 
                    </div>
                </ModalBody>
                <ModalFooter className="pt-0">
                    <Button className="btn-modal" onClick={next}>Try Another</Button>
                </ModalFooter>
            </Modal>
            
            <Modal show={showModal === "wrongMap"} style={{marginTop: "25%"}}>
                <ModalBody>
                    This isn't even on the same map!
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-modal" onClick={() => setShowModal("none")}>Try Again</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
};
  
export default GeoguesserMain;