// Will do more formatting / processing for time zones in the future, but for now...
const momenttz = require('moment-timezone');

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    const arr = time.split(':');
    var hours = arr[0];
    const minutes = arr[1];
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
}

export const formatDate = (dateTime) => {
    return dateTime.toLocaleDateString();
}

export const formatTime = (dateTime) => {
    var tz = momenttz.tz.guess();
    var date = new Date(dateTime)
    var timeStr = date.toLocaleTimeString('en-US', { timeStyle: 'short', hour12: true });
    var timeZone = momenttz.tz(dateTime, tz).format("z");
    return timeStr + " " + timeZone;
}

export const formatTimeNoTz = (dateTime) => {
    var date = new Date(dateTime)
    var timeStr = date.toLocaleTimeString('en-US', { timeStyle: 'short', hour12: true });
    return timeStr;
}

export const formatDateForInput = (dateTime) => {
    try {
        return dateTime.toLocaleDateString('fr-CA').split('T')[0];
    } catch (e) {
        return "";
    }
}

export const formatTimeForInput = (dateTime) => {
    try {
        var date = new Date(dateTime)
        var timeStr = date.toLocaleTimeString('en-US', { hour12: false });
        return timeStr.split(' ')[0]
    } catch (e) {
        return "";
    }
}

export const getTimeZoneOffsetMinutes = (dateTime) => {
    try {
        var tz = momenttz.tz.guess();
        return momenttz.tz(dateTime, tz).utcOffset();
    } catch (e) {
        return "";
    }
}

export const zeroPad = (num) => {
    return String(Math.abs(num)).padStart(2, '0');
}

export const getUTC = (dateStr, offset) => {
    const offsetMinutes = offset * 60;
    const tzStr = `GMT${(offsetMinutes < 0 ? "-" : "+") + zeroPad(Math.floor(offsetMinutes/60))}:${zeroPad(offsetMinutes%60)}`;
    const newDate = new Date(dateStr + " " + tzStr);
    return newDate;
}