import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useCookies } from "react-cookie";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import LoadingSpinner from '../components/LoadingSpinner';

const api = require('../api')

const Login = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["authToken"]);
    const [searchParams] = useSearchParams();

    if(cookies.authToken) {
        navigate('/');
    }

    const {state} = useLocation();
    const [selectedTab, setSelectedTab] = useState("login");
    const [message, setMessage] = useState("");
    const [resetSent, setResetSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTwitch, setIsLoadingTwitch] = useState(false);
    //const [existingUser, setExistingUser] = useState();
    //const [isMe, setIsMe] = useState(false);

    const login = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const params = {
            "username": event.target.elements.username.value,
            "password": event.target.elements.password.value,
        };
        api.post('/users/login', params, (response) => {
            if (response?.authToken){
                setCookie("authToken", response.authToken, { path: "/", maxAge: 86400 });
                var next = '/';
                if(state && state.next)
                    next = state.next;
                navigate(next);
            } else {
                setMessage("Invalid login. If you requested a password reset, you must use your temporary password within 15 minutes.")
            }
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if(searchParams.get("code")){
            setIsLoadingTwitch(true);
            //setIsMe(false);
            api.post('/users/twitchLogin', { 
                "code": searchParams.get("code")
            }, (response) => {
                if(response.authToken){
                    setCookie("authToken", response.authToken, { path: "/", maxAge: 86400 });
                    var next = '/';
                    if(state && state.next)
                        next = state.next;
                    navigate(next);
                /*} else if (response?.username ) {
                    setExistingUser(response.username);
                    setIsLoading(false);*/
                } else {
                    setMessage("Login error")
                    setIsLoadingTwitch(false);
                }
            })
        }
    }, [searchParams, cookies.authToken, navigate, setCookie, state])

    const createAccount = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const params = {
            "username": event.target.elements.username.value,
            "password": event.target.elements.password.value,
            "email": event.target.elements.email.value,
        };
        api.post('/users/create', params, (response) => {
            if(response.authToken){
                setCookie("authToken", response.authToken, { path: "/", maxAge: 86400 });
                var next = '/';
                if(state && state.next)
                    next = state.next;
                navigate(next);
            } else if (response === "Username already exists"){
                setMessage("This username already exists.")
            } else {
                setMessage("Error creating account.")
            }
            setIsLoading(false);
        })
    }

    const resetPassword = (event) => {
        event.preventDefault();
        const params = {
            "username": event.target.elements.username.value,
            "email": event.target.elements.email.value,
        };
        api.post('/users/resetPassword', params, (response) => {
            setResetSent(true);
            setMessage("If the entered information is correct, you will receive an email with a temporary password.");
        })
    }

    const selectTab = (name) => {
        setMessage("");
        setSelectedTab(name);
    }

    return (
        <div className="container-fluid d-flex flex-column align-items-center">
            <div className="p-5">
                <ul className="nav">
                    <li className="nav-item" onClick={() => selectTab("login")}>
                        <div className={"nav-link" + (selectedTab === "login" ? " active-tab" : "")}>Log in</div>
                    </li>
                    <li className="nav-item" onClick={() => selectTab("create")}>
                        <div className={"nav-link" + (selectedTab === "create" ? " active-tab" : "")}>Create Account</div>
                    </li>
                    <li className="nav-item" onClick={() => selectTab("reset")}>
                        <div className={"nav-link" + (selectedTab === "reset" ? " active-tab" : "")}>Reset Password</div>
                    </li>
                </ul>
                {selectedTab === "login" ? (
                    isLoadingTwitch ? (
                        <LoadingSpinner/>
                    ) : (
                        <div className="p-5 d-flex flex-column align-items-center">
                            <a 
                                className="btn btn-twitch" 
                                href={
                                    "https://id.twitch.tv/oauth2/authorize?response_type=code"
                                        + "&client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID
                                        + "&redirect_uri=" + process.env.REACT_APP_BASE_URL + "/login"
                                        + "&scope=" + process.env.REACT_APP_TWITCH_PERMISSIONS
                                }
                            >
                                <i className="fa-brands fa-twitch"></i> Log in with Twitch
                            </a>
                            <div className="mt-3">or</div>
                            <form onSubmit={login} id="form-login">
                                <div className="form-group mt-1">
                                    <label htmlFor="username">Username</label>
                                    <input required type="text" className="mt-1 form-control form-control-lg" name="username"/>
                                </div>
                                <input hidden type="email" className="mt-1 form-control form-control-lg" name="email"/>
                                <div className="form-group mt-3">
                                    <label htmlFor="password">Password</label>
                                    <input required type="password" className="mt-1 form-control form-control-lg" name="password"/>
                                </div>
                                <div className="form-group mt-3">
                                    { isLoading ? (
                                        <LoadingSpinner/>
                                    ) : (
                                        <button type="submit" className="mt-1 btn btn-accent1">Log in</button>
                                    )}
                                </div>
                                <div className="mt-2 text-danger">{message}</div>
                            </form>
                        </div>
                    )
                ) : selectedTab === "create" ? (
                    <div className="p-5">
                        <form onSubmit={createAccount} id="form-create">
                            <div className="form-group mt-1">
                                <label htmlFor="username">Username</label>
                                <input required type="text" className="mt-1 form-control form-control-lg" name="username"/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="email">Email (optional) *</label>
                                <input type="email" className="mt-1 form-control form-control-lg" name="email"/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="password">Password</label>
                                <input required type="password" className="mt-1 form-control form-control-lg" name="password"/>
                            </div>
                            <div className="form-group mt-3 d-flex">
                                <div><input type="checkbox" required/></div>
                                <div className="mx-2">
                                    I have read, understand, and agree to<br/>
                                    the <a href="/terms" target="_blank">Terms & Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                { isLoading ? (
                                        <LoadingSpinner/>
                                ) : (
                                    <button type="submit" className="mt-1 btn btn-accent1">Create Account</button>
                                )}
                            </div>
                            <div className="mt-2 text-danger">{message}</div>
                            <div className="mt-3"><i>* Used for password resets</i></div>
                        </form>
                    </div>
                ) : (
                    <div className="p-5">
                        <form onSubmit={resetPassword} id="form-reset">
                            <div className="form-group mt-1">
                                <label htmlFor="username">Username</label>
                                <input required type="text" className="mt-1 form-control form-control-lg" name="username"/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="email">Email</label>
                                <input required type="email" className="mt-1 form-control form-control-lg" name="email"/>
                            </div>
                            <div className="form-group mt-3">
                                { resetSent ? (
                                    <button className="mt-1 btn btn-accent1" disabled>Submitted!</button>
                                ) : (
                                    <button type="submit" className="mt-1 btn btn-accent1">Request Password Reset</button>
                                )}
                            </div>
                            <div className="mt-2 text-danger">{message}</div>
                        </form>
                    </div>
                )}
                
            </div>
            {/** 
            <Modal show={existingUser && isMe} style={{marginTop: "15%"}}>
                <ModalHeader>
                    <h2>Please log in to link your account.</h2>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input required type="text" className="mt-1 form-control form-control-lg" name="username"/>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="password">Password</label>
                        <input required type="password" className="mt-1 form-control form-control-lg" name="password"/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-modal" onClick={() => setIsMe(true)}>Link Account</Button>
                    <Button className="btn-modal" onClick={() => setIsMe(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
            
            <Modal show={existingUser && !isMe} style={{marginTop: "15%"}}>
                <ModalHeader>
                    <h2>Username already exists</h2>
                </ModalHeader>
                <ModalBody>
                    Do you already have an account with Bayou Bingo with username {existingUser}?
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-modal" onClick={() => setIsMe(true)}>That's me!</Button>
                    <Button className="btn-modal" onClick={() => setIsMe(false)}>Nope!</Button>
                </ModalFooter>
            </Modal>
            */}
        </div>
    );

};
  
export default Login;
  