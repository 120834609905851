import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


export const CallsList = ({allPromptsList, calls}) => {
    const calledPrompts = allPromptsList?.filter(x => calls.includes(x.id)).sort((a, b) => {return a.modified_at < b.modified_at ? 1 : -1});
    const uncalledPrompts = allPromptsList?.filter(x => !calls.includes(x.id)).sort((a, b) => {return a.modified_at < b.modified_at ? 1 : -1});
    return (
        <div className="container">
            <ul className="list-group" style={{maxHeight: "750px", overflowY: "scroll"}}>
                { calledPrompts?.length > 0 ? 
                    calledPrompts.map((prompt) => {
                        return (
                            <li key={prompt.id} className="list-group-item list-group-item-success">
                                {prompt.text}
                                {prompt.description ?
                                    <ul><li key={prompt.id}><i>{prompt.description}</i></li></ul>
                                : null}
                            </li>
                        );
                    })
                : null }
                { uncalledPrompts?.length > 0 ? 
                    uncalledPrompts.map((prompt) => {
                        return (
                            <li key={prompt.id} className="list-group-item">
                                {prompt.text}
                                {prompt.description ?
                                    <ul><li key={prompt.id}><i>{prompt.description}</i></li></ul>
                                : null}
                            </li>
                        );
                    })
                : null }

            </ul>
        </div>
    )
}