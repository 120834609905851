import React from "react";
import Select from 'react-select'
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTimeZoneOffsetMinutes } from "../functions/DateFunctions";

export const ToggleEditableSection = ({title, btn_text, handleSubmit, content_static, content_editing}) => {
    const [editing, setEditing] = React.useState(false);
    return (
        <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
                <h3>{title}</h3>
                {editing ? (
                    <div>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                    </div>
                ) : (
                    <div>
                        <Button onClick={() => setEditing(true)}>Edit</Button>
                    </div>
                )}
            </div>
        {editing ? (
            <form onSubmit={(event) => {handleSubmit(event);setEditing(false);}}>
                {content_editing}
                <div className="form-group mt-3">
                    <button type="submit" className="mt-1 btn btn-primary">{btn_text}</button>
                </div>
            </form>
        ) : (
            content_static
        )}
    </div>

    )
}

export const SelectTZ = (dateTime) => {
        var optionsList = [
            { value: -12, label: "(GMT -12:00) International Date Line, West" },
            { value: -11, label: "(GMT -11:00) Nome" },

            { value: -10, label: "(GMT -10:00) Central Alaska" },
            { value: -10, label: "(GMT -10:00) Alaska/Hawaii Standard" },

            { value: -9, label: "(GMT -09:00) Alaska/Hawaii Daylight" },
            { value: -9, label: "(GMT -09:00) Alaska/Yukon Standard" },

            { value: -8, label: "(GMT -08:00) Alaska/Yukon Daylight" },
            { value: -8, label: "(GMT -08:00) Pacific Standard" },

            { value: -7, label: "(GMT -07:00) Pacific Daylight" },
            { value: -7, label: "(GMT -07:00) Mountain Standard" },

            { value: -6, label: "(GMT -06:00) Mountain Daylight" },
            { value: -6, label: "(GMT -06:00) Central Standard" },

            { value: -5, label: "(GMT -05:00) Central Daylight" },
            { value: -5, label: "(GMT -05:00) Eastern Standard" },

            { value: -4, label: "(GMT -04:00) Eastern Daylight" },
            { value: -4, label: "(GMT -04:00) Atlantic Standard" },

            { value: -3, label: "(GMT -03:00) Atlantic Daylight" },
            { value: -3.5, label: "(GMT -03:30) Newfoundland Standard" },

            { value: -2.5, label: "(GMT -02:30) Newfoundland Daylight" },

            { value: -1, label: "(GMT -01:00) West Africa" },

            { value: 0, label: "(GMT +00:00) GMT / Western Europe" },

            { value: 1, label: "(GMT +01:00) Western Europe Daylight" },
            { value: 1, label: "(GMT +01:00) Central Europe" },
            
            { value: 2, label: "(GMT +02:00) Eastern Europe" },
            { value: 2, label: "(GMT +02:00) Central Europe Daylight" },

            { value: 3, label: "(GMT +03:00) Eastern Europe Daylight" },
            { value: 3.5, label: "(GMT +03:30) Iran" },

            { value: 4, label: "(GMT +04:00) Mauritius / Reunion / Mahe Island" },
            { value: 4.5, label: "(GMT +04:30) Afghanistan" },

            { value: 5, label: "(GMT +05:00) Indian Chagos / Maldives" },

            { value: 6, label: "(GMT +06:00) Almaty / Mawson" },

            { value: 7, label: "(GMT +07:00) Almaty Summer" },
            { value: 7, label: "(GMT +07:00) West Australia Standard" },

            { value: 7.5, label: "(GMT +07:30) Java" },

            { value: 8, label: "(GMT +08:00) West Australia Summer / Standard" },
            { value: 8, label: "(GMT +08:00) China Coastal" },
            
            { value: 8.5, label: "(GMT +08:30) Moluccas" },
            
            { value: 9, label: "(GMT +09:00) West Australia Summer" },
            { value: 9, label: "(GMT +09:00) Japan / Korea / Kwajalein" },

            { value: 9.5, label: "(GMT +09:30) Central / South Australia" },

            { value: 10, label: "(GMT +10:00) East Australia" },

            { value: 10.5, label: "(GMT +10:30) Central / South Australia Summer" },
            
            { value: 11, label: "(GMT +11:00) East Australia Summer" },

            { value: 12, label: "(GMT +12:00) New Zealand" },
            { value: 13, label: "(GMT +13:00) New Zealand Daylight" },
        ];
        
        // TODO: Make this auto selector more robust
        const offset = getTimeZoneOffsetMinutes(dateTime) / 60;
        const index = optionsList.map(e => e.value).indexOf(offset);
        const defaultValue = optionsList[index];

    return (
        <Select required className="mt-1 text-dark select-tz" name="timeZone" options={optionsList} defaultValue={defaultValue}/>)
};