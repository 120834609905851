import React from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Select from 'react-select'
import { parse, RECORD_SEP, UNIT_SEP } from 'papaparse';
import 'bootstrap/dist/css/bootstrap.min.css';
const api = require('../../api');

export const KeysBlock = ({gameID, refreshGameInfo, keysList, editable}) => {
    const [cookies] = useCookies(["authToken"]);
    const [editing, setEditing] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState("edit");

    const handleSubmitNew = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/bingo/keys/create', cookies.authToken, { 
            "game_id": gameID,
            "name": elements.newKeyName.value,
            "code": elements.newKeyCode.value
        }, (response) => {
            setEditing(false);
            refreshGameInfo();
        })
    }
    
    const handleSubmitImport = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        const mode = elements.importMode.value;
        if(mode === "replace"){
            api.postAuth('/bingo/keys/deleteAll', cookies.authToken, { "game_id": gameID });
        }

        parse(elements.keysFile.files[0], {
            delimiter: "",
            newline: "",
            quoteChar: '"',
            escapeChar: '"',
            header: false,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: true,
            skipEmptyLines: true,
            delimitersToGuess: [',', '\t', '|', ';', RECORD_SEP, UNIT_SEP],
            skipFirstNLines: 0,
            step: function(result) {
                if(result?.data?.length >= 2){
                    let name = result.data[0];
                    let code = result.data[1];
                    api.postAuth('/bingo/keys/create', cookies.authToken, { 
                        "game_id": gameID,
                        "name": name,
                        "code": code
                    }, (response) => {
                        refreshGameInfo();
                    })
                }

            },
            complete: function(results, file) {
                refreshGameInfo();
                setEditing(false);
            }
        })
    }

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/bingo/keys/update', cookies.authToken, { 
            "key_id": elements.id.value,
            "name": elements.name.value,
            "code": elements.keyCode.value
        }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }

    const handleSubmitDelete = async (id) => {
        api.postAuth('/bingo/keys/delete', cookies.authToken, { "key_id": id }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }

    return (
        <div className="p-3">
            <div className="d-flex flex-row justify-content-end">
            </div>
            { editing ? (
                <div>
                    <Button onClick={() => setEditing(false)} className="btn m-1">Cancel Editing</Button>
                    { keysList ? 
                        keysList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map((key, index) => {
                            return (
                                <form onSubmit={handleSubmitEdit} className="p-2 border rounded d-flex flex-row flex-wrap flex-grow-1 align-items-center mt-3">
                                    <div className="d-flex flex-row flex-grow-1 gap-2">
                                        <input type="hidden" name="id" value={key.id}/>
                                        <input disabled={key.claimed} required className="flex-grow-1 settings-content-small form-control key-input" type="text" name="name" defaultValue={key.name}/>
                                        <input disabled={key.claimed} required className="flex-grow-1 settings-content-small form-control" type="text" name="keyCode" defaultValue={key.code}/>
                                    </div>
                                    {key.claimed ? (
                                        <div className="mx-2">CLAIMED - Cannot be modified</div>
                                    ) : (
                                        <div>
                                            <Button className="btn-sm m-1" type="submit">Modify</Button>
                                            <Button onClick={() => handleSubmitDelete(key.id)} className="btn btn-sm btn-danger m-1">Delete</Button>
                                        </div>
                                    )}
                                </form>
                            )
                        }) 
                    : null }
                </div>
            ) : (
                <div>
                    {editable ? (
                        <div>
                            <ul className="nav nav-fill mb-4">
                                <li key="mine" className="nav-item" onClick={() => setSelectedTab("edit")}>
                                    <div className={"nav-link" + (selectedTab === "edit" ? " active-tab" : "")}>Edit Keys</div>
                                </li>
                                <li key="mine" className="nav-item" onClick={() => setSelectedTab("import")}>
                                    <div className={"nav-link" + (selectedTab === "import" ? " active-tab" : "")}>Import Keys (CSV only)</div>
                                </li>
                            </ul>
                            {selectedTab === "edit" ? (
                                <>
                                    <h5 className="m-1">Add New Key</h5>
                                    <form className="d-flex flex-wrap flex-row align-items-center mb-3 form-row" onSubmit={handleSubmitNew}>
                                        <input required className="m-1 settings-content-small form-control" type="text" name="newKeyName" placeholder="Name"/>
                                        <input required className="m-1 settings-content-small form-control" type="text" name="newKeyCode" placeholder="Code"/>
                                        <Button className="btn-sm btn-success game-detail-button" type="submit">Add</Button>
                                    </form>
                                    {editable ? (<Button className="game-detail-button" onClick={() => setEditing(true)}>Edit Keys</Button>) : null}
                                    <p className="mt-3">(Codes hidden - to view, go into edit mode)</p>
                                    <ol>
                                        { keysList ? 
                                            keysList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map((key) => {
                                                return (
                                                    <li key={"key_" + key.id}>
                                                        {key.claimed ? "[CLAIMED] " : ""}
                                                        {key.name}
                                                    </li>
                                                )
                                            }) 
                                        : null }
                                    </ol> 
                                </>
                            ) : (
                                <form className="mb-4 p-2 d-flex flex-column gap-2" onSubmit={handleSubmitImport}>
                                    <p>Format: CSV file, no headers, key names in column 1 with codes in column 2.</p>
                                    <div className="d-flex flex-row align-items-center gap-2 form-row">
                                        <label>Import Mode:</label>
                                        <Select required className="flex-grow-1 text-dark game-detail-select" name="importMode" options={[
                                            { value: "add", label: "Add to key list" },
                                            { value: "replace", label: "Replace key list (deletes current keys!)" }
                                        ]} defaultValue={{ value: "add", label: "Add to key list" }}/>
                                    </div>
                                    <input name="keysFile" required type={"file"} accept={".csv"} />
                                    <div>
                                        <Button className="btn btn-success game-detail-button" type="submit">Import</Button>
                                    </div>
                                </form>
                            )}
                        </div>
                    ) : (
                        <ol>
                            { keysList ? 
                                keysList.map((key) => {
                                    return (
                                        <li key={"key_" + key.id}>
                                            {key.name}
                                        </li>
                                    )
                                }) 
                            : null }
                        </ol> 
                    )}
                </div>
            )}
        </div>
    )
}