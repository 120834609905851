import React from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Select from 'react-select'
import { parse, RECORD_SEP, UNIT_SEP } from 'papaparse';
import 'bootstrap/dist/css/bootstrap.min.css';
const api = require('../../api');

export const PromptsBlock = ({gameID, refreshGameInfo, promptsList, editable}) => {
    const [cookies] = useCookies(["authToken"]);
    const [editing, setEditing] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState("edit");

    const handleSubmitNew = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/bingo/prompts/create', cookies.authToken, { 
            "game_id": gameID,
            "text": elements.newPromptText.value,
            "description": elements.newPromptDescription.value
        }, (response) => {
            setEditing(false);
            refreshGameInfo();
        })
    }
    
    const handleSubmitImport = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        const mode = elements.importMode.value;
        if(mode === "replace"){
            api.postAuth('/bingo/prompts/deleteAll', cookies.authToken, { "game_id": gameID });
        }

        parse(elements.promptsFile.files[0], {
            delimiter: "",
            newline: "",
            quoteChar: '"',
            escapeChar: '"',
            header: false,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: true,
            skipEmptyLines: true,
            delimitersToGuess: [',', '\t', '|', ';', RECORD_SEP, UNIT_SEP],
            skipFirstNLines: 0,
            step: function(result) {
                var prompt = result.data[0];
                if(prompt.length > 1){
                    var description = "";
                    if(result.data.length > 1){
                        description = result.data[1];
                    }
                    api.postAuth('/bingo/prompts/create', cookies.authToken, { 
                        "game_id": gameID,
                        "text": prompt,
                        "description": description
                    }, (response) => {
                        refreshGameInfo();
                    })
                }

            },
            complete: function(results, file) {
                refreshGameInfo();
                setEditing(false);
            }
        })
    }

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        const elements = event.target.elements;
        api.postAuth('/bingo/prompts/update', cookies.authToken, { 
            "prompt_id": elements.id.value,
            "text": elements.text.value,
            "description": elements.description.value
        }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }

    const handleSubmitDelete = async (id) => {
        api.postAuth('/bingo/prompts/delete', cookies.authToken, { "prompt_id": id }, (response) => {
            refreshGameInfo();
            setEditing(false);
        })
    }

    return (
        <div className="p-3">
            <div className="d-flex flex-row justify-content-end">
            </div>
            { editing ? (
                <div>
                    <Button onClick={() => setEditing(false)} className="btn m-1">Cancel Editing</Button>
                    { promptsList ? 
                        promptsList.map((prompt, index) => {
                            return (
                                <form onSubmit={handleSubmitEdit} className="p-2 border rounded d-flex flex-row flex-wrap flex-grow-1 align-items-end mt-3">
                                    <div className="d-flex flex-column flex-grow-1 gap-2">
                                        <input type="hidden" name="id" value={prompt.id}/>
                                        <input required className="flex-grow-1 settings-content-small form-control prompt-input" type="text" name="text" defaultValue={prompt.text}/>
                                        <div className="d-flex flex-row gap-2">Description: <input className="flex-grow-1 settings-content-small form-control" type="text" name="description" defaultValue={prompt.description}/></div>
                                    </div>
                                    <div>
                                        <Button className="btn-sm m-1" type="submit">Modify</Button>
                                        <Button onClick={() => handleSubmitDelete(prompt.id)} className="btn btn-sm btn-danger m-1">Delete</Button>
                                    </div>
                                </form>
                            )
                        }) 
                    : null }
                </div>
            ) : (
                <div>
                    {editable ? (
                        <div>
                            <ul className="nav nav-fill mb-4">
                                <li key="mine" className="nav-item" onClick={() => setSelectedTab("edit")}>
                                    <div className={"nav-link" + (selectedTab === "edit" ? " active-tab" : "")}>Edit Prompts</div>
                                </li>
                                <li key="mine" className="nav-item" onClick={() => setSelectedTab("import")}>
                                    <div className={"nav-link" + (selectedTab === "import" ? " active-tab" : "")}>Import Prompts (CSV only)</div>
                                </li>
                            </ul>
                            {selectedTab === "edit" ? (
                                <>
                                    <h5 className="m-1">Add New Prompt</h5>
                                    <form className="d-flex flex-wrap flex-row align-items-center mb-3 form-row" onSubmit={handleSubmitNew}>
                                        <input required className="m-1 settings-content-small form-control" type="text" name="newPromptText" placeholder="Text"/>
                                        <input className="m-1 settings-content-small form-control" type="text" name="newPromptDescription" placeholder="Description"/>
                                        <Button className="btn-sm btn-success game-detail-button" type="submit">Add</Button>
                                    </form>
                                    {editable ? (<Button className="game-detail-button" onClick={() => setEditing(true)}>Edit Prompts</Button>) : null}
                                <ol className="mt-2">
                                        { promptsList ? 
                                            promptsList.map((prompt) => {
                                                return (
                                                    <li key={"prompt_" + prompt.id}>
                                                        {prompt.text}
                                                        {prompt.description ?
                                                            <ul><li key={"prompt_description_" + prompt.id}><i>{prompt.description}</i></li></ul>
                                                        : null}
                                                    </li>
                                                )
                                            }) 
                                        : null }
                                    </ol> 
                                </>
                            ) : (
                                <form className="mb-4 p-2 d-flex flex-column gap-2" onSubmit={handleSubmitImport}>
                                    <p>Format: CSV file, no headers, prompts in column 1 with optional descriptions in column 2.</p>
                                    <div className="d-flex flex-row align-items-center gap-2 form-row">
                                        <label>Import Mode:</label>
                                        <Select required className="flex-grow-1 text-dark game-detail-select" name="importMode" options={[
                                            { value: "add", label: "Add to prompt list" },
                                            { value: "replace", label: "Replace prompt list (deletes current prompts!)" }
                                        ]} defaultValue={{ value: "add", label: "Add to prompt list" }}/>
                                    </div>
                                    <input name="promptsFile" required type={"file"} accept={".csv"} />
                                    <div>
                                        <Button className="btn btn-success game-detail-button" type="submit">Import</Button>
                                    </div>
                                </form>
                            )}
                        </div>
                    ) : (
                        <ol>
                            { promptsList ? 
                                promptsList.map((prompt) => {
                                    return (
                                        <li key={"prompt_" + prompt.id}>
                                            {prompt.text}
                                            {prompt.description ?
                                                <ul><li key={"prompt_description_" + prompt.id}><i>{prompt.description}</i></li></ul>
                                            : null}
                                        </li>
                                    )
                                }) 
                            : null }
                        </ol> 
                    )}
                </div>
            )}
        </div>
    )
}