import React from "react";
import { Image } from "react-bootstrap";

export const ProfileLink = ({username}) => {
    return(
        <a href={"/profile/" + username} className="text-decoration-none" target="_blank" rel="noreferrer">
            <b>{username}</b>
        </a>
    )
}

export const GeoStats = ({stats}) => {
    if(!stats || stats.length === 0){
        return "No stats available."
    }
    const stars = [1, 2, 3, 4, 5, 6]
    return(
        <table>
            <thead>
                <tr>
                    <th className="text-end p-1">Star Rating</th>
                    <th className="text-center p-1">Avg Distance</th>
                </tr>
            </thead>
            <tbody>
                {stars.map((rating) => {
                    var stat = stats.find(item => item.stars === rating);
                    return (
                        <tr>
                            <td><div className="d-flex flex-row justify-content-end p-1">
                                {Array.apply(null, { length: rating }).map((e, i) => 
                                    <Image src="/star.png" className="mx-1 flex-shrink-1"/>
                                )}
                            </div></td>
                            <td className="text-center p-1">{stat ? stat.avg + " m" : "-"}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
