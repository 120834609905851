import React from "react";
import { useCookies } from "react-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { BingoCard } from "./BingoCard";
import { CallsList } from "./CallsList";
import { formatDate, formatTime } from "../../functions/DateFunctions";
const api = require('../../api');

export const CheckCardModal = ({ show, onClose, isLoading, username, flaggedPlayers, cards, selectedCardIndex, setSelectedCardIndex, allPromptsList, calls, onMarkWinner, onDeleteWin }) => {
    const [cookies] = useCookies(["authToken"]);
    const selectedCard = (cards && cards.length > 0) ? cards[selectedCardIndex] : null;

    const lowerHand = () => {
        api.postAuth('/bingo/cards/flag', cookies.authToken, { 
            "card_id": selectedCard.id,
            "flagged": false
        }, null)
    }

    return (
        <Modal show={show} onHide={onClose} dialogClassName="modal-xl">
            <ModalBody>
                {isLoading ? (
                    <div className="container-fluid p-3">
                        <div className="m-3 p-3 d-flex align-items-center">
                            <LoadingSpinner />
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid p-3 check-wrapper">
                        <h2 className="w-100 text-center">{username}</h2>
                        {cards && cards.length > 0 ? (
                            <div>
                                <div className="split-container-wrapper">
                                    <div className="split-container-inner">
                                        {cards.length > 1 && (
                                            <div className="d-flex gap-2 my-2">
                                                Card:
                                                {cards.sort((a, b) => a.created_at > b.created_at).map((card, index) => (
                                                    <button
                                                        key={card.id}
                                                        className={"btn py-0 px-3" + (index === selectedCardIndex ? " btn-accent2" : " btn-accent1")}
                                                        onClick={() => setSelectedCardIndex(index)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                )
                                                )}
                                            </div>
                                        )}
                                        <BingoCard
                                            cardID={selectedCard.id}
                                            prompts={selectedCard.prompts}
                                            autoMark={true}
                                            calls={calls}
                                            markerColor={"#FFE00F"}
                                        />
                                        <div className="d-flex flex-column align-items-center">
                                            {selectedCard?.wins?.length > 0 && (
                                                <table className="table w-auto text-center mt-3 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Win #</th>
                                                            <th>Won At</th>
                                                            <th>Game Key</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedCard.wins.sort((a, b) => a.win_number > b.win_number).map(item => {
                                                            const winDateTime = new Date(item.won_at);
                                                            return (<tr>
                                                                <td>{item.win_number}</td>
                                                                <td>{formatDate(winDateTime) + " " + formatTime(winDateTime)}</td>
                                                                <td>{item.name ? item.name : (
                                                                    <button className="btn btn-accent1 py-0 px-3" onClick={() => onDeleteWin(item.id)}>Delete Win</button>
                                                                )}</td>
                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                            {selectedCardIndex === cards.length - 1 && (
                                                <div className="my-3 d-flex align-items-center justify-content-center gap-2 flex-wrap">
                                                    <Button className="btn-success btn-shrink" onClick={onMarkWinner}>Mark Winner</Button>
                                                    {flaggedPlayers.some(x => x.username === username) ? 
                                                        <Button className="btn-success btn-shrink" onClick={lowerHand}>Lower Hand</Button>
                                                    : 
                                                        null
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="split-container-inner">
                                        <CallsList
                                            allPromptsList={allPromptsList}
                                            calls={calls}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column align-items-center">
                                This player has not rolled a card yet
                            </div>
                        )}
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button className="btn-shrink" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};