import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { formatDate, formatTime, getUTC } from "../functions/DateFunctions";
import { GamesTable, AllGamesTable } from "../components/bingo/GamesTable";
import { GameDetailModal } from "../components/bingo/GameDetailModal";
import { CreateGameModal } from "../components/bingo/CreateGameModal";
import '../css/bingo.css';
const api = require('../api');

const Bingo = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/bingo" } });
    }

    const [canCreateGames, setCanCreateGames] = useState(false);

    const [isLoadingGames, setIsLoadingGames] = useState(true);
    const [isLoadingGameInfo, setIsLoadingGameInfo] = useState(true);

    const [pastGamesList, setPastGamesList] = useState([]);
    const [upcomingGamesList, setUpcomingGamesList] = useState([]);
    const [allGamesList, setAllGamesList] = useState([]);

    const [selectedGameID, setSelectedGameID] = useState();

    const [showModal, setShowModal] = useState("none"); // none, create, detail, delete

    const [showDashButton, setShowDashButton] = useState(false);
    const [detailModalTitle, setDetailModalTitle] = useState("");
    const [gameDetails, setGameDetails] = useState("");
    const [isFull, setIsFull] = useState(false);
    const [viewOnly, setViewOnly] = useState(true);

    const [selectedTab, setSelectedTab] = useState("mine");

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const refreshAllGames = () => {
        setIsLoadingGames(true);
        api.getAuth('/bingo/games/getPublicWithRole', cookies.authToken, {}, (response) => {
            if (response && response.length > 0) {
                setAllGamesList(response.filter(game => new Date(game["scheduled_datetime"]) >= today));
            }
            setIsLoadingGames(false);
        })
    }
    const refreshUserGames = useCallback(() => {
        setIsLoadingGames(true);
        api.getAuth('/bingo/games/getForCurrentUser', cookies.authToken, {}, (response) => {
            if (response && response.length > 0 && response !== "Invalid token") {
                var pastGames = response.filter(game => new Date(game["scheduled_datetime"]) < today);
                var upcomingGames = response.filter(game => new Date(game["scheduled_datetime"]) >= today);
                pastGames = pastGames.sort((a, b) => { return new Date(b.scheduled_datetime) - new Date(a.scheduled_datetime) });
                upcomingGames = upcomingGames.sort((a, b) => { return new Date(a.scheduled_datetime) - new Date(b.scheduled_datetime) });
                setPastGamesList(pastGames);
                setUpcomingGamesList(upcomingGames);
            }
            setIsLoadingGames(false);
        })
    }, [cookies.authToken]);

    const refreshGameInfo = useCallback(() => {
        if (selectedGameID === undefined || selectedGameID === "undefined") return;
        setIsLoadingGameInfo(true);
        setShowModal("detail");
        api.getAuth('/bingo/games/getById', cookies.authToken, { "game_id": selectedGameID }, (response) => {
            if (typeof response !== 'string' && !(response instanceof String)) {
                setGameDetails(response);
                setDetailModalTitle(response.details.name + "  (" + formatDate(new Date(response.details.scheduled_datetime)) + " @ " + formatTime(new Date(response.details.scheduled_datetime)) + ")");
                const playerCount = (response?.participants?.mods?.length || 0) + (response?.participants?.players?.length || 0);
                const playerLimit = (response?.details?.player_limit || 0);
                setIsFull(playerCount >= playerLimit);
            }
            setIsLoadingGameInfo(false);
        })
    }, [cookies.authToken, selectedGameID]);

    useEffect(() => {
        refreshUserGames();
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                setCanCreateGames(response.can_create_games);
            }
        });
    }, [cookies.authToken, refreshUserGames])

    useEffect(() => { refreshGameInfo() }, [selectedGameID, refreshGameInfo])

    const handleCreateGameSubmit = (event) => {
        event.preventDefault();
        var elements = event.target.elements;
        const offset = elements.timeZone.value;
        const utcDateTime = getUTC(elements.date.value + " " + elements.time.value, offset);
        api.postAuth('/bingo/games/create', cookies.authToken, {
            "name": elements.name.value,
            "description": elements.description.value,
            "scheduled_datetime": utcDateTime,
            "player_limit": elements.player_limit.value,
            "public": elements.public.value,
            "allow_rerolls": elements.allow_rerolls.value,
            "max_rerolls": elements.max_rerolls.value,
            "show_calls": elements.show_calls.value,
            "max_wins": elements.max_wins.value,
        }, (response) => {
            refreshUserGames();
            setShowModal("none");
        })
    }

    const handleDeleteGame = (game) => {
        api.postAuth('/bingo/games/delete', cookies.authToken, {
            "game_id": game
        }, (response) => {
            refreshUserGames();
            setShowModal("none");
        })
    }

    const handleGameClick = (game) => {
        if (game.id === selectedGameID) {
            refreshGameInfo();
        } else {
            setIsLoadingGameInfo(true);
            setSelectedGameID(game.id);
        }
    }

    return (
        cookies.authToken ? (
            <div className="d-flex flex-column align-items-center">
                {canCreateGames && (
                    <div className="m-3">
                        <Button onClick={() => setShowModal("create")}>Create New Game</Button>
                    </div>
                )}
                <ul className="nav">
                    <li key="mine" className="nav-item" onClick={() => setSelectedTab("mine")}>
                        <div className={"nav-link" + (selectedTab === "mine" ? " active-tab" : "")}>My Games</div>
                    </li>
                    <li key="all" className="nav-item" onClick={() => { refreshAllGames(); setSelectedTab("all"); }}>
                        <div className={"nav-link" + (selectedTab === "all" ? " active-tab" : "")}>All Upcoming Games</div>
                    </li>
                </ul>
                <div className="container p-3">

                    {selectedTab === "mine" ? (
                        <div className="content-wrapper">
                            <h2>Upcoming</h2>
                            {upcomingGamesList && upcomingGamesList.length > 0 ? (
                                <GamesTable
                                    setViewOnly={setViewOnly}
                                    setShowDashButton={setShowDashButton}
                                    games={upcomingGamesList}
                                    showModal={handleGameClick}
                                    isLoading={isLoadingGames}
                                />
                            ) : (
                                <div className="mb-4">
                                    You are not registered for any upcoming games! Go to the "All Upcoming Games" tab to register.
                                </div>
                            )}
                            {pastGamesList && pastGamesList.length > 0 ? (
                                <>
                                    <h2>Past</h2>
                                    <GamesTable
                                        setViewOnly={setViewOnly}
                                        setShowDashButton={setShowDashButton}
                                        games={pastGamesList}
                                        showModal={handleGameClick}
                                        isLoading={isLoadingGames}
                                    />
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <div className="content-wrapper">
                            <h2>All Upcoming Games</h2>
                            {allGamesList && allGamesList.length > 0 ? (
                                <AllGamesTable
                                    setViewOnly={setViewOnly}
                                    setShowDashButton={setShowDashButton}
                                    games={allGamesList}
                                    showModal={handleGameClick}
                                    isLoading={isLoadingGames}
                                />
                            ) : "No games found"}
                        </div>
                    )}
                </div>

                <CreateGameModal
                    show={showModal === "create"}
                    onSubmit={handleCreateGameSubmit}
                    onClose={() => setShowModal("none")}
                />

                <GameDetailModal
                    gameID={selectedGameID}
                    refreshAllGames={refreshAllGames}
                    refreshUserGames={refreshUserGames}
                    refreshGameInfo={refreshGameInfo}
                    viewOnly={viewOnly}
                    show={showModal === "detail"}
                    showDashButton={showDashButton}
                    title={detailModalTitle}
                    gameDetails={gameDetails}
                    onClose={() => { setShowModal("none"); setGameDetails(""); }}
                    isLoading={isLoadingGameInfo}
                    handleDeleteGame={() => setShowModal("delete")}
                    isFull={isFull}
                    setIsFull={setIsFull}
                />

                <Modal show={showModal === "delete"} style={{ marginTop: "20%" }}>
                    <ModalBody>
                        <h3>Are you sure you want to delete this game?</h3>
                        <div className="text-center">{gameDetails && gameDetails.details.name}</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-modal" onClick={() => handleDeleteGame(selectedGameID)}>Delete</Button>
                        <Button className="btn-modal" onClick={() => setShowModal("detail")}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        ) : null
    );
};

export default Bingo;


