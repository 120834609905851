import { Image } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
const api = require('../../api');

const normalizeMeters = (num, max) => {
    return (num / max) * 1000
}

const GeoguesserSetup = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);
    const [selectedTab, setSelectedTab] = useState("map");

    const [locationID, setLocationID] = useState();
    const [imageFilename, setImageFilename] = useState("");
    const [mapFilename, setMapFilename] = useState("");
    const [answerUrl, setAnswerUrl] = useState("");

    const [left, setLeft] = useState("0px");
    const [top, setTop] = useState("0px");

    const [line1, setLine1] = useState(1100);
    const [line2, setLine2] = useState(27);

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/geoguessersetup" } });
    }

    useEffect(() => {
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                if (response.username !== "xxyrana") {
                    navigate('/');
                }
            } else {
                navigate('/');
            }
        });
    }, [cookies.authToken, navigate])

    useEffect(() => {
        setImageFilename("");
        setLocationID();
        if (selectedTab === "direction") {
            api.getAuth('/geosetup/getLocationNeedingDirection', cookies.authToken, {}, (response) => {
                if (response) {
                    setLocationID(response.id);
                    setImageFilename("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + response.img_filename);
                }
            });
        }
    }, [cookies.authToken, selectedTab])

    const handleMouseMove = (e) => {
        setLeft(`${e.pageX}px`);
        setTop(`${e.pageY}px`);
    }

    const checkClick = (e) => {
        // Note: Coordinates are from top left of image
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        var normX = Math.round(normalizeMeters(x, rect.width));
        var normY = Math.round(normalizeMeters(y, rect.height));

        api.postAuth('/geosetup/setXY', cookies.authToken, {
            "img": imageFilename,
            "map": mapFilename,
            "x": normX,
            "y": normY,
            "stars": imageFilename[1]
        }, (response) => {
            if (response && response.length > 0 && response !== "Invalid token") {
                alert("success");
            } else {
                alert("error");
            }
        })
    }

    const getFile = (event) => {
        const newVal = event.target.value;
        setImageFilename(newVal + ".png");
        setAnswerUrl("/geo_unprocessed/answers/" + newVal + "_answer.png")
        const locAbbr = newVal[0];
        if (locAbbr === "L") {
            setMapFilename("map_lawson.png")
        } else if (locAbbr === "D") {
            setMapFilename("map_desalle.png")
        } else if (locAbbr === "S" || locAbbr === "B") {
            setMapFilename("map_bayou.png")
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        api.postAuth('/geosetup/setDirection', cookies.authToken, {
            "id": locationID,
            "direction": event.target.elements[0].value
        }, (response) => {
            if (response && response.length > 0 && response !== "Invalid token") {
                api.getAuth('/geosetup/getLocationNeedingDirection', cookies.authToken, {}, (response) => {
                    if (response) {
                        setLocationID(response.id);
                        setImageFilename("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/" + response.img_filename);
                    }
                });
            } else {
                alert("error");
            }
        })
    }

    return (
        cookies.authToken ? (
            <div>
                <div className="container-fluid d-flex flex-column align-items-center">
                    <ul className="nav">
                        <li className="nav-item" onClick={() => setSelectedTab("map")}>
                            <div className={"nav-link" + (selectedTab === "map" ? " active-tab" : "")}>Map</div>
                        </li>
                        <li className="nav-item" onClick={() => setSelectedTab("direction")}>
                            <div className={"nav-link" + (selectedTab === "direction" ? " active-tab" : "")}>Direction</div>
                        </li>
                    </ul>
                </div>
                {selectedTab === "map" ? (
                    <div className="d-flex flex-column align-items-center" onMouseMove={handleMouseMove}>
                        <input type="text" onChange={getFile} />
                        <Image className="z-2 img-fluid mx-auto mb-2 fixed-bottom" src={answerUrl} />
                        <Image height={250} className="z-2 fixed-top" src={"/geo_unprocessed/" + imageFilename} />
                        <div className="vt z-3" style={{ pointerEvents: "none", position: "absolute", top: 0, bottom: 0, width: "1px", background: "black", left: left }}></div>
                        <div className="hl z-3" style={{ pointerEvents: "none", position: "absolute", left: 0, right: 0, height: "1px", background: "black", top: top }}></div>
                        <Image className="img w-100" src={"/geoguesser/" + mapFilename} ismap="true" onClick={checkClick} />
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-row gap-3">
                            <input type="number" defaultValue={line1} onChange={(event) => setLine1(event.target.value)} />
                            <input type="number" defaultValue={line2} onChange={(event) => setLine2(event.target.value)} />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input type="number" />
                        </form>
                        <Image className="w-100 mx-auto" src={imageFilename} />
                        {/*<div className="vt z-3" style={{top: 0, bottom: 0, left: line1+"px", pointerEvents: "none", position: "absolute", width: "1px", background: "black"}}></div>
                    <div className="vt z-3" style={{top: 0, bottom: 0, left: (line2 - 2*((line2 - line1)/3))+"px", pointerEvents: "none", position: "absolute", width: "1px", background: "black"}}></div>
                    <div className="vt z-3" style={{top: 0, bottom: 0, left: (line2 - ((line2 - line1)/3))+"px", pointerEvents: "none", position: "absolute", width: "1px", background: "black"}}></div>
                    <div className="vt z-3" style={{top: 0, bottom: 0, left: line2+"px", pointerEvents: "none", position: "absolute", width: "1px", background: "black"}}></div>*/}
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1)) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1) + parseInt(line2)) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1) + line2 * 2) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1) + line2 * 3) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1) + line2 * 4) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                        <div className="vt z-3" style={{ top: 0, bottom: 0, left: (parseInt(line1) + line2 * 5) + "px", pointerEvents: "none", position: "absolute", width: "1px", background: "black" }}></div>
                    </div>
                )}
            </div>
        ) : null
    );
};

export default GeoguesserSetup;