import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/bingogame.css"

export const BingoCard = ({cardID, prompts, autoMark, markerColor, calls, locked = false}) => {
    const [cardPromptsForDisplay, setCardPromptsForDisplay] = useState([]);
    const cardRowMap = (row, x, i) => {
        const num = row*5+i
        var content = x.text;
        if(content === "FREE"){
            content = (<h2>FREE</h2>);
        }
        const marked = cardPromptsForDisplay[num].marked || (autoMark && calls && calls.includes(x.id)) || cardPromptsForDisplay[num].text === "FREE";
        let style = marked ? {
            background: markerColor,
            transitionProperty: "background"
        } : {};
        if(content.length > 60){
            style = {
                fontSize: "0.8rem",
                ...style
            }
        } else if(content.length > 40){
            style = {
                fontSize: "0.9rem",
                ...style
            }
        }
        return (
            <td key={x.id} title={x.description} onClick={() => toggleMark(num)}>
                <div className="wrapper" style={style}>
                    {content}
                </div>
            </td>
        );
    }
    const toggleMark = (num) => {
        if(autoMark || locked) return;
        var nextCardPrompts = cardPromptsForDisplay.map((x, i) => {
            if(i === num){
                return {
                    text: x.text,
                    description: x.description,
                    marked: !x.marked,
                };
            } else {
                return x;
            }
        });
        let expires = new Date();
        expires.setTime(expires.getTime() + 2*60*60*1000);
        localStorage.setItem("cardPrompts_" + cardID, JSON.stringify(nextCardPrompts));
        setCardPromptsForDisplay(nextCardPrompts);
    }

    useEffect(() => {
        let initialPromptsForDisplay;
        const storedPrompts = localStorage.getItem("cardPrompts_" + cardID);
        if (storedPrompts && storedPrompts !== "null") {
            initialPromptsForDisplay = JSON.parse(storedPrompts);
        } else {
            var cardPromptsArr = prompts.map((prompt) => {
                return {
                    "id": prompt.id,
                    "text": prompt.text,
                    "description": prompt.description,
                    "marked": false
                }
            });
            initialPromptsForDisplay = [
                ...cardPromptsArr.slice(0, 12),
                { "id": 0, "text": "FREE", "marked": false },
                ...cardPromptsArr.slice(12),
            ];
            localStorage.setItem("cardPrompts_" + cardID, JSON.stringify(initialPromptsForDisplay));
        }
        setCardPromptsForDisplay(initialPromptsForDisplay);
    }, [prompts])

    return (
        <table className="bingocard">
            <thead>
                <tr>
                    <th scope="col" className="col-1"><div className="wrapper"><h1>B</h1></div></th>
                    <th scope="col" className="col-1"><div className="wrapper"><h1>I</h1></div></th>
                    <th scope="col" className="col-1"><div className="wrapper"><h1>N</h1></div></th>
                    <th scope="col" className="col-1"><div className="wrapper"><h1>G</h1></div></th>
                    <th scope="col" className="col-1"><div className="wrapper"><h1>O</h1></div></th>
                </tr>
            </thead>
            {cardPromptsForDisplay.length > 0 ? (
                <tbody>
                    <tr>
                        {cardPromptsForDisplay.slice(0,5).map((x, i) => cardRowMap(0, x, i))}
                    </tr>
                    <tr>
                        {cardPromptsForDisplay.slice(5,10).map((x, i) => cardRowMap(1, x, i))}
                    </tr>
                    <tr>
                        {cardPromptsForDisplay.slice(10,15).map((x, i) => cardRowMap(2, x, i))}
                    </tr>
                    <tr>
                        {cardPromptsForDisplay.slice(15,20).map((x, i) => cardRowMap(3, x, i))}
                    </tr>
                    <tr>
                        {cardPromptsForDisplay.slice(20,25).map((x, i) => cardRowMap(4, x, i))}
                    </tr>
                </tbody>
            ) : null}
        </table>
    )
}