import React from "react";
import { formatDate, formatTime } from "../../functions/DateFunctions";
import { LoadingSpinner } from "../LoadingSpinner"
import "../../css/bingo.css"

export const GamesTable = ({games, showModal, setViewOnly, setShowDashButton, isLoading}) => {
    return(
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                { isLoading ? (<tr><td colSpan="4"><LoadingSpinner /></td></tr>) : (
                    games && Array.isArray(games) && games.length > 0 ? 
                        games.map((game, i) => {
                            var dateTime = new Date(game.scheduled_datetime);
                            return (
                                <tr key={"game_" + i} onClick={() => {setViewOnly(game.type !== "streamer" && game.type !== "creator"); setShowDashButton(true); showModal(game);}}>
                                    <td>{game.name}</td>
                                    <td>{formatDate(dateTime)}</td>
                                    <td>{formatTime(dateTime)}</td>
                                    <td>{game.type}</td>
                                </tr>
                            )
                        })
                    :
                    null
                )}
                
            </tbody>
        </table>
    )
}

export const AllGamesTable = ({games, showModal, setViewOnly, setShowDashButton, isLoading}) => {
    return(
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                { isLoading ? (<tr><td colSpan="4"><LoadingSpinner /></td></tr>) : (
                    games && Array.isArray(games) && games.length > 0 ? 
                        games.map((game, i) => {
                            var dateTime = new Date(game.scheduled_datetime);
                            return (
                                <tr key={"game_all_" + i} onClick={() => {setViewOnly(game.type !== "streamer" && game.type !== "creator"); setShowDashButton(game.type !== null && game.type.length > 0); showModal(game);}}>
                                    <td>{game.name}</td>
                                    <td>{formatDate(dateTime)}</td>
                                    <td>{formatTime(dateTime)}</td>
                                    <td>{game.type}</td>
                                </tr>
                            )
                        })
                    :
                    null
                )}
                
            </tbody>
        </table>
    )
}