import React, { useCallback, useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import '../../css/geoguesser.css';

const debounce = (func, wait, immediate) => {
    var timeout
    return function () {
        var context = this,
            args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export const GeoguesserMap = ({ selectedMap, setSelectedMap, actualMap, checkClick, isViewer, setShowModal, userGuesses }) => {

    const [mapSize, setMapSize] = useState(500);
    const [dotColor, setDotColor] = React.useState("#00FFFF");

    useEffect(() => {
        // Debounce to avoid the function fire multiple times
        var handleResizeDebounced = debounce(function handleResize() {
            const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (screenWidth < 768) {
                setMapSize(screenWidth / 1.1);
            } else {
                setMapSize(screenWidth / 2.1);
            }
        }, 250)

        window.addEventListener("resize", handleResizeDebounced)
        return () => window.removeEventListener("resize", handleResizeDebounced)
    }, [])


    const mapUrls = {
        S: "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_bayou.png",
        D: "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_desalle.png",
        L: "https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_lawson.png"
    };
    const selectedMapUrl = mapUrls[selectedMap];

    const denomalizeMeters = useCallback((num) => {
        return (num / 1000) * mapSize;
    }, [mapSize])

    const addGuessDot = useCallback((x, y) => {
        var canv = document.getElementById("mapCanvas");
        if (canv) {
            var context = canv.getContext('2d');
            context.beginPath();
            context.arc(x, y, 4, 0, 2 * Math.PI, false);
            context.fillStyle = dotColor;
            context.fill();
            context.lineWidth = 2;
            context.closePath();
        }
    }, [dotColor])

    const onClick = (e) => {
        if (!isViewer && selectedMapUrl !== actualMap) {
            setShowModal("wrongMap");
            return;
        }
        checkClick(e);
    }

    useEffect(() => {
        const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (screenWidth < 768) {
            setMapSize(screenWidth / 1.1);
        } else {
            setMapSize(screenWidth / 2.1);
        }
        var canv = document.getElementById("mapCanvas");
        if (canv) {
            var context = canv.getContext('2d');
            context.clearRect(0, 0, mapSize, mapSize);
        }
        if (userGuesses && userGuesses[selectedMap]?.length > 0) {
            userGuesses[selectedMap].forEach(guess => {
                const x = denomalizeMeters(guess.x);
                const y = denomalizeMeters(guess.y);
                addGuessDot(x, y);
            });
        }
    }, [userGuesses, denomalizeMeters, selectedMap, mapSize, addGuessDot])

    return (
        <>
            <div className="w-100 mb-2 d-flex flex-row align-items-center justify-content-center gap-2">
            <Button
                disabled={selectedMap === "S"}
                onClick={() => setSelectedMap("S")}
                className={"btn btn-map"}>
                Stillwater Bayou{!isViewer && userGuesses && userGuesses["S"]?.length > 0 ? " (" + userGuesses["S"].length + ")" : ""}
            </Button>
            <Button
                disabled={selectedMap === "D"}
                onClick={() => setSelectedMap("D")}
                className="btn btn-map">
                DeSalle{!isViewer && userGuesses && userGuesses["D"]?.length > 0 ? " (" + userGuesses["D"].length + ")" : ""}
            </Button>
            <Button
                disabled={selectedMap === "L"}
                onClick={() => setSelectedMap("L")}
                className="btn btn-map">
                Lawson Delta{!isViewer && userGuesses && userGuesses["L"]?.length > 0 ? " (" + userGuesses["L"].length + ")" : ""}
            </Button>
            </div>
            {selectedMap?.length > 0 && (
                <div style={{ width: mapSize + "px", height: mapSize + "px" }}>
                    <div className="w-100 h-100 position-relative">
                        <Image
                            className="w-100 h-100 position-absolute"
                            src={selectedMapUrl}
                            ismap="true"
                            onClick={(e) => { onClick(e); }}
                        />
                        <canvas id="mapCanvas" width={mapSize} height={mapSize} className="w-100 h-100 position-absolute"
                            onClick={(e) => { onClick(e); }}></canvas>
                    </div>
                </div>
            )}
            <div className="pt-2 d-flex align-items-center">
                Guess dot color: <input className="marker-input" type="color" value={dotColor} onChange={e => setDotColor(e.target.value)} />
            </div>
        </>
    )
}