import React from 'react';
import '../css/home.css';

const Home = () => {

    const HomeButton = ({text, icon, url}) => {
        return (
            <div className="d-flex flex-row align-items-center" style={{width: "28%", aspectRatio: "1/1"}}>
                <a href={url} className="h-100 w-100">
                    <button className="btn-wrapper">
                        <svg className="btn-inner" viewBox="0 0 100 100" >
                            <defs>
                                <clipPath id="insideOnly">
                                <path d={svgBtnShape} />
                                </clipPath>
                            </defs>
                            <path clipPath="url(#insideOnly)" className="btn-path" d={svgBtnShape} />
                        </svg>
                        <div className="btn-inner">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100 p-3">
                                <div className="d-flex flex-column justify-content-center flex-grow-1">
                                    <i className={icon + " btn-icon"}></i>
                                </div>
                                <br/>
                                <p className="flex-shrink btn-text p-2">{text}</p>
                            </div>
                        </div>
                    </button>
                </a>
            </div>
        );
    }

    const NewsItem = ({title, date, content}) => {
        return (
            <div className="news-item w-100">
                <div className="d-flex flex-row justify-content-between align-items-end w-100">
                    <h2>{title}</h2>
                    <p>{date}</p>
                </div>
                <div className="mt-2">
                    {content}
                </div>
            </div>
        );
    }

    const svgBtnShape = " M 0,10 "
            + "A 10,10 0,0,0 10,0 "
            + "L 90,0 "
            + "A 10,10 0,0,0 100,10 "
            + "L 100,90 "
            + "A 10,10 0,0,0 90,100 "
            + "L 10,100 "
            + "A 10,10 0,0,0 0,90 "
            + "L 0,10 "
            + "z";
    return (
        <div className="container-fluid d-flex flex-column align-items-center p-4 content-box">
            <div className="d-flex w-100 justify-content-between">
                <HomeButton
                    text = "Geoguesser"
                    icon = "fa-solid fa-location-dot"
                    url = "/geoguesser"
                />
                <HomeButton
                    text = "Loadout Randomizer"
                    icon = "fa-solid fa-dice"
                    url = "/loadout"
                />
                <HomeButton
                    text = "Bayou Bingo"
                    icon = "fa-solid fa-gamepad"
                    url = "/bingo"
                />
            </div>
            <div className="d-flex flex-column emphasis-box w-100 mt-3 p-4 gap-3 news-container">
                <NewsItem
                    title="Update 2.0"
                    date="8/8/24"
                    content={<>
                        <p>
                            The following updates have been made to Bayou Bingo:
                        </p>
                        <ul>
                            <li>Streamers can now upload keys for players to choose from upon winning.</li>
                            <li>Rerolls can be limited by number. All rerolled / past cards are shown for each player.</li>
                            <li>Mods can see the number of players with a certain prompt on their cards.</li>
                            <li>Marking wins has a small cooldown to avoid multiple mods marking the same win.</li>
                            <li>Players can call a bingo within the site, and mods will see it.</li>
                            <li>Bug fixes, minor QOL updates</li>
                        </ul>
                    </>}
                />
                <NewsItem
                    title="Update 1.6"
                    date="8/5/24"
                    content={<>
                        <p>
                            Geoguesser viewers have been moved from a Twitch extension to being directly on the site. This allows for viewers to click on the map and have it be large enough to actually see!
                        </p>
                    </>}
                />
                <NewsItem
                    title="Update 1.5"
                    date="7/19/24"
                    content={<>
                        <p>
                            Users can now log in using Twitch without needing to create a Bayou Bingo account!
                        </p>
                        <p>
                            The <b>Log in with Twitch</b> option will also work if you have already created a Bayou Bingo account and linked your Twitch account.
                        </p>
                        <p>
                            Other updates:
                        </p>
                        <ul>
                            <li>Bingo participants can see embedded streams and chat if streamer has linked their Twitch account.</li>
                            <li>Bingo game streamers / mods have more viewing and filtering options for the calls list / player lists.</li>
                            <li>Bug fixes & optimizations.</li>
                        </ul>
                    </>}
                />
                <NewsItem
                    title="Update 1.4"
                    date="5/31/24"
                    content={<>
                        <p>
                            The <b>Geoguesser Twitch extension</b> is now in beta!
                        </p>
                        <p>
                            For now, this extension allows users to guess the names of locations,
                            while we figure out how the server will handle the many simultaneous connections required to play in this way.
                            In the future, we plan to have users click on a map and allow streamers to see the "dots" of user guesses
                            on their own map display.
                        </p>
                        <div className="d-flex flex-column align-items-center">
                            <img className="mt-4" src="/extension-web.jpg" alt="Web side of Twitch extension"/>
                            Streamer side (web)
                            <img className="mt-4" src="/extension-channel.jpg" alt="Twitch channel side of Twitch extension"/>
                            Viewer side (channel)
                        </div>
                        <p className="mt-4">
                            If you would like to join the beta test, please email <a href="mailto:bayoubingoweb@gmail.com">bayoubingoweb@gmail.com</a> for details.
                        </p>
                    </>}
                />
                <NewsItem
                    title="Update 1.3"
                    date="5/23/24"
                    content={<>
                        <p>
                            <ul>
                                <li>
                                    Added look direction to Geoguesser
                                </li>
                                <li>
                                    Performance optimizations
                                </li>
                            </ul>
                        </p>
                    </>}
                />
                <NewsItem
                    title="Update 1.2"
                    date="5/22/24"
                    content={<>
                        <p>
                            Bingo has been re-enabled but will not be receiving feature updates in the immediate future.
                            (Bugs will still be fixed if any are reported to <a href="mailto:bayoubingoweb@gmail.com">bayoubingoweb@gmail.com</a>.)
                        </p>
                    </>}
                />
                <NewsItem
                    title="Update 1.1"
                    date="5/16/24"
                    content={<>
                        <p>Exciting new things are coming for the site! 
                            Bingo development has been paused, as it will soon be moving to a Twitch integration.
                            For questions regarding this, please contact us at the email at the bottom of this announcement.
                        </p>
                        <h4>Updates:</h4>
                        <ul>
                            <li>Added Twitch integration for geoguesser and profile screen.</li>
                            <li>Updated site design.</li>
                        </ul>
                        <h4>Roadmap:</h4>
                        <ul>
                            <li className="mt-2"><h5>General</h5>
                                <ul>
                                    <li>More & better Twitch integration functionality.</li>
                                    <li>More profile options.</li>
                                    <li>More design updates, particularly for mobile.</li>
                                </ul>
                            </li>
                            <li className="mt-2"><h5>Geoguesser</h5>
                                <ul>
                                    <li>Show a look direction cone on answer screen.</li>
                                    <li>Mark locations as seen (if you did some Geoguessing without logging in).</li>
                                    <li>"Geoguesser Plotter" - shows all locations currently mapped for Geoguesser (once more locations are input and it would not give answers away).</li>
                                </ul>
                            </li>
                            <li className="mt-2"><h5>Loadout Randomizer</h5>
                                <ul>
                                    <li>Filter items by rank / unlock requirements.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>As before, please report any issues to <a href="mailto:bayoubingoweb@gmail.com">bayoubingoweb@gmail.com</a>.</p>
                    </>}
                />
            </div>

        </div>
    );

};
  
export default Home;
  