import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'
import { Image, Button } from "react-bootstrap";
const api = require('../../api');

const GeoguesserPlotter = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(["authToken"]);

    if (!cookies.authToken) {
        navigate('/login', { state: { "next": "/account" } });
    }

    const [selectedMap, setSelectedMap] = React.useState("S");
    const [selectedMapUrl, setSelectedMapUrl] = React.useState("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_bayou.png");

    const addCanvasDot = (x, y, color) => {
        var canv = document.getElementById('mapCanvas');
        var context = canv.getContext('2d');
        context.beginPath();
        context.arc(x, y, 5, 0, 2 * Math.PI, false);
        context.fillStyle = color;
        context.fill();
        context.lineWidth = 2;
        context.strokeStyle = color;
        context.stroke();
    }

    useEffect(() => {
        api.getAuth('/users/getCurrent', cookies.authToken, {}, (response) => {
            if (response) {
                if (response.username !== "xxyrana" && response.username !== "baconbeagle") {
                    navigate('/');
                }
            } else {
                navigate('/');
            }
        });
    }, [cookies.authToken, navigate])

    useEffect(() => {
        if (selectedMap) {
            var canv = document.getElementById('mapCanvas');
            var context = canv.getContext('2d');
            context.clearRect(0, 0, 1000, 1000);
            api.getAuth('/geosetup/getMapCoordinates', cookies.authToken, { "map": selectedMap }, (response) => {
                try {
                    response.forEach(element => {
                        var color = ""
                        if (element.stars === 1) {
                            color = "#1dca1f";
                        } else if (element.stars === 2) {
                            color = "#72ca1d";
                        } else if (element.stars === 3) {
                            color = "#beca1d";
                        } else if (element.stars === 4) {
                            color = "#d8a51a";
                        } else if (element.stars === 5) {
                            color = "#d86b1a";
                        } else {
                            color = "#d8361a";
                        }
                        addCanvasDot(element.x_coord, element.y_coord, color);
                    });
                } catch {
                    console.log("error");
                }
            })
        }
    }, [cookies.authToken, selectedMap])

    return (
        cookies.authToken ? (
            <div className="d-flex flex-column align-items-center">
                <div className="mb-2 d-flex flex-row gap-2">
                    <Button
                        disabled={selectedMap === "S"}
                        onClick={() => {
                            setSelectedMap("S");
                            setSelectedMapUrl("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_bayou.png");
                        }}
                        className={"btn"}>
                        Stillwater Bayou
                    </Button>
                    <Button
                        disabled={selectedMap === "D"}
                        onClick={() => {
                            setSelectedMap("D");
                            setSelectedMapUrl("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_desalle.png");
                        }}
                        className="btn">
                        DeSalle
                    </Button>
                    <Button
                        disabled={selectedMap === "L"}
                        onClick={() => {
                            setSelectedMap("L");
                            setSelectedMapUrl("https://bayoubingo.s3.us-east-2.amazonaws.com/geoguesser/map_lawson.png");
                        }}
                        className="btn">
                        Lawson Delta
                    </Button>
                </div>
                <div style={{ width: "1000px", height: "1000px" }}>
                    <div className="w-100 h-100 position-relative">
                        <Image src={selectedMapUrl} className="w-100 h-100 position-absolute" />
                        <canvas id="mapCanvas" width={1000} height={1000} className="w-100 h-100 position-absolute"></canvas>
                    </div>
                </div>
            </div>
        ) : null
    );
};

export default GeoguesserPlotter;