import React from "react";
import LoadingSpinner from "../LoadingSpinner";

const ModeSelect = ({ isLoading, setMode, twitchId }) => {

    const svgBtnShape = " M 0,10 "
        + "A 10,10 0,0,0 10,0 "
        + "L 90,0 "
        + "A 10,10 0,0,0 100,10 "
        + "L 100,90 "
        + "A 10,10 0,0,0 90,100 "
        + "L 10,100 "
        + "A 10,10 0,0,0 0,90 "
        + "L 0,10 "
        + "z";

    const GeoguessButton = ({ text, icon, onClick }) => {
        return (
            <div className="d-flex flex-row align-items-center" style={{ width: twitchId ? "30%" : "40%", aspectRatio: "1/1" }}>
                <button className="btn-wrapper" onClick={onClick}>
                    <svg className="btn-inner" viewBox="0 0 100 100" >
                        <defs>
                            <clipPath id="insideOnly">
                                <path d={svgBtnShape} />
                            </clipPath>
                        </defs>
                        <path clipPath="url(#insideOnly)" className="btn-path" d={svgBtnShape} />
                    </svg>
                    <div className="btn-inner">
                        <div className="d-flex flex-column align-items-center justify-content-center h-100 p-3">
                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                                <i className={icon + " btn-icon"}></i>
                            </div>
                            <br />
                            <p className="flex-shrink btn-text pb-4">{text}</p>
                        </div>
                    </div>
                </button>
            </div>
        );
    }

    return (
        <div className="h-100 container-fluid d-flex flex-column align-items-center justify-content-center p-4 content-box">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="d-flex w-100 justify-content-between">
                    <GeoguessButton
                        text="Play"
                        icon="fa-solid fa-location-dot"
                        onClick={() => setMode("play")}
                    />
                    {twitchId !== undefined && (
                        <GeoguessButton
                            text="Stream"
                            icon="fa-brands fa-twitch"
                            onClick={() => setMode("stream")}
                        />
                    )}
                    <GeoguessButton
                        text="Join"
                        icon="fa-solid fa-users"
                        onClick={() => setMode("join")}
                    />
                </div>
            )}
        </div>
    );
}

export default ModeSelect;