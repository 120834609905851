import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { ParticipantsBlock } from "./ParticipantsBlock";
import { PromptsBlock } from "./PromptsBlock";
import { SettingsBlock } from "./SettingsBlock";
import { LoadingSpinner } from "../LoadingSpinner"
import 'bootstrap/dist/css/bootstrap.min.css';
import { KeysBlock } from "./KeysBlock";
const api = require('../../api');

export const GameDetailModal = ({gameID, refreshAllGames, refreshUserGames, refreshGameInfo, viewOnly, showDashButton, show, title, gameDetails, 
        onClose, isLoading, handleDeleteGame, isFull, setIsFull}) => {
    const [cookies] = useCookies(["authToken"]);
    const [selectedTab, setSelectedTab] = React.useState("settings");

    if(gameDetails === "") return;
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    var editable = true;
    if(new Date(gameDetails.details.scheduled_datetime) < today){
        editable = false;
    }

    const onGameJoin = (game_id) => {
        api.postAuth('/bingo/games/joinGame', cookies.authToken, { "game_id": game_id }, (response) => {
            if(response === "Game is full"){
                alert("Could not join game: Game is full");
                setIsFull(true);
            } else {
                refreshGameInfo();
                refreshAllGames();
            }
        })
    }

    return (
        <Modal show={show} onHide={onClose} dialogClassName="modal-xl">
            <ModalHeader>
                <div className="d-flex w-100 justify-content-between">
                    <ModalTitle>{title}</ModalTitle>
                    <div className="game-detail-header d-flex flex-wrap justify-content-end gap-1">
                        { isLoading || !showDashButton ? null : (
                            <Link to={"/game/" + gameID}>
                                <Button className="game-detail-button">Game Dashboard</Button>
                            </Link>
                        )}
                        { gameDetails.details.type ? null : 
                            !isFull && (<Button className="game-detail-button" onClick={() => onGameJoin(gameID)}>Join Game</Button>)
                        }
                        { (gameDetails.details.type === "streamer" || gameDetails.details.type === "creator") && (
                            <Button className="btn btn-danger mx-2 game-detail-button" onClick={() => handleDeleteGame(gameID)}>Delete Game</Button>
                        )}
                    </div>
                </div>
            </ModalHeader>
                <ModalBody>
                    <div className="py-3 game-detail-body">
                        <ul className="nav mb-4">
                            <li key="settings" className="nav-item" onClick={() => setSelectedTab("settings")}>
                                <div className={"nav-link" + (selectedTab === "settings" ? " active-tab" : "")}>Settings</div>
                            </li>
                            <li key="participants" className="nav-item" onClick={() => setSelectedTab("participants")}>
                                <div className={"nav-link" + (selectedTab === "participants" ? " active-tab" : "")}>Participants</div>
                            </li>
                            <li key="prompts" className="nav-item" onClick={() => setSelectedTab("prompts")}>
                                <div className={"nav-link" + (selectedTab === "prompts" ? " active-tab" : "")}>Prompts</div>
                            </li>
                            <li key="Keys" className="nav-item" onClick={() => setSelectedTab("keys")}>
                                <div className={"nav-link" + (selectedTab === "keys" ? " active-tab" : "")}>Keys</div>
                            </li>
                        </ul>
                        { isLoading ? (
                            <div className="p-3">
                                <div className="m-3 p-3 d-flex align-items-center">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        ) : selectedTab === "settings" ? (
                            <SettingsBlock 
                                gameID={gameID}
                                refreshUserGames={refreshUserGames}
                                refreshGameInfo={refreshGameInfo}
                                settingsList={gameDetails.details} 
                                editable={!viewOnly}
                            />
                        ) : selectedTab === "participants" ? (
                            <ParticipantsBlock 
                                gameID={gameID}
                                refreshGameInfo={refreshGameInfo}
                                participantsList={gameDetails.participants} 
                                editable={editable && !viewOnly}
                                isFull={isFull}
                            />
                        ) : selectedTab === "prompts" ? (
                            <PromptsBlock 
                                gameID={gameID}
                                refreshGameInfo={refreshGameInfo}
                                promptsList={gameDetails.prompts} 
                                editable={editable && !viewOnly}
                            />
                        ) : selectedTab === "keys" ? (
                            <KeysBlock 
                                gameID={gameID}
                                refreshGameInfo={refreshGameInfo}
                                keysList={gameDetails.keys} 
                                editable={editable && !viewOnly}
                            />
                        ) : null}
                    </div>
                </ModalBody>
            <ModalFooter>
                <Button className="game-detail-button" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};
