import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Account from "./pages/Account";
import Profile from "./pages/Profile";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Donate from "./pages/Donate"
import Bingo from "./pages/Bingo";
import BingoGame from "./pages/BingoGame";
import Loadout from "./pages/Loadout";
import Geoguesser from "./pages/Geoguesser";
import GeoguesserSetup from "./pages/admin/GeoguesserSetup";
import GeoguesserPlotter from "./pages/admin/GeoguesserPlotter";
import GeoguesserDashboard from "./pages/admin/GeoguesserDashboard";
import Users from "./pages/admin/Users";
import NoPage from "./pages/NoPage";
import './css/main.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<Account />} />
        <Route path="/profile/:username?" element={<Profile />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/loadout" element={<Loadout />} />
        <Route path="/geoguesser" element={<Geoguesser />} />
        <Route path="/geoguesser/setup" element={<GeoguesserSetup />} />
        <Route path="/geoguesser/plotter" element={<GeoguesserPlotter />} />
        <Route path="/geoguesser/dashboard" element={<GeoguesserDashboard />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/bingo" element={<Bingo />} />
        <Route path="/game/:gameID" element={<BingoGame />} />
        <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);